import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate" //保留vuex数据

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        activePage: '',
        userInfo: null, // 登入信息
        userInfoDetail: {}, //登入信息详情
        message: null, //站内信通知
        cartCount: 0, //购物车数量
        client_remark: '',//用户备注
        follow_remark: '',//跟进注意事项
        searchInfo: '', //搜索关键词
        palletizedCargoSearchInfo: '',//货盘搜索关键词
        shopType: '1688',
        page: '',
        languageStatus: false,
        searchInfoCN: '',
        collectionSumList: [],//商品库数量
        warehouseSumList: [],//仓库数量
        storageDeliverNumList: [],//配送单数量
        chinaLogisticsListTableNumList: [],//中国国内物流各个状态数量
        newOrdersSumList: [],//订单各个状态数量
        searchImg: '', //搜索关键图
        exchangeRate: 0, //汇率
        kaishisousuo: false,
        orderStatus: '',//订单当前状态
        router: '',
        messageUnreadCount: 0,
        leftSidebarNum: [0, 0, 0, 0, 0], //订单菜单侧边栏数量统计
        pageLoading: null, //是否显示加载中
        goodsList: [{}], //顶部搜索框选项
        fileList: [],//订单文件
        goodsListActiveId: '',
        payDetails: {},//支付信息
        showChinese: false, //是否显示中文
        excelOrderPreviewInfo: {},//excel提出的订单数据
        logisticsTransportation: [],
        stockAutoIdList: [],
        QASearchHistore: [],
        commercialMatchGoodsCount: 0,
        TagTitle: 'RAKUMART',
    },
    mutations: {
        changeGoodsName50Str(state, data) {
            state.goodsName50Str = data
        },
        changeTagDescription(state, data) {
            state.TagDescription = data
        },
        changeTagTitle(state, data) {
            state.TagTitle = data
        },
        showChinese(state, data) {
            state.showChinese = data
        },
        goodsListActiveId(state, data) {
            state.goodsListActiveId = data
        },
        getGoodsList(state, data) {
            state.goodsList = data
        },
        setPage(state, data) {
            state.page = data
        },
        getQASearchHistore(state, data) {
            state.showLoginDialog = data
        },
        pageLoading(state, data) {
            state.pageLoading = data
        },
        kaishisousuo(state, data) {
            state.kaishisousuo = data
        },
        getExchangeRate(state, data) {
            state.exchangeRate = data
            localStorage.setItem('exchangeRate', data)
        },
        getactivePage(state, data) {
            state.activePage = data
        },
        // 搜索关键图
        getsearchImg(state, data) {
            state.searchImg = data
        },
        //设置excel下单数据
        setExcelOrderPreviewInfo(state, params) {
            state.excelOrderPreviewInfo = params
        },
        //设置商品库各个状态数量
        setCollectionSumList(state, params) {
            state.collectionSumList = params;
        },
        //设置语言
        setLanguageStatus(state, params) {
            state.languageStatus = params;
        },
        //设置仓库各个状态数量
        setWarehouseSumList(state, params) {
            state.warehouseSumList = params;
            localStorage.setItem("warehouseSumList", JSON.stringify(params));
        },
        //设置配送单各个状态数量
        setStorageDeliverNumList(state, params) {
            state.storageDeliverNumList = params;
            localStorage.setItem("storageDeliverNumList", JSON.stringify(params));
        },
        //设置中国国内物流各个状态数量
        setChinaLogisticsListTableNumList(state, params) {
            state.chinaLogisticsListTableNumList = params;
            localStorage.setItem("chinaLogisticsListTableNumList", JSON.stringify(params));
        },
        //设置订单各个状态数量
        setOrdersSumList(state, params) {
            state.newOrdersSumList = params;
            localStorage.setItem("newOrdersSumList", JSON.stringify(params));
        },
        // 搜索关键词
        getsearchInfo(state, data) {
            state.searchInfo = data
            state.palletizedCargoSearchInfo = data
        },
        setShopType(state, data) {
            state.shopType = data
        },
        //获取物流方式列表
        getLogisticsTransportation(state, data) {
            const {logisticsTransportation} = data;
            state.logisticsTransportation = logisticsTransportation
        },
        getsearchInfoCN(state, data) {
            state.searchInfoCN = data
        },
        /* 获取登录信息 */
        userData(state, data) {
            state.userInfo = data;
            localStorage.setItem("userData", window.btoa(window.encodeURIComponent(JSON.stringify(data))));
        },
        // 登入信息详情
        getuserInfoDetail(state, data) {
            state.userInfoDetail = data
        },
        // 站内信通知
        getMessage(state, data) {
            state.message = data
        },
        //更新支付信息
        updatePayDetails(state, data) {
            const {obj} = data;
            state.payDetails = obj
        },
        /* 退出登录 */
        outUser(state) {
            state.userInfo = null
            localStorage.removeItem("token");
            state.message = null
            window.location.reload()
        },
        // 汇率
        rateData(state, data) {
            state.rate = data
        },
        // 客户会员中心数据
        getUserIndex(state, data) {
            state.userIndex = data
        },
        // 存储关键词
        setQ(state, data) {
            state.q = data
        },
        // excelOrder 下单
        getexcelOrder(state, data) {
            state.excelOrder = data
        },
        // 购物车数量
        setcartCount(state, data) {
            state.cartCount = data
        },
        // 聊天未读数量
        setMessageUnreadCount(state, data) {
            state.messageUnreadCount = data
        },
        setCommercialMatchGoodsCount(state, data) {
            state.commercialMatchGoodsCount = data
        },
        //更新订单详情的文件
        updateOrderFile(state, params) {
            const {file} = params;
            state.fileList.push(file);
            // state.fileList.splice(deleteIndex, 1)
        },
        //清空附件
        emptyFileList(state, params) {
            const {length} = params;
            state.fileList.length = length
        },
        //保存普通仓库要提出配送单的id
        saveAutoWarehouseId(state, params) {
            const {arr} = params;
            state.stockAutoIdList = arr
        },
        //删除订单详情的文件
        deleteOrderFile(state, params) {
            const {file} = params;
            state.fileList.push(file);
        },
        //更新订单详情要展示的组件
        updateOrderStatus(state, params) {
            const {name} = params;
            state.orderStatus = name;
        },
        // 购物车数量
        sidebarNumList(state, data) {
            state.sidebarNumList = data
        },
        //获取订单菜单侧边栏数量统计
        getLeftSidebarNum(state, data) {
            state.leftSidebarNum = data
        },
        //更新用户备注
        updateClient_remark(state, data) {
            const {text} = data;
            state.client_remark = text;
        },
        //更新用户备注
        updateFollow_remark(state, data) {
            const {text} = data;
            state.follow_remark = text;
        },
    },
    modules: {},
    actions: {
        // 获取购物车数量
        goodsToCartNum({commit}) {
            let user_token = localStorage.getItem('japan_user_token')
            if (user_token) {
                Vue.prototype.$api.goodsToCartNum().then(res => {
                    if (res.code == 0) {
                        commit('setcartCount', res.data.dataCount)
                    } else {
                        commit('setcartCount', 0)
                    }
                })
            }
        },
        // 获取用户资料
        japanGetUserInfo({commit}) {
            Vue.prototype.$api.japanGetUserInfo().then(res => {
                if (res.code == 0) {
                    commit('userData', res.data);
                } else {
                    commit('userData', null)
                }
            })
        },
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})
