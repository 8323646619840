import {apiAxios} from '../axios'
import Europess from '../wangZhi'

let Europe = Europess + '/client/'
export default {
    // 获取分类栏目
    getShopGroup: data => apiAxios('post', Europe + "goodsGroup", data),
    // 网站Banner列表
    getShopBanner: data => apiAxios('post', Europe + "webBanner", data),
    // 个性化商品推荐(注释参照商品搜索)
    getPersonalizedGoods: data => apiAxios('post', Europe + "hotGoods", data),
    // 获取常规推荐商品
    getRecommendedGoods: data => apiAxios('post', Europe + "autoGoods", data),
    //获取店铺shop_id
    getStoreShopId: data => apiAxios('post', Europe + "getStoreShopId", data),
    // 获取相似商品
    similarGoods: data => apiAxios('post', Europess + '/similarGoods', data),
}
