import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import {Message} from 'element-ui'

Vue.use(VueRouter)

const routes = [
  /* 用户页面*/
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserCenter/index'),
    meta: {
      requireAuth: true,
    },
    children: [
      // 用户-首页
      {
        path: '/user/index',
        name: 'userIndex',
        component: () => import('../views/UserCenter/views/UserIndex/index'),
        meta: {
          tagTitle: 'マイページ',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-订单页面
      {
        path: '/user/order',
        name: 'userOrder',
        component: () =>
         import('../views/UserCenter/views/userOrder/index.vue'),
        meta: {
          tagTitle: '代行注文書',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-运输中页面
      {
        path: '/user/wuliuStatus',
        name: 'wuliuStatus',
        component: () =>
         import('../views/UserCenter/views/wuliuStatus/index.vue'),
        meta: {
          tagTitle: '中国国内物流状況',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-运输中页面
      {
        path: '/user/excelPlaceAnOrder',
        name: 'excelPlaceAnOrder',
        component: () =>
         import('../views/UserCenter/views/excelPlaceAnOrder/index.vue'),
        meta: {
          tagTitle: 'rakumartエクセル注文',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-配送单页面
      {
        path: '/user/deliveryList',
        name: 'deliveryList',
        component: () =>
         import('../views/UserCenter/views/deliveryList/index.vue'),
        meta: {
          tagTitle: '配送書管理',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-充值页面
      {
        path: '/user/recharge',
        name: 'recharge',
        component: () => import('../views/UserCenter/views/recharge/index.vue'),
        meta: {
          tagTitle: '新規入金',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      
      // 用户-充值记录页面
      {
        path: '/user/churujinList',
        name: 'churujinList',
        component: () =>
         import('../views/UserCenter/views/churujinList/index.vue'),
        meta: {
          tagTitle: '入出金履歴',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-提现记录页面
      {
        path: '/user/WithdrawalRecord',
        name: 'WithdrawalRecord',
        component: () =>
         import(
          '../views/UserCenter/views/churujinList/views/WithdrawalRecord.vue'
          ),
        meta: {
          tagTitle: '残高返金',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-收藏夹店铺
      {
        path: '/user/favoriteShop',
        name: 'favoriteShop',
        component: () => import('../views/UserCenter/views/favoriteShop/index'),
        meta: {
          tagTitle: 'お気に入り店舗',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-商品库列表页面
      {
        path: '/user/commodity',
        name: 'commodity',
        component: () =>
         import('../views/UserCenter/views/commodity/index.vue'),
        meta: {
          tagTitle: 'お気に入り商品',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-登录信息页面
      {
        path: '/user/userDetail',
        name: 'userDetail',
        component: () =>
         import('../views/UserCenter/views/userDetail/index.vue'),
        meta: {
          tagTitle: '個人情報管理',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-编辑地址页面
      {
        path: '/user/deliverySite',
        name: 'deliverySite',
        component: () =>
         import('../views/UserCenter/views/deliverySite/index.vue'),
        meta: {
          tagTitle: '配送先管理',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-员工账户
      {
        path: '/user/EmployeesAccount',
        name: 'EmployeesAccount',
        component: () =>
         import('../views/UserCenter/views/EmployeesAccount/index.vue'),
        meta: {
          tagTitle: '社員用アカウント',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-优惠券
      {
        path: '/user/coupon',
        name: 'coupon',
        component: () => import('../views/UserCenter/views/coupon/index.vue'),
        meta: {
          tagTitle: 'クーポン券',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-授权许可
      {
        path: '/user/userAuthorizeAndPermit',
        name: 'userAuthorizeAndPermit',
        component: () =>
         import('../views/UserCenter/views/userAuthorizeAndPermit/index.vue'),
        meta: {
          tagTitle: '権限許可管理',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 用户-通知
      {
        path: '/user/inform',
        name: 'inform',
        component: () => import('../views/UserCenter/views/inform/idnex.vue'),
        meta: {
          tagTitle: 'お知らせ',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      //问题产品
      {
        path: '/user/issueOrder',
        name: 'issueOrder',
        component: () =>
         import('../views/UserCenter/views/issueOrder/index.vue'),
        meta: {
          tagTitle: '問題商品',
          tagDescription:
           'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
          tagKeywords:
           'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
          buDaiRakumart: true,
        },
      },
      // 售后
      {
        path: '/user/aftersale',
        name: 'aftersale',
        component: () =>
         import('../views/UserCenter/views/aftersale/index.vue'),
        meta: {
          tagTitle: 'アフターサービス',
          buDaiRakumart: true,
        },
      },
      // 店铺授权
      {
        path: '/user/amazonShopAuthorization',
        name: 'amazonShopAuthorization',
        component: () => import('../views/UserCenter/views/shopAuthorization/index.vue'),
        meta: {
          tagTitle: 'アフターサービス',
          buDaiRakumart: true,
        },
      },
      // 库存报告
      {
        path: '/user/inventoryReport',
        name: 'inventoryReport',
        component: () => import('../views/UserCenter/views/inventoryReport/index.vue'),
        meta: {
          tagTitle: 'アフターサービス',
          buDaiRakumart: true,
        },
      },
    ],
  },
  // 首页
  {
    path: '/',
    name: 'homepage',
    meta: {
      tagTitle: 'Rakumartラクマート 中国輸入 アリババ　タオバオ代行',
      tagDescription:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      tagKeywords:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      buDaiRakumart: true,
    },
    component: () => import('../views/homepage/index.vue'),
  },
  {
    path: '/questionAndAnswer',
    name: 'questionAndAnswer',
    component: () =>
     import('../views/footList/questionAndAnswer/questionAndAnswer'),
  },
  {
    path: '/answerResultPage',
    name: 'answerResultPage',
    component: () =>
     import('../views/footList/questionAndAnswer/answerResultPage'),
  },
  {
    path: '/answerSearchResultPage',
    name: 'answerSearchResultPage',
    component: () =>
     import('../views/footList/questionAndAnswer/answerSearchResultPage.vue'),
  },
  //插件版本更新日志
  {
    path: '/pluginVersionUpdateLogs',
    name: 'pluginVersionUpdateLogs',
    component: () => import('../views/footList/pluginVersionUpdateLogs.vue'),
  },
  //插件设置
  {
    path: '/pluginSettings',
    name: 'pluginSettings',
    component: () => import('../views/footList/pluginSettings.vue'),
  },
  //自组货源
  {
    path: '/selfOrganizingSourceGoods',
    name: 'selfOrganizingSourceGoods',
    component: () => import('../views/order/selfOrganizingSourceGoods.vue'),
  },
  //货盘待确认商品
  {
    path: '/goodsToBeConfirmedOnThePallet',
    name: 'goodsToBeConfirmedOnThePallet',
    component: () => import('../views/order/goodsToBeConfirmedOnThePallet.vue'),
  },
  {
    path: '/aboutNotInStock',
    name: 'aboutNotInStock',
    meta: {
      tagTitle: '無在庫便ご利用までの流れ',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/footList/aboutNotInStock'),
  },
  // 用户-收藏夹产品页面
  {
    path: '/collection',
    name: 'collection',
    meta: {
      tagTitle: '商品庫管理',
      tagDescription: 'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords: 'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/UserCenter/views/collection/index.vue'),
  },
  // 用户-仓库页面
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('../views/UserCenter/views/warehouse/index.vue'),
    meta: {
      tagTitle: '倉庫管理',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 用户-FBA直送サービス
  {
    path: '/FBADirectDeliveryService',
    name: 'FBADirectDeliveryService',
    component: () => import('../views/footList/FBADirectDeliveryService'),
    meta: {
      tagTitle: 'FBA直送サービス',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 用户-联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/footList/contactUs'),
    meta: {
      tagTitle: 'お問い合わせ',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 收费会员申请
  {
    path: '/tollVipApplyFor',
    name: 'tollVipApplyFor',
    component: () => import('../views/UserCenter/views/tollVipApplyFor/index.vue'),
    meta: {
      tagTitle: 'Rakumart 有料会員制度',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 余额支付收费会员费用
  {
    path: '/balancePayTollVip',
    name: 'balancePayTollVip',
    component: () => import('../views/UserCenter/views/tollVipApplyFor/components/index.vue'),
    meta: {
      tagTitle: '有料会員制度　申し込み',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 服务评价
  {
    path: '/serviceEvaluation',
    name: 'serviceEvaluation',
    component: () => import('../views/UserCenter/views/serviceEvaluation/index.vue'),
    meta: {
      tagTitle: 'レビュー',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 提现页面
  {
    path: '/withdrawDeposit',
    name: 'withdrawDeposit',
    component: () => import('../views/order/WithdrawDeposit.vue'),
    meta: {
      tagTitle: '残高返金明細',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
      requireAuth: true,
    },
  },
  // 订单页面
  {
    path: '/OrderDetails',
    name: 'OrderDetails',
    component: () => import('../views/UserCenter/views/userOrder/views/OrderDetails.vue'),
    meta: {
      tagTitle: '注文詳細',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // excel订单预览页面
  {
    path: '/excelOrderPreview',
    name: 'excelOrderPreview',
    component: () => import('../views/UserCenter/views/userOrder/views/excelOrderPreview.vue'),
    meta: {
      tagTitle: 'エクセル注文詳細',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 调查问卷
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/footList/questionnaire.vue'),
    meta: {
      tagTitle: 'Rakumart新規会員アンケート調査',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  //网易分类详情
  {
    path: '/neteaseClassifyDetails',
    name: 'neteaseClassifyDetails',
    component: () => import('../views/order/neteaseClassifyDetails.vue'),
    meta: {
      tagTitle: '中国輸入、タオバオ、アリババ代行専門会社ラクマート',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 店铺全部商品
  {
    path: '/shopGoods',
    name: 'shopGoods',
    component: () => import('../views/order/shopGoods'),
    meta: {
      tagTitle: 'Rakumart-店舗情報',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 物流追踪
  {
    path: '/logisticsTracking',
    name: 'logisticsTracking',
    component: () => import('../views/footList/logisticsTracking.vue'),
    meta: {
      tagTitle: '物流追跡サイト',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 国际物流
  {
    path: '/internationalLogistics',
    name: 'internationalLogistics',
    meta: {
      tagTitle: '国際送料',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/footList/internationalLogistics.vue'),
  },
  // 忘记密码页面
  {
    path: '/ForgetThePassword',
    name: 'ForgetThePassword',
    meta: {
      tagTitle: 'パスワードの再発行',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/login/forgetThePassword.vue'),
  },
  // 商品详情
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    meta: {
      tagTitle: '商品詳細',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/order/productDetails.vue'),
  },
  //榜单
  {
    path: '/goodsMonthlyFocus',
    name: 'goodsMonthlyFocus',
    meta: {
      tagTitle: 'ヒット商品ランキング',
      tagDescription: 'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords: 'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/order/goodsMonthlyFocus.vue'),
  },
  /* 注册页面*/
  {
    path: '/register',
    name: 'register',
    meta: {
      tagTitle: '新規会員登録',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/login/register.vue'),
  },
  /* 注册页面*/
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    meta: {
      tagTitle: '新規会員登録',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/login/registerSuccess.vue'),
  },
  // 用户指南
  {
    path: '/guia-de-usuario',
    name: 'userGuide',
    meta: {
      tagTitle: '初めての方',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/footList/userGuide.vue'),
  },
  // 用户-问题产品详情
  {
    path: '/issueOrderDetail',
    name: 'issueOrderDetail',
    meta: {
      tagTitle: '問題商品詳細',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/UserCenter/views/issueOrder/views/issueOrderDetail.vue'),
  },
  // 仓库保管说明
  {
    path: '/storageInstructions',
    name: 'storageInstructions',
    meta: {
      tagTitle: '倉庫保管費用',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () => import('../views/UserCenter/views/storageInstructions/index'),
  },
  /* 登录页面*/
  {
    path: '/login',
    name: 'login',
    meta: {
      tagTitle: 'Rakumart ログイン',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () => import('../views/login/login.vue'),
  },
  /* 关于我们*/
  // {
  //     path: '/aboutUs',
  //     name: 'aboutUs',
  //     meta: {
  //         tagTitle: 'Rakumart ログイン',
  //         tagDescription:
  //             '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
  //         tagKeywords:
  //             '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
  //         buDaiRakumart: true,
  //     },
  //     component: () => import('../views/footList/aboutUs'),
  // },
  /* 购物车列表*/
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/order/cart'),
    meta: {
      tagTitle: '買い物かご',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* 订单支付*/
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment/payment'),
    meta: {
      tagTitle: '支払い',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* 支付页面*/
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment/payment'),
    meta: {
      tagTitle: '支払い',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* paypal支付完成*/
  {
    path: '/paypalPaymentSuccess',
    name: 'paypalPaymentSuccess',
    component: () => import('../views/payment/paypalPaymentSuccess'),
    meta: {
      tagTitle: '支払い完了',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* paypal支付完成*/
  {
    path: '/paypalPaymentError',
    name: 'paypalPaymentError',
    component: () => import('../views/payment/paypalPaymentError'),
    meta: {
      tagTitle: '支払い失敗しました',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* 银行或余额支付完成*/
  {
    path: '/balanceMaybeBankPaymentSuccess',
    name: 'balanceMaybeBankPaymentSuccess',
    component: () => import('../views/payment/balanceMaybeBankPaymentSuccess'),
    meta: {
      tagTitle: '支払い完了',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /*订单物流追踪*/
  {
    path: '/orderLogisticsStatus',
    name: 'orderLogisticsStatus',
    component: () =>
     import(
      '../views/UserCenter/views/wuliuStatus/components/orderLogisticsStatus'
      ),
    meta: {
      tagTitle: '物流の状況',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* 商品搜索页*/
  {
    path: '/CommoditySearch',
    name: 'CommoditySearch',
    component: () => import('../views/order/commoditySearch.vue'),
    meta: {
      tagTitle: '商品リサーチ',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
  },
  /* 隐私政策*/
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    meta: {
      tagTitle: 'Rakumart プライバシーポリシー',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () =>
     import(/* webpackChunkName: "foot" */ '../views/footList/privacyPolicy'),
  },
  /* 会员服务条款*/
  {
    path: '/termsService',
    name: 'termsService',
    meta: {
      tagTitle: 'Rakumart 会員利用規約',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () =>
     import(/* webpackChunkName: "foot" */ '../views/footList/termsService'),
  },
  /* 中国公司介绍 */
  {
    path: '/chinaCompanyIntroduce',
    name: 'chinaCompanyIntroduce',
    meta: {
      tagTitle: 'Rakumart　中国会社',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      /* webpackChunkName: "foot" */ '../views/footList/chinaCompanyIntroduce'
      ),
  },
  /* 日本人公司介绍 */
  {
    path: '/japanCompanyIntroduce',
    name: 'japanCompanyIntroduce',
    meta: {
      tagTitle: 'Rakumart　日本会社',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      /* webpackChunkName: "foot" */ '../views/footList/japanCompanyIntroduce'
      ),
  },
  /* option*/
  {
    path: '/servicios-adicionales',
    name: 'option',
    component: () =>
     import(
      /* webpackChunkName: "foot" */ '../views/footList/servicios-adicionales'
      ),
    meta: {
      tagTitle: 'Rakumart　オプション',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /* 会员等级单独页面*/
  {
    path: '/comisiones-rakumart',
    name: 'comisiones-rakumart',
    meta: {
      tagTitle: 'Rakumart　手数料',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      /* webpackChunkName: "foot" */ '../views/footList/comisiones-rakumart'
      ),
  },
  /* 页脚页面列表集合*/
  {
    path: '/description',
    name: 'description',
    meta: {
      tagTitle: 'Rakumartラクマート 中国輸入 アリババ　タオバオ代行',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      /* webpackChunkName: "foot" */ '../views/footList/footListAll.vue'
      ),
    children: [],
  },
  // 页脚页面列表集合-返品・返金及び免責事項
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('../views/footList/disclaimer.vue'),
    meta: {
      tagTitle: 'Rakumart 返品・返金及び免責事項',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  //放假通知
  {
    path: '/notificationOfHoliday',
    name: 'notificationOfHoliday',
    component: () => import('../views/footList/notificationOfHoliday.vue'),
    meta: {
      tagTitle: '2023年元旦と旧正月大型連休についてのお知らせ',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  // 页脚页面列表集合-会員利用規約
  {
    path: '/membershipUtilizationProtocol',
    name: 'membershipUtilizationProtocol',
    component: () => import('../views/footList/termsService.vue'),
    meta: {
      tagTitle: 'Rakumart 会員利用規約',
      tagDescription:
       'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords:
       'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true,
    },
  },
  /*配送单详情*/
  {
    path: '/deliveryDetails',
    name: 'deliveryDetails',
    meta: {
      tagTitle: '配送書詳細',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      '../views/UserCenter/views/deliveryList/views/DeliveryDetails/index.vue'
      ),
  },
  /*配送单装箱详情*/
  {
    path: '/deliveryEncasementDetails',
    name: 'deliveryEncasementDetails',
    meta: {
      tagTitle: '配送書詳細',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () =>
     import(
      '../views/UserCenter/views/deliveryList/views/DeliveryDetails/deliveryEncasementDetails.vue'
      ),
  },
  // 摄影
  {
    path: '/photography',
    name: 'photography',
    component: () => import('../views/footList/photography.vue'),
  },
  // 普检精检详细说明
  {
    path: '/inspectionDetail',
    name: 'inspectionDetail',
    meta: {
      tagTitle: ' Rakumart 検品について',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () => import('../views/footList/inspectionDetail.vue'),
  },
  // option公司费用介绍
  {
    path: '/option',
    name: 'options',
    meta: {
      tagTitle: ' Rakumart オプション費用',
      tagDescription:
       '中国輸入,OEM,無在庫,義烏,イーウー,輸入代行,無在庫販売,Rakumart,アリババ　タオバオ"',
      tagKeywords:
       '中国輸入に特化！URL/写真添付不要の時短発注管理システムは、中国内物流追跡、外注管理、無在庫発送やOEMプライベートブランドまで、業界初機能満載、ワンストップで輸入完結',
      buDaiRakumart: true,
    },
    component: () => import('../views/footList/option.vue'),
  },
  // 售后详情
  {
    path: '/aftersaledetails',
    name: 'aftersaledetails',
    component: () => import('../views/UserCenter/views/aftersale/view/aftersaledetails.vue'),
    meta: {
      tagTitle: ' アフターサービスの詳細',
      buDaiRakumart: true,
    }
  },
  // 用户-阿里巴巴同步说明页
  {
    path: '/accountSynchronization',
    name: 'accountSynchronization',
    component: () => import('../views/footList/accountSynchronization.vue'),
    meta: {
      tagTitle: 'RAKUAMRT&1688',
      tagDescription: 'アリババ,タオバオ中国輸入なら日中間最大級の総合仕れサイト「rakumart.com」にお任せ下さい！仕入れ、商材輸入、工場を探しまで。メーカー、サプライヤーにOEMや小ロットなどの仕入れ条件を直接交渉できます。',
      tagKeywords: 'アリババ,タオバオ仕入れ,卸,問屋,工場,メーカー,OEM,輸入,仕入れサイト，仕入れ問屋，海外輸入サイト，サプライヤー，rakumart',
      buDaiRakumart: true
    },
  },
]
const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  document.getElementsByName("canonicalLink")[0].href = 'https://www.rakumart.com' + to.fullPath;
  setOnlyName(to)
  changetag(to)
  changeDescription(to)
  // 判断是否有token
  if (localStorage.getItem('japan_user_token')) {
    // 没有登录信息时候去获取用户信息
    if (store.state.userInfo == null) {
      Vue.prototype.$api.japanGetUserInfo().then((res) => {
        // 登录失效
        if (res.code !== 0) {
          store.commit('userData', null)
          localStorage.removeItem('japan_user_token')
          localStorage.removeItem('gkValue');
          // 有需要验证的用户中心
          if (to.matched.some((record) => record.meta.requireAuth)) {
            next({
              path: '/login',
            })
          }
          Message.error(res.msg)
        } else {
          return store.commit('userData', res.data) // 用户信息存储
        }
      })
    }
  }
  if (to.name != 'login') {
    //console.log(to);
    store.commit('getactivePage', to.fullPath)
  }
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem('japan_user_token')) {
      next()
    } else {
      store.commit('getactivePage', to.fullPath)
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})

// 设置网站的tag 推广用
function changetag(to) {
  if (to.meta.buDaiRakumart) {
    document.title = to.meta.tagTitle
    return store.commit('changeTagTitle', to.meta.tagTitle)
  }
  // 假如设置了meta就使用meta
  if (to.meta.tagTitle) {
    store.commit('changeTagTitle', 'Rakumart-' + to.meta.tagTitle)
    return (document.title = 'Rakumart-' + to.meta.tagTitle)
  } else {
    store.commit('changeTagTitle', 'Rakumart-' + to.name)
    return (document.title = 'Rakumart-' + to.name)
  }
}

// 设置页面标识符,因为谷歌在爬取商品详情页面时爬取不到接口返回数据,所以需要加一个唯一的页面标示符以区分页面,否则会提示重定向
function setOnlyName(to) {
  document.getElementsByName('onlyName')[0].innerText = to.fullPath
}

// 设置网站的tagDescription 推广用
function changeDescription(to) {
  // 假如设置了meta就使用meta
  if (to.meta.tagDescription) {
    document.getElementsByName('description')[0].content =
     to.meta.tagDescription
    // store.commit('changeTagDescription', to.meta.tagDescription)
  }
  if (to.meta.tagKeywords) {
    document.getElementsByName('keywords')[0].content = to.meta.tagKeywords
    // store.commit('changeTagDescription', to.meta.tagDescription)
  }
}

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
