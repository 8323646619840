import {apiAxios} from '../axios'
import Europess from '../wangZhi'

let comm_api = Europess
export default {
  // 实时汇率
  commonExchangeRate: data => apiAxios('post', comm_api + "/api/common/getExchangeRat", data),
  // 发送验证码
  sendVerificationCode: data => apiAxios('post', comm_api + "/client/getRegisterVerificationCode", data),
  //比对验证码
  checkVerificationCode: data => apiAxios('post', comm_api + "/client/checkRegisterCode", data),
  //国际物流说明页
  logisticsTableData: data => apiAxios('post', comm_api + "/api/common/explain.logisticsTableData", data),
  //国际物流说明页-试算
  getLogisticsAmountAll: data => apiAxios('post', comm_api + "/api/common/explain.getLogisticsAmountAll", data),
  //商品链接转商品ID
  goodsUrlToGoodsId: data => apiAxios('post', comm_api + "/api/common/goodsUrlToGoodsId", data),
  //商品状态刷新
  goodsTypeReset: data => apiAxios('post', comm_api + "/client/favorite.goodsTypeReset", data),
  //上传文件
  uploadFile: data => apiAxios('post', comm_api + "/api/common/uploadFile", data),
  //文件url转换成文件流
  downloadFile: data => apiAxios('post', "https://laveltest.rakumart.cn/api/common/downloadFile", data),
  //图片翻译
  pictureTranslation: data => apiAxios('post', comm_api + "/api/common/imageTextTranslation", data),
  //检查用户聊天群【异常的会自动重置】
  userTimGroupCheck: data => apiAxios('post', comm_api + "/api/common/userTimGroupCheck", data),
  //日历查询
  getCalendar: data => apiAxios('post', comm_api + "/client/config.getCalendar", data),
  //国际物流查询
  getLogisticsTrack: data => apiAxios('post', comm_api + "/api/common/logistics.logisticsTrack", data),
  //获取图形验证码
  getCodeImg: (data, code) => apiAxios('get', comm_api + "/client/codeImg?rand=" + code, data),
  // 获取上传token
  getUploadToken: (data) => apiAxios('post', comm_api + '/api/common/getUploadToken', data),
  // 插件版本列表
  pluginVersionList: (data) => apiAxios('post', comm_api + '/plugin/wpi/pluginVersionList', data),
}
