let langs = [
  {
    key: '密码',
    value: 'パスワード',
  },
  {
    key: '忘记密码',
    value: 'パスワードを忘れた場合',
  },
  {
    key: '添加地址',
    value: '住所を追加する',
  },
  {
    key: '还没有账号',
    value: '無料',
  },
  {
    key: '已从您的余额扣除待支付的金额。',
    value: '残高より支払いさせて頂きました。',
  },
  {
    key: '支付金额不能大于1000000日元',
    value: '金額は100000000円を超えてはいけない',
  },
  {
    key: '创建新账户',
    value: '新規会員登録',
  },
  {
    key: '标记*的请务必填写',
    value: '※は必ずご記入ください',
  },
  {
    key: '还没有账号',
    value: '新規会員登',
  },
  {
    key: '数据导出中',
    value: 'データエクスポート中',
  },
  {
    key: '长度在 1 到 24 个字符',
    value: '半角1文字以上24文字以内に収めてください',
  },
  {
    key: '请输入住所',
    value: '住所を入力してください',
  },
  {
    key: '登录名不能全由数字组成',
    value: '数字のみのユーザー名は対応できません',
  },
  {
    key: '保持登录状态',
    value: '登録状態を維持',
  },
  {
    key: '从淘宝、阿里巴巴、天猫轻松转型进口业务',
    value: 'タオバオ・アリババ・天猫から楽々と輸入事業へ',
  },
  {
    key: '请输入商品名搜索',
    value: '商品名検索',
  },
  {
    key: '加入商品库',
    value: '商品ライブラリに追加',
  },
  {
    key: '请输入邮箱/手机号',
    value: 'メールアドレス/携帯電話番号を入力してください',
  },
  {
    key: '手机号已被使用',
    value: '携帯電話番号が既に利用されています',
  },
  {
    key: '邮箱已被使用',
    value: 'メールアドレスが既に利用されています',
  },
  {
    key: '从商品库中导入更多产品',
    value: '商品庫から追加',
  },
  {
    key: '手机号',
    value: '携帯電話番号',
  },
  {
    key: '手机号为空',
    value: '携帯電話番号が入力されていません。入力してください。',
  },
  {
    key: '手机',
    value: '携帯電話番号',
  },
  {
    key: '倉庫保管費用',
    value: '倉庫保管費用',
  },
  {
    key: '请输入密码',
    value: 'パスワードを記入',
  },
  {
    key: 'カウント期間',
    value: 'カウント期間',
  },
  {
    key: '正在发送验证码',
    value: '確認コードの送信中です',
  },
  {
    key: '一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。',
    value:
     '一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。',
  },
  {
    key: '無料保管期間',
    value: '無料保管期間',
  },
  {
    key: '通常会員様',
    value: '通常会員様',
  },
  {
    key: '無料保管',
    value: '無料保管',
  },
  {
    key: '月額有料会員様',
    value: '月額有料会員様',
  },
  {
    key: '無料保管期限を過ぎた場合の保管手数料について',
    value: '無料保管期限を過ぎた場合の保管手数料について',
  },
  {
    key: '注意事项',
    value: '注意事項',
  },
  {
    key: 'リアルタイム口座(14桁)',
    value: 'リアルタイム口座(14桁)',
  },
  {
    key: 'アカウント残高',
    value: 'アカウント残高',
  },
  {
    key: '授权管理',
    value: '権限管理',
  },
  {
    key: '您已进行装箱授权，如需要提货请与负责人沟通',
    value:
     'こちらのアカウントは配送依頼権限を担当者へ許可している状態です、操作される場合は担当者へご連絡ください',
  },
  {
    key: '現在ご利用可能の金額',
    value: '現在ご利用可能の金額',
  },
  {
    key: '入金確認待ち金額',
    value: '入金確認待ち金額',
  },
  {
    key: '不可用金额',
    value: '凍結金額',
  },
  {
    key: '物流失效，请重新选择物流',
    value: '配送方法が無効です。別の配送方法を選択してください',
  },
  {
    key: '失效',
    value: 'URL無効',
  },
  {
    key: '在库确认',
    value: '在庫確認',
  },
  {
    key: '您对该商品添加过的数据可以快捷填充，目前标签只支持：FBA，乐天，雅虎',
    value: '現在Amazon、楽天、ヤフー ラベル情報記入可能です',
  },
  {
    key: '不可用',
    value: '不可用',
  },
  {
    key: '余额提现',
    value: '余额提现',
  },
  {
    key: '振込先',
    value: '振込先',
  },
  {
    key: '请输入手机号',
    value: '日本国内の携帯電話を入力してください',
  },
  {
    key: '填写个人信息',
    value: '個人情報入力',
  },
  {
    key: '个人信息',
    value: '個人情報',
  },
  {
    key: '注册完成',
    value: '新規登録完了',
  },
  {
    key: '名字',
    value: '名前',
  },
  {
    key: '重置密码',
    value: 'パスワード変更',
  },
  {
    key: '查看数据',
    value: '閲覧',
  },
  {
    key: '变更',
    value: '変更',
  },
  {
    key: '请输入正确的邮箱地址',
    value: '正しいメールアドレスをご記入ください',
  },
  {
    key: '订单问题产品',
    value: '注文書　問題商品',
  },
  {
    key: '功能权限',
    value: '機能の権限',
  },
  {
    key: '查看范围',
    value: '詳細範囲',
  },
  {
    key: '验证码',
    value: '確認コード',
  },
  {
    key: '同意以上协议，进入下一步',
    value: '上記規約に同意して確認画面へ',
  },
  {
    key: '注册完了',
    value: '登録完了',
  },
  {
    key: '立即登录',
    value: 'ログインへ',
  },
  {
    key: '个人中心',
    value: 'マイページ',
  },
  {
    key: '退出登录',
    value: 'ログアウト',
  },
  {
    key: '验证码错误',
    value: '確認コードが違います',
  },
  {
    key: '注文書No',
    value: '注文書No',
  },
  {
    key: 'キーワードまたは1688、タオバオの商品URL',
    value: 'キーワードまたは1688、タオバオの商品URL',
  },
  {
    key: '登录',
    value: 'ログイン',
  },
  {
    key: '搜索结果',
    value: '検索結果',
  },
  {
    key: '注册',
    value: '新規登録',
  },
  {
    key: '仕入れ会員',
    value: '仕入れ会員',
  },
  {
    key: '費用について',
    value: '費用について',
  },
  {
    key: '收费会员服务',
    value: '定額会員',
  },
  {
    key: '会社案内',
    value: '会社案内',
  },
  {
    key: '中国会社',
    value: '中国会社',
  },
  {
    key: 'よくある質問',
    value: 'よくある質問',
  },
  {
    key: '問い合わせ',
    value: '問い合わせ',
  },
  {
    key: '国際送料詳細',
    value: '国際送料詳細',
  },
  {
    key: 'FBA直送サービス',
    value: 'FBA直送サービス',
  },
  {
    key: 'プライバシーポリシー',
    value: 'プライバシーポリシー',
  },
  {
    key: '会員利用規約',
    value: '会員利用規約',
  },
  {
    key: '返品・返金及び免責事項',
    value: '返品・返金及び免責事項',
  },
  {
    key: '会员服务',
    value: '会員サービス',
  },
  {
    key: '物流追跡',
    value: '物流追跡',
  },
  {
    key: '收费体系',
    value: '料金体系',
  },
  {
    key: '购物车',
    value: '買い物かご',
  },
  {
    key: '円',
    value: '円',
  },
  {
    key: 'カードから追加',
    value: 'カードから追加',
  },
  {
    key: 'オプション',
    value: 'オプション',
  },
  {
    key: 'オプション选择',
    value: 'オプション选择',
  },
  {
    key: '追加',
    value: '追加',
  },
  {
    key: '特色类别',
    value: '特色種類',
  },
  {
    key: '加入购物车',
    value: 'ショッピングカートに入れる',
  },
  {
    key: '账号',
    value: 'ユーザー名',
  },
  {
    key: '请输入账号或手机号或邮箱',
    value: 'ユーザー名／携帯番号／Email',
  },
  {
    key: '其他认证方式',
    value: '他の認証方法',
  },
  {
    key: '無料会員登録',
    value: '無料会員登録',
  },
  {
    key: '利用ガイド',
    value: '利用ガイド',
  },
  {
    key: '确认个人信息',
    value: '個人情報確認',
  },
  {
    key: '登录名',
    value: 'ユーザー名',
  },
  {
    key: '密码确认',
    value: 'パスワード再確認',
  },
  {
    key: '手机',
    value: '携帯番号',
  },
  {
    key: '获取验证码',
    value: '確認コード取得',
  },
  {
    key: '在Rakumart，您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务',
    value:
     'Rakumartでは、さまざまな倉庫でのラベルサービス（FBA、楽天、Yahooなど）や、製品のカスタム撮影、ODM（Original Design Manufacturer）カスタマイズなど、さまざまな追加サービスを提供しています。',
  },
  {
    key: '如果您有自己的私人品牌，太好了！请联系我们。',
    value: '如独自のプライベートブランドをお持ちであれば、ぜひご連絡ください。',
  },
  {
    key: '包装，标签，精细检品',
    value: '梱包、ラベル、精密品検査',
  },
  {
    key: '有料会员',
    value: '定額会員',
  },
  {
    key: '为您的私人品牌生产定制包装，标签，按照您的要求进行',
    value:
     'お客様のプライベートブランド向けに、カスタムパッケージやラベルを作成、ご要望に沿って対応いたします。',
  },
  {
    key: '個',
    value: '個',
  },
  {
    key: '产品摄影',
    value: '商品写真撮影',
  },
  {
    key: '贴标服务',
    value: 'ラベル貼りサービス',
  },
  {
    key: '更换条码，标签',
    value: 'バーコード、ラベルの変更',
  },
  {
    key: '更换包装袋',
    value: 'パッケージの交換',
  },
  {
    key: '双面打印',
    value: '両面印刷',
  },
  {
    key: '缝制标签',
    value: '縫い付けラベル',
  },
  {
    key: '缝制个性化标签到您的产品上，如纺织品，衣物等。',
    value:
     '製品にカスタムラベルを縫い付けることも可能です。例えば、紡織品や衣類などです。',
  },
  {
    key: '拆卸织物标签',
    value: 'タグの取り外し',
  },
  {
    key: '放置吊牌',
    value: '紙タグの取り付け',
  },
  {
    key: '移除吊牌',
    value: '紙タグの取り外し',
  },
  {
    key: '联合包装',
    value: '共同パッケージ',
  },
  {
    key: '泡沫袋保护',
    value: '泡袋で保護',
  },
  {
    key: '我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。',
    value: '工場出荷時に付属している布タグを取り除くことができます。',
  },
  {
    key: '在您的产品上按照您的需求放上您的品牌标签。',
    value: '製品にはお客様のブランドタグを付けることができます。',
  },
  {
    key: 'RAKUMART手续费说明',
    value: 'RAKUMART 会員手数料について',
  },
  {
    key: '把商品上带有的吊牌进行移除。',
    value: '商品に付属している吊り札を取り外すこともできます。',
  },
  {
    key: '根据客户在本网站采购商品的累计总金额设置手续费，',
    value:
     'お客様が当サイトで商品を購入する累計総額に基づいて手数料が設定されます。',
  },
  {
    key: '根据采购总额手续费也不同，详情请参照下表。',
    value:
     '購入総額に応じて手数料が異なりますので、詳細は以下の表をご参照ください。',
  },
  {
    key: '例如:实际超过1000万日元以后的交易，适用4.2%的手续费。',
    value:
     '例えば、実際の取引が1000万円を超えた場合、手数料は4.2%が適用されます。',
  },
  {
    key: '大件物品需要单独报价，请与负责人联系',
    value:
     '大型商品については、個別に見積もりが必要ですので、担当者にご相談ください',
  },
  {
    key: '默认为A4纸，其他类型纸张需求请联系负责人确认。',
    value:
     'デフォルトではA4サイズの用紙を使用していますが、他の種類の用紙をご希望の場合は、担当者にご確認ください。',
  },
  {
    key: '四件以上的商品联合包装时费用另算，请与负责人联系。',
    value:
     '4点以上の商品を共同で梱包する場合は、別途料金が発生しますので、担当者にご相談ください。',
  },
  {
    key: '大件物品我们需要单独报价。',
    value: '大型商品については、個別に見積もりが必要です。',
  },
  {
    key: '支持各个仓库的贴标服务，比如FBA，乐天，雅虎，定制的标签也可以，请联系负责人进行确认。',
    value:
     '各倉庫のラベルサービスに対応しており、例えばFBA、楽天、Yahooなどです。また、カスタムのラベルも可能ですので、担当者に確認してください。',
  },
  {
    key: '定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价',
    value:
     'OEM、ODMでは、サイズ、色、必要な材料タイプに応じて異なりますので、お客様のブランドの要素と情報を提供していただく必要があります。それに基づいて予算や見積もりを行います。',
  },
  {
    key: '与我们的负责人协商组装等要求',
    value: '組み立てなどの要件については、担当者と相談してください。',
  },
  {
    key: '请输入意见反馈',
    value: 'ご意見を入力してください',
  },
  {
    key: '配送单评价',
    value: '配送伝票評価',
  },
  {
    key: '满意度为1到5星，星级越高越满意',
    value: '満足度は1から5つ星で、星の数が多いほど満足度が高いです',
  },
  {
    key: '服务流程是否满意',
    value: 'サービスに満足しましたか',
  },
  {
    key: '商品质量是否符合您的预期',
    value: '商品の品質は予想通りでしたか',
  },
  {
    key: '商品是否完整（有无缺失、破损）',
    value: '商品は完全でしたか（欠損や破損はありましたか）',
  },
  {
    key: '整体服务满意度',
    value: '全体的なサービス満足度',
  },
  {
    key: '服务人员是否能精准到位满足您的需求',
    value: '担当者はあなたの要求に正確かつ満足できるように対応しましたか',
  },
  {
    key: '服务人员是否态度良好',
    value: '担当者の態度は良好でしたか',
  },
  {
    key: '服务人员是否处理问题及时',
    value: '担当者は問題を適切に処理しましたか',
  },
  {
    key: '物流满意度',
    value: '物流の満足度',
  },
  {
    key: '对此物流速度是否满意',
    value: '物流のスピードに満足しましたか',
  },
  {
    key: '物流费用是否满意',
    value: '物流費用に満足しましたか',
  },
  {
    key: '物流服务（通关、配送等）',
    value: '物流サービス（通関、配送など）',
  },
  {
    key: 'ODM/OEM服务',
    value: 'ODM/OEMサービス',
  },
  {
    key: '客户准备一份初稿，我们委托工厂按照设计生产或者对现有产品进行个性化定制，添加客户想要的功能或特性，',
    value:
     'お客様が初期案を準備し、我々が工場に依頼してデザインに基づいて製造するか、',
  },
  {
    key: '以便在自有品牌下销售。',
    value:
     '既存の製品をカスタマイズしてお客様の要望に合わせた機能や特性を追加します。',
  },
  {
    key: '这个过程从工厂购买材料开始，所以要满足一定的产量',
    value:
     '独自のブランドで販売注文するために必要な最低ロットが必要になります。',
  },
  {
    key: '我们将与生产厂家联系，了解生产数量，单价，交货期，并拿到样品与您确认',
    value:
     '生産工場と連携し、生産数量、単価、納期などの詳細を確認し、サンプルをお客様にご確認いただきます。',
  },
  {
    key: '服务评价',
    value: 'サービス評価',
  },
  {
    key: '已被使用',
    value: 'ユーザー名が既に利用されています',
  },
  {
    key: '关键词',
    value: 'キーワード',
  },
  {
    key: '没有找到商品，请换一个关键词试试',
    value:
     'と関係する商品が見つかりませんでした。再度別のキーワードで検索または担当者までご連絡ください。',
  },
  {
    key: '图片上传中',
    value: '写真のアップロード中',
  },
  {
    key: '图片上传',
    value: '画像添付',
  },
  {
    key: '请输入您想查找的商品名称或者淘宝与1688的商品URL',
    value: 'キーワードまたは1688、タオバオの商品及び店舗URL',
  },
  {
    key: '搜索',
    value: '検索',
  },
  {
    key: '标签名称',
    value: 'タグ',
  },
  {
    key: '提交评价',
    value: '評価を送信する',
  },
  {
    key: '店铺名称',
    value: '店舗名',
  },
  {
    key: '请输入店铺名称',
    value: '店舗名をご記入ください',
  },
  {
    key: '取消收藏',
    value: 'お気に入り外す',
  },
  {
    key: '新增标签',
    value: '新規追加',
  },
  {
    key: '请输入标签名',
    value: 'タグ名をご記入ください',
  },
  {
    key: 'option说明界面',
    value: 'オプションの説明画面',
  },
  {
    key: '上传的图片大小不能超过 10MB!',
    value: 'アップロードされた画像のサイズは10MBを超えることはできません！',
  },
  {
    key: '图片格式不正确！',
    value: '画像の形式が正しくありません！',
  },
  {
    key: '请填写完评价内容再进行提交',
    value: '評価内容を入力してから送信してください',
  },
  {
    key: '确认开通',
    value: '開通を確認する',
  },
  {
    key: '天的有料会員？',
    value: '天的有料会員？',
  },
  {
    key: '件',
    value: '件',
  },
  {
    key: '合计金额',
    value: '合計金額',
  },
  {
    key: '不能全由空格组成',
    value: '不能全由空格组成',
  },
  {
    key: '附加服务',
    value: 'オプションサービス',
  },
  {
    key: '国际物流说明',
    value: '国際送料',
  },
  {
    key: '手续费说明',
    value: '手数料について',
  },
  {
    key: '国际运费说明',
    value: '国際について',
  },
  {
    key: '全部商品',
    value: '全商品',
  },
  {
    key: '首页',
    value: 'マイページ',
  },
  {
    key: '在库数',
    value: '在庫数',
  },
  {
    key: '国内送料',
    value: '中国内送料',
  },
  {
    key: 'スタッフ',
    value: 'スタッフ',
  },
  {
    key: '备选链接',
    value: '予備URL',
  },
  {
    key: '注文書修正',
    value: '注文書修正',
  },
  {
    key: '支払い',
    value: '支払い',
  },
  {
    key: '购物车添加成功',
    value: 'カートに追加されました',
  },
  {
    key: '收藏商品',
    value: 'お気に入り',
  },
  {
    key: '移出收藏夹',
    value: 'お気に入りから削除する',
  },
  {
    key: '删除商品',
    value: '削除',
  },
  {
    key: '商品详情',
    value: '商品詳細',
  },
  {
    key: '属性',
    value: '詳細',
  },
  {
    key: '注文',
    value: '注文',
  },
  {
    key: '数量',
    value: '数量',
  },
  {
    key: '小計(元)',
    value: '小計(元)',
  },
  {
    key: '人民元対円換算',
    value: '人民元対円換算',
  },
  {
    key: '汇率从“三菱UFJ TTS レート”获取',
    value: '三菱UFJ銀行 TTS レート+0.7',
  },
  {
    key: '元',
    value: '元',
  },
  {
    key: '予想費用',
    value: '予想費用',
  },
  {
    key: '总价',
    value: '総額',
  },
  {
    key: '该商品将移出购物车',
    value: 'この商品はカートから削除されます',
  },
  {
    key: '确认',
    value: '確認',
  },
  {
    key: '提醒',
    value: '注意',
  },
  {
    key: '是否将选中的商品移除购物车',
    value: '選択した商品をカートから削除しますか',
  },
  {
    key: '未选择操作的商品',
    value: '操作されていない商品が選択されていません',
  },
  {
    key: '去订货',
    value: '注文へ',
  },
  {
    key: '更改头像',
    value: '画像変更',
  },
  {
    key: '头像上传中',
    value: 'プロフィール画像のアップロード中',
  },
  {
    key: '张',
    value: '枚',
  },
  {
    key: '手续费',
    value: '手数料',
  },
  {
    key: '无手续费',
    value: '手数料なし',
  },
  {
    key: '商品调查',
    value: '商品調査',
  },
  {
    key: '免费',
    value: '無料',
  },
  {
    key: '无',
    value: '无',
  },
  {
    key: '商品调查 免费',
    value: '商品調査 無料',
  },
  {
    key: '普通会員',
    value: '一般会員',
  },
  {
    key: '订单为您优先处理',
    value: '特殊な状況優先処理',
  },
  {
    key: '有料会員',
    value: '有料会員',
  },
  {
    key: '会員',
    value: '会員',
  },
  {
    key: '代行商品金額合計（万円）',
    value: '代行商品金額合計（万円）',
  },
  {
    key: '公平的佣金，巨大的价值',
    value: '透明な手数料で輸入サポート',
  },
  {
    key: '用很少的钱利用Rakumart提供的所有服务，更好的帮助您进口',
    value:
     'Rakumartが提供するサービスをご利用いただく上で、シンプルな手数料でお客様の輸入ビジネスをサポート致します。',
  },
  {
    key: '如果在使用本网站的过程中有不方便的地方或者想要改善的地方，请填写在下方文本框里，有图片也可进行上传，我们会尽可能的改善。',
    value:
     '当サイトの使い勝手がわるいところや改善したい所がございましたら、下記の枠にご記入頂ければ、改善可能の限り対応させていただきます。',
  },
  {
    key: '改善意见',
    value: '改善要望',
  },
  {
    key: '请多多关照。',
    value: '宜しくお願い致します。',
  },
  {
    key: '紹介へ',
    value: '紹介へ',
  },
  {
    key: '图片翻译',
    value: '画像翻訳',
  },
  {
    key: '商品详情介绍图片翻译',
    value: '商品詳細の画像翻訳',
  },
  {
    key: '特殊情况优先处理',
    value: '特殊な状況優先処理',
  },
  {
    key: '您的订单特殊情况下为您优先处理',
    value: 'お客様の注文は特別な状況下で優先的に処理されます',
  },
  {
    key: '普通会员',
    value: '一般会員',
  },
  {
    key: '订单',
    value: '注文書',
  },
  {
    key: '临时保存的订单',
    value: '一時保存（未提出）',
  },
  {
    key: '立即申请',
    value: '今すぐ申し込む',
  },
  {
    key: '推荐给每月订购50万日元以上商品的顾客',
    value: '月に50万円以上の商品を注文されるお客様におすすめです',
  },
  {
    key: '关于规则事项',
    value: 'ルールについて',
  },
  {
    key: '运输中',
    value: '配送中',
  },
  {
    key: '此店铺暂无商品',
    value: 'お勧め商品ありません',
  },
  {
    key: '收藏店铺',
    value: 'お気に入り店舗',
  },
  {
    key: '是否删除该订单？',
    value: 'こちらの注文書を削除されますか？',
  },
  {
    key: '验证手机号',
    value: '携帯番号認証',
  },
  {
    key: '目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%',
    value:
     '現在、お客様のランクに関係なく、会費の支払いと確定後、以降注文の手数料はすべて0％です',
  },
  {
    key: '申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了',
    value:
     '申し込みが承認された日から日数をカウントします。定額会員の期間は月単位ではなく、日数単位で計算されます。たとえば、1月は31日ありますが、1月1日からの有効期間は30日であり、31日は適用されません',
  },
  {
    key: '确定为收费会员后，关于未支付的订单，手续费将自动变为0%',
    value:
     '定額会員に確定した場合、未払いの注文に対して手数料は自動的に0％になります',
  },
  {
    key: '期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)',
    value:
     '中国の法定休日などにより連続して5日以上休業する場合、該当する日数が自動的に追加されます。（例：国慶節、春節など）',
  },
  {
    key: '收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理',
    value:
     '定額会員の有効期限が5日前になると、システムから自動的に通知メールが送信されますが、何らかの理由で遅延する場合もあります。配信できない場合もあるため、有効期限については自己管理をお願いします',
  },
  {
    key: '请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案',
    value:
     '定額会員期間中の変更や返金は基本受け付けられません。事前に手数料（契約金額）を支払っているため、優待のサービスを提供しています。万が一、取引中に何か問題が発生した場合で弊社が原因の場合、定額会員料金の返還以外の補償策を提案させていただきます',
  },
  {
    key: '请至少选择一个问题产品',
    value: '少なくとも1つの問題商品を選択してください',
  },
  {
    key: '节省',
    value: '節約',
  },
  {
    key: '使用记录',
    value: '使用履歴',
  },
  {
    key: '请选择',
    value: '選択してください',
  },
  {
    key: '券编码',
    value: 'クーポンコード',
  },
  {
    key: '中国国内物流状态',
    value: '中国国内物流状態',
  },
  {
    key: '采购完成',
    value: '購入完了',
  },
  {
    key: '售后',
    value: 'アフターサービス',
  },
  {
    key: '资金管理',
    value: '入出金管理',
  },
  {
    key: '账单',
    value: '入出金履歴',
  },
  {
    key: '收藏',
    value: 'お気に入り',
  },
  {
    key: '協力先',
    value: '協力先',
  },
  {
    key: '点',
    value: '円',
  },
  {
    key: '等待付款的订单',
    value: '入金待ち',
  },
  {
    key: '入金',
    value: '入金処理',
  },
  {
    key: '需要支付金额',
    value: '支払額',
  },
  {
    key: '立即付款',
    value: '支払いへ',
  },
  {
    key: 'Excel导入',
    value: 'Excelインポート',
  },
  {
    key: 'Excel导出',
    value: 'Excelエクスポート',
  },
  {
    key: '等待处理的问题产品',
    value: '処理待ち問題商品',
  },
  {
    key: '等待处理',
    value: '処理中',
  },
  {
    key: '请先下载Excel模板表格，再上传文件',
    value:
     'Excelのテンプレートをダウンロードして、ファイルをアップロードしてください',
  },
  {
    key: '前往处理',
    value: '処理へ',
  },
  {
    key: '请选择上传文件',
    value: 'アップロードするファイルを選択してください',
  },
  {
    key: '文件解析成功后，商品将直接在商品库里展示，重复的商品会按照文件编辑好的内容进行覆盖',
    value:
     'ファイルが正常に解析されると、商品は直接商品ライブラリに表示されます。重複する商品はファイルの編集内容に従って上書きされます',
  },
  {
    key: '点击上传',
    value: 'アップロードをクリック',
  },
  {
    key: '文件上传中',
    value: 'ファイルのアップロード中',
  },
  {
    key: '文件大小超出限制',
    value: 'ファイルのサイズが制限を超えています',
  },
  {
    key: '下载模板',
    value: 'テンプレートをダウンロード',
  },
  {
    key: '查看',
    value: '詳細',
  },
  {
    key: '点击或将EXCEL 文件拖拽到这里上传',
    value:
     'ここをクリックするか、Excelファイルをここにドラッグしてアップロードしてください',
  },
  {
    key: '等待付款的国际运费',
    value: '国際送料支払い待ち',
  },
  {
    key: '文件格式仅限Xls和Xlsx',
    value: 'ファイル形式はXlsおよびXlsxのみ対応しています',
  },
  {
    key: '日本语',
    value: '日本語',
  },
  {
    key: '授权许可',
    value: '権限許可',
  },
  {
    key: '普通流程',
    value: '通常の流れ',
  },
  {
    key: '商品到货检品',
    value: '商品の到着と検品',
  },
  {
    key: '上架仓库',
    value: '倉庫への入庫',
  },
  {
    key: '商品从仓库取出',
    value: '商品倉庫からの出庫',
  },
  {
    key: '打包装箱',
    value: '梱包',
  },
  {
    key: '确认商品',
    value: '商品確認',
  },
  {
    key: '授权方法',
    value: '許可方法',
  },
  {
    key: '负责人为您提配送单',
    value: '担当者が配送依頼書を提出',
  },
  {
    key: '配送单报价',
    value: '配送依頼書の見積もり',
  },
  {
    key: '当您授权给我们配送单提出权限后',
    value:
     'RAKUMARTに配送依頼書の提出権限を許可すると、以下の作業が行われます。',
  },
  {
    key: '当您授权给我们出金权限以及提出配送单权限后',
    value:
     'RAKUMARTに入金処理および配送依頼書の提出権限を許可すると、以下の作業が行われます。',
  },
  {
    key: '优点1：缩短发货流程，更快到达日本',
    value: '利点1：出荷手続きが短縮され、日本への到着がより早くなります',
  },
  {
    key: '优点2：每天不需要确认商品状态，只要向负责人传达固定的发货规则，就可以随时发货',
    value:
     '利点2：商品の状態を毎日確認する必要がなく、定められた出荷ルールを担当者に伝えるだけでいつでも出荷できます',
  },
  {
    key: '当您授权后：同意商品配送委托时，可缩短交货期1天以上',
    value:
     '許可をいただいた場合、商品の配送を承認いただくと、納期を1日以上短縮することが可能です',
  },
  {
    key: '对需要开通的授权内容进行确认',
    value: '許可内容を確認します。',
  },
  {
    key: '点击授权按钮，点击“确认”授权。',
    value: '許可ボタンをクリックし、"確認"をクリックして許可します。',
  },
  {
    key: '常用收件地址与进口商信息确认好',
    value: 'よく使用する受取り住所と輸入者の情報を確認します。',
  },
  {
    key: '与负责人联系',
    value: '担当者に連絡します。',
  },
  {
    key: '授权完成',
    value: '許可完了',
  },
  {
    key: '授权RAKUMART公司提交配送委托',
    value: 'RAKUMART社への配送委託の提出を許可します',
  },
  {
    key: '负责人根据订单及配送委托书的余额给予扣款收款权限，余额不足时请再次汇款',
    value:
     '注文と配送委託書に基づいて、担当者は請求と受け取りの権限を与えられます。残高が不足している場合は、再度入金してください',
  },
  {
    key: '当前状态',
    value: '現在の状態',
  },
  {
    key: '取消配送委托权限',
    value: '配送依頼書の権限を取り消します',
  },
  {
    key: '权限许可后，已经进行了包装作业，如果想被取消的话，请尽快联系负责人。',
    value:
     '権限を許可した後、商品の梱包作業が行われている場合、速やかに担当者に連絡してください。',
  },
  {
    key: '余额收款支付权限撤销',
    value: '入金および受け取りの権限を取り消します',
  },
  {
    key: '取消授权',
    value: '許可の取り消し',
  },
  {
    key: '授权取消',
    value: '許可の取消',
  },
  {
    key: '已开通授权',
    value: '許可済み',
  },
  {
    key: '授权',
    value: '許可',
  },
  {
    key: '可随时取消。',
    value: 'いつでも取り消すことができます。',
  },
  {
    key: '用户协议',
    value: '利用規約',
  },
  {
    key: '未授权',
    value: '許可されません',
  },
  {
    key: '订单入金，配送单入金的授权设定已经完成，衷心感谢您的信赖。',
    value:
     '注文の注文及び配送依頼書の入金の権限設定が完了しました。ご信頼頂きありがとうございました。',
  },
  {
    key: '订单入金，配送单入金的授权设定已经取消，衷心感谢您的信赖。',
    value:
     '注文の入金及び配送依頼書の入金の権限設定が取り消されました。ご利用頂きありがとうございました。',
  },
  {
    key: '常用地址设定未完成，请同时设定常用进口商地址和常用收件址。',
    value:
     'よく使用する住所の設定が完了していません。よく使用する輸入者の住所と受け取り住所を同時に設定してください。',
  },
  {
    key: '配送单提出权限设定已经完成，授权许可后，已经在进行打包工作，如果想要被取消的话请务必尽早与负责人联系。',
    value:
     '配送依頼書の提出権限は設定が完了しており、許可を許可した後、商品の梱包作業が行われています。取り消したい場合は、できるだけ早く担当者に連絡してください。',
  },
  {
    key: '许可后，关于已经反映到仓库的商品，只有负责人可以操作，如果想要变动的话，请向负责人确认后进行调整',
    value:
     '許可された後、倉庫に反映されている商品に関しては、担当者のみが操作できます。変更が必要な場合は、担当者に確認して調整してください',
  },
  {
    key: '亚马逊交货的商品可以随时提交配送委托，也可以传达给负责人进行操作',
    value:
     'Amazon配送の場合は、いつでも配送委託を提出したり、担当者に伝えたりすることができます',
  },
  {
    key: '常用地址',
    value: 'よく使用する住所',
  },
  {
    key: '常用进口商地址和常用收件地址相同也可以',
    value: 'よく使用する輸入者の住所と受け取り住所は同じでも構いません',
  },
  {
    key: '只支持上传格式为png，jpg，jpeg，pneg文件，最大可上传三张',
    value: '対応できるファイル形式：png，jpg，jpeg，pneg最大３枚まで',
  },
  {
    key: '设定为常用收件地址',
    value: 'よく使用する受け取り住所に設定する',
  },
  {
    key: '设定为常用进口商',
    value: 'よく使用する輸入者に設定する',
  },
  {
    key: '已设为常用',
    value: 'よく使用する住所に設定済み',
  },
  {
    key: '请输入关键词搜索',
    value: 'キーワードをご入力してください',
  },
  {
    key: '通知公告',
    value: 'お知らせ',
  },
  {
    key: '授权内容',
    value: '許可内容',
  },
  {
    key: '写真上传中',
    value: '写真上传中',
  },
  {
    key: '出入金详细',
    value: '入出金履歴詳細',
  },
  {
    key: '订单号/派送单号/内容',
    value: '注文番号/配送番号/内容',
  },
  {
    key: '订单管理',
    value: '注文管理',
  },
  {
    key: '请输入咨询内容',
    value: 'お問い合わせ内容を入力してください',
  },
  {
    key: '全部优惠券',
    value: 'すべてのクーポン',
  },
  {
    key: '券类别',
    value: '券種類',
  },
  {
    key: '类别',
    value: '検索サイト',
  },
  {
    key: '额度/可用次数',
    value: '割引額',
  },
  {
    key: '有效日期',
    value: '有効期限',
  },
  {
    key: '使用状态',
    value: '利用状態',
  },
  {
    key: '使用时间',
    value: '利用日',
  },
  {
    key: '已过期',
    value: '期限切れ',
  },
  {
    key: '子账号信息编辑',
    value: '社員アカウント',
  },
  {
    key: '订单号',
    value: '注文番号',
  },
  {
    key: '已选择',
    value: '選択中',
  },
  {
    key: '请输入订单号',
    value: '注文番号',
  },
  {
    key: '问题商品',
    value: '問題商品',
  },
  {
    key: '当列表里没有您需要的服务时，请直接与负责人进行联系',
    value: '内容に応じて個別で対応させていただきます',
  },
  {
    key: '仓库',
    value: '倉庫',
  },
  {
    key: '隐藏',
    value: '隠す',
  },
  {
    key: '展开',
    value: '展開',
  },
  {
    key: '标签设置',
    value: 'ラベル編集',
  },
  {
    key: '剩余在库数量',
    value: '在庫残量',
  },
  {
    key: '为您的私人品牌生产定制包装，标签，定制摄影，ODM定制服务，按照您的要求进行处理，定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价',
    value:
     '各商品に対する個別での付属サービス依頼が可能になります、内容に応じて個別で対応させていただきます。',
  },
  {
    key: '配送单',
    value: '配送依頼書',
  },
  {
    key: '报价中配送单',
    value: '見積もり中の配送伝票',
  },
  {
    key: '最低的的手续费，为您降低进口成本',
    value: '最低手数料で、輸入コストを削減します',
  },
  {
    key: '个人信息管理',
    value: '個人情報管理',
  },
  {
    key: '注册信息修改',
    value: '登録情報変更',
  },
  {
    key: '我的收货地址',
    value: '配送先管理',
  },
  {
    key: '子账户',
    value: '社員用アカウント',
  },
  {
    key: '「RAKUMART有料会員制度」',
    value: '「RAKUMART定額会員制度」',
  },
  {
    key: '成为有料会員，享受更多优惠',
    value: '定額会員になって、さらなる特典をお楽しみください',
  },
  {
    key: '亚马逊仓库',
    value: 'Amazon倉庫',
  },
  {
    key: '雅虎仓库',
    value: 'Yahoo倉庫',
  },
  {
    key: 'EMS中邮海外仓',
    value: 'EMS国際倉庫',
  },
  {
    key: '乐天仓库',
    value: '楽天倉庫',
  },
  {
    key: '配送先管理',
    value: '配送先管理',
  },
  {
    key: '更改密码',
    value: 'パスワードを変更する',
  },
  {
    key: '预购订单',
    value: '予約注文',
  },
  {
    key: '全部订单',
    value: 'すべての注文書',
  },
  {
    key: '报价中',
    value: '見積中',
  },
  {
    key: '等待付款',
    value: '支払い待ち',
  },
  {
    key: '采购完了',
    value: '買付完了',
  },
  {
    key: '正在购买',
    value: '買付中',
  },
  {
    key: '密码变更',
    value: 'パスワードの変更',
  },
  {
    key: '手机号变更',
    value: '携帯電話番号の変更',
  },
  {
    key: '邮箱变更',
    value: 'メールアドレスの変更',
  },
  {
    key: '身份认证',
    value: '身分確認',
  },
  {
    key: '取消',
    value: 'キャンセル',
  },
  {
    key: '欢迎来到',
    value: 'ようこそ',
  },
  {
    key: '确认删除选中的商品吗？',
    value: '選択した商品を削除しますか？',
  },
  {
    key: '确认删除该商品吗？',
    value: 'この商品を削除しますか？',
  },
  {
    key: '长度在 1 到 64 个字符',
    value: '半角1文字以上64文字以内に収めてください',
  },
  {
    key: '长度在 1 到 200 个字符',
    value: '半角1文字以上200文字以内に収めてください',
  },
  {
    key: '残金支払い',
    value: '残金支払い',
  },
  {
    key: '銀行入金',
    value: '銀行入金',
  },
  {
    key: '残高',
    value: '残高',
  },
  {
    key: '残り',
    value: '残り',
  },
  {
    key: '支払い額',
    value: '支払い額',
  },
  {
    key: '見積金額',
    value: '見積金額',
  },
  {
    key: '注文番号',
    value: '注文番号',
  },
  {
    key: '最低入金金額',
    value: '最低入金金額',
  },
  {
    key: '*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为在报价完成时被修正，请直接提交报价。',
    value:
     '*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、そのままお見積りをご提出下さいませ。',
  },
  {
    key: '开始日期',
    value: '開始日',
  },
  {
    key: '至',
    value: '～',
  },
  {
    key: '结束日期',
    value: '終了日',
  },
  {
    key: '交易方式',
    value: '取引方法',
  },
  {
    key: '出金额',
    value: '出金額',
  },
  {
    key: '确认入账时间',
    value: '入金確認日時',
  },
  {
    key: '入金额',
    value: '入金額',
  },
  {
    key: '残金',
    value: '残金',
  },
  {
    key: '提出时间',
    value: '提出時間',
  },
  {
    key: '注文書・配送書',
    value: '注文書・配送書',
  },
  {
    key: '注文書',
    value: '注文書',
  },
  {
    key: '配送書',
    value: '配送書',
  },
  {
    key: '内容',
    value: '内容',
  },
  {
    key: '账单类型',
    value: '账单类型',
  },
  {
    key: '搜 索',
    value: '索狩り',
  },
  {
    key: '请先选择要导出的时间范围',
    value: '出力される時間範囲を指定してください',
  },
  {
    key: '收费说明',
    value: '詳細',
  },
  {
    key: 'RAKUMART在交易的过程中会向客户收取手续费。',
    value: 'RAKUMARTは代行買付サービスに手数料をお客様にご請求しています。',
  },
  {
    key: '手续费的收取标准是根据订单金额百分比进行计算，百分比大小与客户的会员等级有关。',
    value:
     '手数料は注文金額の割合に基づいて計算されますが、割合の大きさはお客様の会員レベルによって異なります。',
  },
  {
    key: '请注意手续费只是基本工作的费用。',
    value: '手数料は基本的な業務費用であることにご注意ください。',
  },
  {
    key: '对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。',
    value:
     '下記の料金表に掲載されていない操作やサービスについても、柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。',
  },
  {
    key: '月采购额',
    value: '月買付額',
  },
  {
    key: '待打款',
    value: '振込待ち',
  },
  {
    key: '已拒绝',
    value: '拒否',
  },
  {
    key: '已打款',
    value: '送金済み',
  },
  {
    key: '导出excel',
    value: 'エクセル導出',
  },
  {
    key: '检品中',
    value: '検品中',
  },
  {
    key: '订单状态',
    value: '注文書状態',
  },
  {
    key: '操作',
    value: '操作',
  },
  {
    key: '提出日期',
    value: '提出日',
  },
  {
    key: '发货日期',
    value: '出荷日',
  },
  {
    key: '包含订单',
    value: '関係注文書',
  },
  {
    key: '报价中订单',
    value: '見積中',
  },
  {
    key: '订单待付款',
    value: '支払い待ち',
  },
  {
    key: '付款日期',
    value: '入金日',
  },
  {
    key: '购买完成日期',
    value: '買付完了日',
  },
  {
    key: '购买完成',
    value: '買付完了',
  },
  {
    key: '商品数',
    value: '商品数',
  },
  {
    key: '暂无数据',
    value: 'データがありません',
  },
  {
    key: '处理意见',
    value: '処理',
  },
  {
    key: '单价（元）',
    value: '単価（元）',
  },
  {
    key: '全部',
    value: 'すべて',
  },
  {
    key: '等待发货',
    value: '出荷待ち',
  },
  {
    key: '添加至收藏夹',
    value: 'お気に入りに追加',
  },
  {
    key: '可用次数',
    value: '利用可能な回数',
  },
  {
    key: '支付完成',
    value: '支払いが完了しました',
  },
  {
    key: '我们为您管理一切国际物流',
    value: '国際送料詳細',
  },
  {
    key: 'Rakumart为您管理和执行从中国进口的整个物流流程。',
    value: '私たちは、中国からの輸入物流の管理と実行を担当し',
  },
  {
    key: '利用Rakumart提供的所有资源，彻底改变您从中国购买和进口的方式，为您的业务节省时间和成本。',
    value:
     'Rakumartを利用して中国からの商品の購入、輸入の方法を根本的に変え、ビジネスに時間短縮とコストダンする様々な配送方法を提供させていただきます。',
  },
  {
    key: '成本',
    value: 'コスト',
  },
  {
    key: '物流纳期一览',
    value: '一覧',
  },
  {
    key: '物流名称',
    value: '物流会社',
  },
  {
    key: '国际物流运费预估',
    value: '見積ツール',
  },
  {
    key: '国际物流简易比较',
    value: '国際送料　簡易比較',
  },
  {
    key: '物流收费说明',
    value: '詳細',
  },
  {
    key: '注意事項',
    value: '注意事項',
  },
  {
    key: '根据会员级别不同',
    value: 'お客様の販売状況に応じ多種な対応サポート',
  },
  {
    key: '请输入处理意见',
    value: '処理意見を入力してください',
  },
  {
    key: '快捷处理',
    value: 'クイック処理',
  },
  {
    key: '仅当前账号',
    value: '現在のアカウントのみ',
  },
  {
    key: '其他费用明细',
    value: 'その他費用明細',
  },
  {
    key: '普通仓库',
    value: '倉庫',
  },
  {
    key: '收费仓库',
    value: '課金倉庫',
  },
  {
    key: '最终订单金额',
    value: '最終金額',
  },
  {
    key: '名义',
    value: '内容',
  },
  {
    key: '调整金额',
    value: '調整額',
  },
  {
    key: '返金额（円）',
    value: '返金額（円）',
  },
  {
    key: '追加额（円）',
    value: '追加費用（円）',
  },
  {
    key: '备选链接选择：',
    value: '备选链接选择：',
  },
  {
    key: '点击展开显示明细',
    value: 'クリックして明細展開',
  },
  {
    key: '点击收起明细',
    value: 'クリックして閉じる',
  },
  {
    key: '费用（元）',
    value: '費用（元）',
  },
  {
    key: '注文書',
    value: '注文書',
  },
  {
    key: '订单汇率',
    value: 'レート',
  },
  {
    key: '类目',
    value: '分類',
  },
  {
    key: '金额（元）',
    value: '金额（元）',
  },
  {
    key: '金额（円）',
    value: '金额（円）',
  },
  {
    key: '当前链接：',
    value: '当前链接：',
  },
  {
    key: '小计',
    value: '小計',
  },
  {
    key: '国内运费',
    value: '国内運賃',
  },
  {
    key: '备注栏',
    value: '備考欄',
  },
  {
    key: '已选商品',
    value: '選択中商品',
  },
  {
    key: '状态',
    value: '状態',
  },
  {
    key: '店铺名',
    value: '店舗名',
  },
  {
    key: '子账户信息编辑',
    value: '子账户信息编辑',
  },
  {
    key: '备注',
    value: '備考欄',
  },
  {
    key: '使用中',
    value: '利用中',
  },
  {
    key: '禁用',
    value: '停止中',
  },
  {
    key: '配送单列表',
    value: '配送依頼書',
  },
  {
    key: '中止',
    value: '停止中',
  },
  {
    key: '* 请输入6到16位的密码，可由字母数字及@符号组成，不支持空格。',
    value:
     '* パスワードは6から16桁の英数字と@記号で構成される必要があります,\n' +
     'スペースは使用できません。',
  },
  {
    key: '回到首页',
    value: '先頭へ',
  },
  {
    key: '售后出金',
    value: 'アフター出金',
  },
  {
    key: '售后入金',
    value: 'アフター入金',
  },
  {
    key: '未匹配到配送单详情',
    value: '配送の詳細が一致しませんでした',
  },
  {
    key: '未匹配到相关订单',
    value:
     'データ取得エラー、ページを閉じて、再度操作をお願いします。\n' +
     'それでも操作できない場合は担当者までご連絡ください。',
  },
  {
    key: '配送单回退追收',
    value: '配送伝票の返金追加請求',
  },
  {
    key: '配送单回退入金',
    value: '配送伝票の返金入金',
  },
  {
    key: '开通付费会员出金',
    value: '有料会員の開通出金',
  },
  {
    key: '装箱中',
    value: '梱包中',
  },
  {
    key: '发货中',
    value: '配送手配中',
  },
  {
    key: '提出配送单',
    value: '提出',
  },
  {
    key: '装箱数',
    value: '梱包数',
  },
  {
    key: '临时保存',
    value: '一時保存',
  },
  {
    key: '箱规（cm）',
    value: '箱の仕様（cm）',
  },
  {
    key: '实际重量（kg）',
    value: '実際の重量（kg）',
  },
  {
    key: '配送单单番备注',
    value: '配送伝票の番号備考',
  },
  {
    key: '追加到配送单',
    value: '配送依頼書へ追加',
  },
  {
    key: '上架中',
    value: '入庫待ち',
  },
  {
    key: '上架完成',
    value: '倉庫',
  },
  {
    key: '已签收',
    value: 'RAKUMART倉庫到着',
  },
  {
    key: '配送单号',
    value: '配送依頼書',
  },
  {
    key: '请输入配送单号搜索',
    value: '配送番号記入して検索',
  },
  {
    key: '商品照片',
    value: '画像',
  },
  {
    key: '所在配送单箱号',
    value: '所在箱No',
  },
  {
    key: '箱数',
    value: '箱数',
  },
  {
    key: '订单支付入金',
    value: '注文の支払い入金',
  },
  {
    key: '订单支付出金',
    value: '注文の支払い出金',
  },
  {
    key: '配送单支付入金',
    value: '配送伝票の支払い入金',
  },
  {
    key: '配送单支付出金',
    value: '配送伝票の支払い出金',
  },
  {
    key: '人工调整',
    value: '手動調整',
  },
  {
    key: '商品调整出金',
    value: '商品調整出金',
  },
  {
    key: '商品调整入金',
    value: '商品調整入金',
  },
  {
    key: '客户提现',
    value: '顧客の引き出し',
  },
  {
    key: '开通付费会员出金',
    value: '有料会員の開通出金',
  },
  {
    key: '配送单回退入金',
    value: '配送依頼書キャンセル入金',
  },
  {
    key: '充值',
    value: 'チャージ',
  },
  {
    key: '公司名',
    value: '会社名',
  },
  {
    key: '住所',
    value: '住所',
  },
  {
    key: '公司地址',
    value: '公司地址',
  },
  {
    key: '公司营业执照',
    value: '公司营业执照',
  },
  {
    key: '银行账户',
    value: '口座番号',
  },
  {
    key: '銀行名',
    value: '銀行名',
  },
  {
    key: '出金金額',
    value: '出金金額',
  },
  {
    key: '汇款金额',
    value: '送金額',
  },
  {
    key: '汇款截图',
    value: '送金のスクリーンショット',
  },
  {
    key: '当前账户余额',
    value: 'アカウント残高',
  },
  {
    key: '户名',
    value: '户名',
  },
  {
    key: '请输入户名',
    value: '口座名義をご記入ください',
  },
  {
    key: '提现申请原因',
    value: '出金申請原因',
  },
  {
    key: '口座名義',
    value: '口座名義',
  },
  {
    key: '原',
    value: '原',
  },
  {
    key: '确认提交',
    value: '提出',
  },
  {
    key: '余额提现明细',
    value: '残高出金詳細',
  },
  {
    key: '提现申请发起失败，余额不足，无法发起提现',
    value:
     '出金依頼が失敗しました。残高が不足しているため、出金依頼を行うことができません',
  },
  {
    key: '提现申请发起成功',
    value: '出金依頼が正常に発行されました',
  },
  {
    key: '最晚1-2个工作日资金自动打入账户',
    value: '最長1〜2営業日以内に資金が自動的にアカウントに振り込まれます',
  },
  {
    key: '请输入银行账户',
    value: '口座番号をご記入ください',
  },
  {
    key: '请输入提现金额',
    value: '金額をご記入ください',
  },
  {
    key: '请您核对以下信息是否正确!',
    value: '以下の情報が正しいかどうかご確認ください!',
  },
  {
    key: '以下单子在同一个包裹进行发货',
    value: '以下配送書の商品は同梱発送されます。',
  },
  {
    key: '支付方式',
    value: '支払い方法',
  },
  {
    key: '明细',
    value: '詳細',
  },
  {
    key: 'オプション详情',
    value: 'オプション详情',
  },
  {
    key: '账户余额',
    value: '账户余额',
  },
  {
    key: '提现记录',
    value: '提现记录',
  },
  {
    key: '提现',
    value: '残高出金',
  },
  {
    key: '发起时间',
    value: '提出時間',
  },
  {
    key: '流水号',
    value: '番号',
  },
  {
    key: '收款户名',
    value: '口座名義',
  },
  {
    key: '收款户号',
    value: '口座番号',
  },
  {
    key: '请输入口座番号',
    value: '请输入口座番号',
  },
  {
    key: '请输入你的问题或者关键字',
    value: '質問内容または関連キーワード',
  },
  {
    key: '帮助中心',
    value: 'よくある質問',
  },
  {
    key: 'option公司费用介绍',
    value: 'option公司费用介绍',
  },
  {
    key: '仓库保管说明',
    value: '倉庫保管費用',
  },
  {
    key: '提现金额',
    value: '出金額',
  },
  {
    key: '定额会员',
    value: '定額会員',
  },
  {
    key: '普检精检详细说明',
    value: '検品について',
  },
  {
    key: '银行名',
    value: '銀行名',
  },
  {
    key: '配送单整体评价',
    value: '今回の仕入れについての総合評価',
  },
  {
    key: '商品包装是否满意',
    value: '商品個別の梱包',
  },
  {
    key: '預金種目',
    value: '預金種目',
  },
  {
    key: '商品整体满意度',
    value: '商品満足度',
  },
  {
    key: '支店番号',
    value: '支店番号',
  },
  {
    key: '支店名',
    value: '支店名',
  },
  {
    key: '处理状态',
    value: '処理状態',
  },
  {
    key: '查看凭证',
    value: '詳細凭证',
  },
  {
    key: '下载',
    value: '下载',
  },
  {
    key: '条',
    value: '件',
  },
  {
    key: '确认关闭',
    value: 'まだ保存されていません、それでも閉じますか？',
  },
  {
    key: '商品金額.元',
    value: '商品金額',
  },
  {
    key: '国内送料.元',
    value: '中国内送料.元',
  },
  {
    key: 'オプション手数料.元',
    value: 'オプション手数料',
  },
  {
    key: '商品码',
    value: '商品コード',
  },
  {
    key: '标签号',
    value: 'ラベル番号',
  },
  {
    key: '请输入订单号搜索',
    value: '注文番号検索',
  },
  {
    key: '请输入关键词',
    value: 'キーワードをご記入ください',
  },
  {
    key: '开始时间',
    value: '開始日',
  },
  {
    key: '选择',
    value: '選択',
  },
  {
    key: '照片',
    value: '画像',
  },
  {
    key: '商品链接',
    value: '商品リンク',
  },
  {
    key: '单价',
    value: '単価',
  },
  {
    key: '商品状态',
    value: '商品状態',
  },
  {
    key: '问题写真',
    value: '問題商品写真',
  },
  {
    key: '配送单附件',
    value: '配送依頼書添付ファイル',
  },
  {
    key: '标签信息',
    value: 'ラベル情報',
  },
  {
    key: '所在箱子',
    value: '所在箱',
  },
  {
    key: '问题数',
    value: '数量',
  },
  {
    key: '问题详细',
    value: '問題点',
  },
  {
    key: '正常',
    value: 'Normal',
  },
  {
    key: '座机',
    value: '電話番号',
  },
  {
    key: '添加',
    value: '追加',
  },
  {
    key: '是否提交座机与注意事项修改',
    value: '固定電話番号と注意事項の変更を送信しますか',
  },
  {
    key: '提示',
    value: '注意',
  },
  {
    key: '一時保存しました。',
    value: '一時保存しますか？',
  },
  {
    key: '一時保存',
    value: '一時保存',
  },
  {
    key: '注文を提出',
    value: '注文を提出',
  },
  {
    key: '写真',
    value: '写真',
  },
  {
    key: '商品详细',
    value: '商品詳細',
  },
  {
    key: '调整前数据',
    value: '調整前',
  },
  {
    key: '调整后数据',
    value: '調整後',
  },
  {
    key: '请输入想搜索的URL',
    value: '商品キーワードまたはアリババ、タオバオのURL',
  },
  {
    key: '订购数量',
    value: '発注数',
  },
  {
    key: '单价(元)',
    value: '単価（元）',
  },
  {
    key: '标签',
    value: 'タグ',
  },
  {
    key: '备考栏',
    value: '備考欄',
  },
  {
    key: '同店铺热销',
    value: '店舗人気商品',
  },
  {
    key: '前往店铺',
    value: '店舗へ',
  },
  {
    key: '是否取消收藏此店铺？',
    value: '店舗のお気に入りを取り消しますか？',
  },
  {
    key: '添加一行',
    value: '追加',
  },
  {
    key: '历史标签',
    value: '履歴',
  },
  {
    key: '请输入标签号',
    value: 'コード',
  },
  {
    key: '请输入商品码',
    value: 'ASIN',
  },
  {
    key: '关于RAKUMART',
    value: 'rakumartについて',
  },
  {
    key: '请输入FBA码',
    value: '请输入FBA码',
  },
  {
    key: '商品庫から追加',
    value: '商品庫から追加',
  },
  {
    key: '编辑',
    value: '編集',
  },
  {
    key: '单价.元',
    value: '単価.元',
  },
  {
    key: '小计.元',
    value: '小計.元',
  },
  {
    key: '请输入1688链接或taobao链接或RAKUMART链接',
    value: '1688、taobaoまたはRAKUMARTのURLを入力してください',
  },
  {
    key: '*请务必填写附带*的项目',
    value: '*ついている項目は必ずご記入ください',
  },
  {
    key: '入金日',
    value: '入金日',
  },
  {
    key: '项目',
    value: '項目',
  },
  {
    key: '出入金记录',
    value: '入出金履歴',
  },
  {
    key: '中国国内物流',
    value: '中国国内物流状態',
  },
  {
    key: '待发货',
    value: '配送待ち',
  },
  {
    key: '配送地址管理',
    value: '配送先管理',
  },
  {
    key: '收藏夹',
    value: 'お気に入り（商品）',
  },
  {
    key: '视频',
    value: 'ビデオ',
  },
  {
    key: '*ついている項目は必ずご記入ください',
    value: '*ついている項目は必ずご記入ください',
  },
  {
    key: '入金額',
    value: '入金額',
  },
  {
    key: '入金名義カタカナ記載',
    value: '入金名義カタカナ記載',
  },
  {
    key: 'カタカナ記載',
    value: 'カタカナ記載',
  },
  {
    key: '入金名義',
    value: '入金名義',
  },
  {
    key: '着金予定日',
    value: '着金予定日',
  },
  {
    key: '请输入着金予定日',
    value: '请输入着金予定日',
  },
  {
    key: '请输入入金名義',
    value: '请输入入金名義',
  },
  {
    key: '*请填写整数',
    value: '*整数をご記入ください',
  },
  {
    key: '請求料金',
    value: '請求料金',
  },
  {
    key: '提出',
    value: '提出',
  },
  {
    key: '当前订单状态',
    value: '注文書状態',
  },
  {
    key: '金额变更明细',
    value: '金額変更詳細',
  },
  {
    key: '入金时金额',
    value: '入金時最終',
  },
  {
    key: '提交成功',
    value: '入金時最終',
  },
  {
    key: '※ 请勿在汇款报告的备注栏中填写问题。\n因为担当不同所以不能传达。',
    value:
     '※入金報告の備考欄で質問を入れないでください。\n' +
     '担当が異なりますので伝わりません。',
  },
  {
    key: '国际运费待支付',
    value: '国际运费待支付',
  },
  {
    key: '优惠券',
    value: 'クーポン券',
  },
  {
    key: '所有订单',
    value: 'すべての注文書',
  },
  {
    key: '进口商',
    value: '輸入者',
  },
  {
    key: '邮编',
    value: '郵便番号',
  },
  {
    key: '新增地址',
    value: '新しい住所を追加する',
  },
  {
    key: '编辑地址',
    value: '住所を編集する',
  },
  {
    key: '选中',
    value: '選択中',
  },
  {
    key: '選択中の商品',
    value: '選択中の商品',
  },
  {
    key: '开始时间不能晚于结束时间',
    value: '開始時間は終了時間よりも遅くすることはできません',
  },
  {
    key: '结束时间不能早于开始时间',
    value: '終了時間は開始時間よりも早くすることはできません',
  },
  {
    key: '支付金额不能大于100000000日元',
    value: '金額は100000000円を超えてはいけない',
  },
  {
    key: '结束时间',
    value: '終了日',
  },
  {
    key: '找回密码',
    value: 'パスワード変更',
  },
  {
    key: '操作成功',
    value: '操作が成功しました',
  },
  {
    key: '移除成功',
    value: '削除が成功しました',
  },
  {
    key: '日元',
    value: '日本円',
  },
  {
    key: '重置',
    value: 'リセット',
  },
  {
    key: '检品option费',
    value: '検品オプション料金',
  },
  {
    key: '发送option费',
    value: '配送オプション費用',
  },
  {
    key: '输入备注',
    value: '備考を入力してください',
  },
  {
    key: '请输入商品名称',
    value: '商品名を入力してください',
  },
  {
    key: '新增',
    value: '追加',
  },
  {
    key: '从仓库选择地址',
    value: '倉庫から住所を選択する',
  },
  {
    key: '地址标题',
    value: '住所のタイトル',
  },
  {
    key: '请输入地址标题',
    value: '住所のタイトルを入力してください',
  },
  {
    key: '密码验证',
    value: 'パスワードの確認',
  },
  {
    key: '手机号验证',
    value: '携帯電話番号の確認',
  },
  {
    key: '未验证',
    value: '未認証',
  },
  {
    key: '立即验证',
    value: '今すぐ認証へ',
  },
  {
    key: '邮箱验证',
    value: 'メールアドレスの確認',
  },
  {
    key: '安全验证',
    value: 'セキュリティの検証',
  },
  {
    key: '请输入新密码',
    value: '新しいパスワードを入力してください',
  },
  {
    key: '请再次输入新密码确认',
    value: '新しいパスワードを再入力して確認してください',
  },
  {
    key: '调整后option',
    value: '調整后option',
  },
  {
    key: '调整前option',
    value: '調整前option',
  },
  {
    key: '两次输入密码不一致',
    value: '入力した2つのパスワードが一致しません',
  },
  {
    key: '设置新密码',
    value: '新しいパスワードを設定してください',
  },
  {
    key: '找回成功',
    value: 'パスワードの再設定が成功しました',
  },
  {
    key: '账号未注册',
    value: 'アカウントが登録されていません',
  },
  {
    key: '请输入您注册时使用的邮箱',
    value: '登録時に使用したメールアドレスを入力してください',
  },
  {
    key: '请输入您注册时使用的手机号',
    value: '登録時の携帯番号を入力してください',
  },
  {
    key: 'RAKUMART帮助中心',
    value: 'RAKUMART　よくある質問',
  },
  {
    key: '下一步',
    value: '次へ',
  },
  {
    key: '请再次输入密码',
    value: 'パスワードを再入力',
  },
  {
    key: '输入密码和前一次输入的不一致',
    value: 'パスワードと上の入力が一致しません。再度入力してください。',
  },
  {
    key: '更改成功',
    value: '変更が完了しました',
  },
  {
    key: '临时保存的配送单',
    value: '一時保存（未提出）',
  },
  {
    key: '所有配送单',
    value: 'すべての配送書',
  },
  {
    key: '货物装箱中',
    value: '梱包中',
  },
  {
    key: '国际运费待付款',
    value: '支払い待ち',
  },
  {
    key: '国际物流待发货',
    value: '物流業者へ輸送中',
  },
  {
    key: '前往日本途中',
    value: '国際輸送中',
  },
  {
    key: '已经收到货物',
    value: '受け取り',
  },
  {
    key: '已取消',
    value: 'キャンセル',
  },
  {
    key: '格式不正确',
    value: '形式が正しくありません',
  },
  {
    key: '在库更新',
    value: '在庫数更新',
  },
  {
    key: "如果与原始商品页面的库存数量不匹配，请点击'在库更新'",
    value: '在庫数1688，taobaoページと違う場合はクリックして更新。',
  },
  {
    key: "原商品页面的库存数为'0'时，请向负责人咨询，并向店铺确认",
    value:
     '1688，タオバオの実際ページ在庫数0の場合は担当者まで問い合わせください。',
  },
  {
    key: '请输入登录密码',
    value: 'ログインパスワードを入力してください',
  },
  {
    key: '邮箱',
    value: 'メールアドレス',
  },
  {
    key: '时间',
    value: '日付',
  },
  {
    key: '出入金编号',
    value: '入金報告番号',
  },
  {
    key: '请多关照',
    value: 'よろしくお願いします',
  },
  {
    key: '＊您操作入金的金额是等待确认状态。',
    value: 'ご記載の金額は確認待ち状態になります',
  },
  {
    key: '取消日期',
    value: 'キャンセル日',
  },
  {
    key: '错误报告',
    value: 'エラー報告',
  },
  {
    key: '当前搜索结果不是您想查找的商品',
    value: '検索キーワードと違う商品が出てきた',
  },
  {
    key: '搜索不出结果',
    value: '検索結果がない',
  },
  {
    key: '出入金类别',
    value: '出入金種類',
  },
  {
    key: '调整时间',
    value: '調整日付',
  },
  {
    key: '调整具体明细',
    value: '変動明細',
  },
  {
    key: '调整金额（元）',
    value: '調整額（元）',
  },
  {
    key: '入金额（円）',
    value: '入金額（円）',
  },
  {
    key: '出金额（円）',
    value: '出金額（円）',
  },
  {
    key: '全选',
    value: 'すべて選択',
  },
  {
    key: '小于最低入金金额',
    value: '最低入金額を超えるように記入及び振込をお願いします。',
  },
  {
    key: '购买中',
    value: '処理待ち',
  },
  {
    key: '说说你对本次服务的感受',
    value: '今回のサービスについての感想を教えてください',
  },
  {
    key: '已到达rakumart仓库',
    value: '到着作業待ち',
  },
  {
    key: '购买数量',
    value: '購入数量',
  },
  {
    key: '商品作业中',
    value: '到着作業中',
  },
  {
    key: '请至少选择一项通知',
    value: '少なくとも1つの通知を選択してください',
  },
  {
    key: '首款',
    value: '頭金',
  },
  {
    key: '支付定金',
    value: '頭金支払いへ',
  },
  {
    key: '支付尾款',
    value: '残債支払いへ',
  },
  {
    key: '首次付款',
    value: '頭金金額',
  },
  {
    key: '尾款金额',
    value: '残債金額',
  },
  {
    key: '尾款',
    value: '残債',
  },
  {
    key: '请至少选择一个商品',
    value: '少なくとも1つの商品を選択してください',
  },
  {
    key: '是否确认删除选中的内容。',
    value: '選択した内容を削除してもよろしいですか。',
  },
  {
    key: '是否确认删除选中的商品',
    value: '選択された商品を削除してもよろしいですか?',
  },
  {
    key: '当前选择的通知中，包含有未读的通知，是否确认删除选中的内容。',
    value:
     '選択した通知には未読の通知が含まれています。選択した内容を削除してもよろしいですか。',
  },
  {
    key: '标签选择',
    value: 'タグリスト',
  },
  {
    key: '查找相似',
    value: '類似品を検索',
  },
  {
    key: '问题产品批量回复',
    value: '一括返答',
  },
  {
    key: '一个订单最多添加100个商品',
    value: '1つの注文に最大100個の商品を追加できます',
  },
  {
    key: '一个订单最多添加200个商品',
    value: '1つの注文に最大200個の商品を追加できます',
  },
  {
    key: '两次输入的密码不一致',
    value: '入力されたパスワードが一致しません',
  },
  {
    key: '请输入 6 到 16 位包含大小写字母和数字的密码',
    value:
     '6から16文字の大文字と小文字、数字を含むパスワードを入力してください',
  },
  {
    key: '已取消删除',
    value: '削除がキャンセルされました',
  },
  {
    key: '确认删除选中的地址吗？',
    value: '選択したアドレスを削除してもよろしいですか？',
  },
  {
    key: '商品配送信息',
    value: '商品の配送情報',
  },
  {
    key: '订单初始付款',
    value: '支払い済み金額',
  },
  {
    key: '有料会員天数有误',
    value: '有料会員天数有误',
  },
  {
    key: '订单中未找到该商品',
    value: '注文内で商品が見つかりませんでした',
  },
  {
    key: '正在获取图片信息',
    value: '画像情報を取得中です',
  },
  {
    key: '代行専用',
    value: '代行専用',
  },
  {
    key: '商品规格',
    value: '商品詳細',
  },
  {
    key: '图片',
    value: '画像',
  },
  {
    key: '聊天',
    value: 'チャット',
  },
  {
    key: '详情',
    value: '详细',
  },
  {
    key: '详细',
    value: '詳細',
  },
  {
    key: '问题提出时间',
    value: '提出時間',
  },
  {
    key: '店铺',
    value: '店舗名',
  },
  {
    key: '标签编号',
    value: 'ラベル番号',
  },
  {
    key: '附件上传',
    value: 'ファイルのアップロード',
  },
  {
    key: '订单附件',
    value: '注文の添付ファイル',
  },
  {
    key: '返回付款',
    value: '支払いに戻る',
  },
  {
    key: 'その他',
    value: 'その他',
  },
  {
    key: '再提出',
    value: '再提出',
  },
  {
    key: '从购物车中导入更多产品',
    value: 'さらに製品をショッピングカートからインポート',
  },
  {
    key: '删除',
    value: '削除',
  },
  {
    key: '其他费用',
    value: 'その他',
  },
  {
    key: '合计：',
    value: '合計：',
  },
  {
    key: '合计',
    value: '合計',
  },
  {
    key: '抵扣金额',
    value: '控除金額',
  },
  {
    key: '应付',
    value: '支払い予定',
  },
  {
    key: '商品金額',
    value: '商品金額',
  },
  {
    key: '手数料',
    value: '手数料',
  },
  {
    key: '手数料について',
    value: '手数料について',
  },
  {
    key: 'オプション手数料',
    value: 'オプション手数料',
  },
  {
    key: '商品金额变动明细',
    value: '金額変動明細',
  },
  {
    key: '合計金額',
    value: '合計金額',
  },
  {
    key: '国际运输公司选择',
    value: '国際配送業者選択',
  },
  {
    key: '*一箱30KG以内、電池、水は含んだ商品は発送できません。',
    value: '*一箱30KG以内、電池、水は含んだ商品は発送できません。',
  },
  {
    key: '当前限制选择 3 个文件',
    value: '現在は 3 個のファイルの選択が制限されています',
  },
  {
    key: '确定移除',
    value: '削除を確定しますか',
  },
  {
    key: '円金券',
    value: '円金券',
  },
  {
    key: '导出装箱明细',
    value: 'Excelのダウンロード',
  },
  {
    key: '手数料详情',
    value: '手数料詳細',
  },
  {
    key: '收费比例',
    value: '手数料％',
  },
  {
    key: '您输入的验证码不正确，请确认后重新输入',
    value: 'コードが間違っています、再度確認してから入力しなおしてください',
  },
  {
    key: '*单个文件不能超过50MB，一个订单最多上传3个文件。',
    value:
     '*ファイルサイズは最大50MB，３つまで、それ以上の場合は担当者までご連絡ください。',
  },
  {
    key: '提出数量',
    value: '数量',
  },
  {
    key: '添加附件',
    value: 'アップロード',
  },
  {
    key: '附件',
    value: '付属ファイル',
  },
  {
    key: '取 消',
    value: 'キャンセル',
  },
  {
    key: '保 存',
    value: '保 存',
  },
  {
    key: '网站属性选择',
    value: 'ウェブサイト属性の選択',
  },
  {
    key: '人工编辑属性',
    value: '手動で属性を編集する',
  },
  {
    key: '属性名称',
    value: '属性名',
  },
  {
    key: '具体属性值',
    value: '具体的な属性値',
  },
  {
    key: '添加属性',
    value: '添加属性',
  },
  {
    key: '属性编辑',
    value: '商品詳細',
  },
  {
    key: '注文書備考欄',
    value: '注文書備考欄',
  },
  {
    key: '业务员订单备注回复',
    value: '注文書備考欄に対する返答',
  },
  {
    key: '*对该订单有特殊要求的，可以在这边备注备注后会同步显示给业务员',
    value:
     '* 注文書に対する備考欄はこちらへご記入ください\n\n' +
     '* 記載後、担当者へ共有されます',
  },
  {
    key: '人民币',
    value: '人民元',
  },
  {
    key: '订单备注栏',
    value: '注文書備考欄',
  },
  {
    key: '确定',
    value: '確定',
  },
  {
    key: '购物车为空',
    value: 'ショッピングカートは空です',
  },
  {
    key: '商品库为空',
    value: '商品ライブラリは空です',
  },
  {
    key: '使用优惠券',
    value: 'クーポン券利用',
  },
  {
    key: '代金券',
    value: '金券',
  },
  {
    key: '免手续费',
    value: '手数料無料',
  },
  {
    key: '请上传图片',
    value: '画像をアップロードしてください',
  },
  {
    key: '插件使用方法',
    value: 'グーグル拡張機能の利用方法',
  },
  {
    key: '优惠券使用方法',
    value: 'クーポンの使用方法',
  },
  {
    key: '提出发货方法',
    value: '配送依頼書の提出手順',
  },
  {
    key: '下单方法',
    value: '注文方法',
  },
  {
    key: '商品查找方法',
    value: '商品検索方法',
  },
  {
    key: '注册方法',
    value: '登録方法',
  },
  {
    key: '进口流程',
    value: '輸入手続き',
  },
  {
    key: '隐私政策',
    value: 'プライバシーポリシー',
  },
  {
    key: '用户指南',
    value: '初めての方',
  },
  {
    key: '折扣券',
    value: '割引クーポン',
  },
  {
    key: '国际运费',
    value: '国際運賃',
  },
  {
    key: '共',
    value: '共',
  },
  {
    key: '商品种类',
    value: '商品種類',
  },
  {
    key: '问题商品明细',
    value: '問題商品詳細',
  },
  {
    key: '邮编输入错误',
    value: '郵便番号が間違っています',
  },
  {
    key: '邮编格式不正确',
    value: '郵便番号の形式が正しくありません。ご確認ください。',
  },
  {
    key: '发送可能数',
    value: '発送可能数',
  },
  {
    key: '可提出数量',
    value: '出荷可能数',
  },
  {
    key: '装箱数量',
    value: '梱包数',
  },
  {
    key: '已提出数',
    value: '提出済み数量',
  },
  {
    key: '问题产品回复',
    value: '選択中の商品一括返信',
  },
  {
    key: '问题产品',
    value: '問題商品',
  },
  {
    key: '中国国内物流跟进',
    value: '中国国内状態',
  },
  {
    key: '等待购买',
    value: '買付待ち',
  },
  {
    key: '中国国内派送中',
    value: '中国国内配送中',
  },
  {
    key: '国内派送中',
    value: '国内配送中',
  },
  {
    key: '派送中',
    value: '配送中',
  },
  {
    key: '计费数据',
    value: '請求データ',
  },
  {
    key: '收货地址',
    value: 'お届け先住所',
  },
  {
    key: '请输入邮编',
    value: '郵便番号を入力してください',
  },
  {
    key: '提货数量不能为零',
    value: '引き取り数量は0にすることはできません',
  },
  {
    key: '提货数量',
    value: '出荷数',
  },
  {
    key: '单番装箱备注',
    value: '箱詰め備考',
  },
  {
    key: 'ローマ字',
    value: 'ローマ字',
  },
  {
    key: '日期',
    value: '日期',
  },
  {
    key: '不能为空',
    value: '入力が空白です',
  },
  {
    key: '请填写验证码',
    value: '確認コードをご記入ください',
  },
  {
    key: '使用条款和隐私政策还没有被同意。',
    value: 'Rakumart利用規約＆プライバシーポリシーを選択してください。',
  },
  {
    key: '返回重新编辑',
    value: '戻る',
  },
  {
    key: '物流单号',
    value: '国際追跡',
  },
  {
    key: '配送单待报价',
    value: '見積待ち',
  },
  {
    key: '配送单待付款',
    value: '支払い町',
  },
  {
    key: '待出货',
    value: '出荷待ち',
  },
  {
    key: '登录失效',
    value: '現在のログインは無効です、再度ログインしてください。',
  },
  {
    key: '国际配送中',
    value: '国際配送中',
  },
  {
    key: '箱号',
    value: '箱番号',
  },
  {
    key: '实际重量',
    value: '実際の重量',
  },
  {
    key: '权限范围',
    value: '権限範囲',
  },
  {
    key: '是否前往登录?',
    value: 'ログインページに移動しますか?',
  },
  {
    key: '展开',
    value: '展開',
  },
  {
    key: '该商品因库存不足或其他原因已经找不到，请查看其他商品！',
    value:
     'URL無効、こちらの商品は既に在庫切れ、店舗から棚卸しされている状態です。\n' +
     '\n' +
     '1688またはタオバオで問題なく確認できる場合は担当者までご連絡ください。\n' +
     '個別で確認させていただきます。',
  },
  {
    key: '返回首页',
    value: 'トップページへ',
  },
  {
    key: '去登录',
    value: 'ログインへ',
  },
  {
    key: '手机号输入错误',
    value: '携帯電話に誤りがございます',
  },
  {
    key: '提出订单',
    value: '注文を提出する',
  },
  {
    key: '请选择要提出订单的商品',
    value: '注文する商品を選択してください',
  },
  {
    key: '加购商品',
    value: 'カートに追加',
  },
  {
    key: '提出订单',
    value: '注文を提出する',
  },
  {
    key: '编辑订单',
    value: '注文を編集する',
  },
  {
    key: '取消订单',
    value: '注文をキャンセルする',
  },
  {
    key: '订单支付',
    value: '注文を支払う',
  },
  {
    key: '问题产品处理',
    value: '問題のある商品を処理する',
  },
  {
    key: '配送单提出',
    value: '配送依頼を提出する',
  },
  {
    key: '取消配送单',
    value: '配送依頼をキャンセルする',
  },
  {
    key: '配送单支付',
    value: '配送依頼を支払う',
  },
  {
    key: '签收配送单',
    value: '配送依頼を受け取る',
  },
  {
    key: '创建/编辑收货地址',
    value: '配送先住所を作成/編集する',
  },
  {
    key: '删除收货地址',
    value: '配送先住所を削除する',
  },
  {
    key: '长度在 2 到 24 个字符',
    value: '半角2文字以上24文字以内に収めてください',
  },
  {
    key: '价格',
    value: '価格',
  },
  {
    key: '起批量',
    value: '最低ロット',
  },
  {
    key: '特殊要求备注',
    value: '特別要求備考',
  },
  {
    key: '价格从高到低',
    value: '価格が高い順に',
  },
  {
    key: '价格从低到高',
    value: '価格が低い順に',
  },
  {
    key: '选项',
    value: 'オプション',
  },
  {
    key: '小计(元)',
    value: '小計（元）',
  },
  {
    key: '小計',
    value: '小計',
  },
  {
    key: '合计（元）',
    value: '合計（元）',
  },
  {
    key: 'リセット',
    value: 'リセット',
  },
  {
    key: '筛选',
    value: '検索',
  },
  {
    key: '保存',
    value: '保存',
  },
  {
    key: '当前是第一页',
    value: '現在1ページ目',
  },
  {
    key: '没有下一页了',
    value: '最終ページ',
  },
  {
    key: '订单手续费',
    value: '買付手数料',
  },
  {
    key: '入RAKUMART仓库费用',
    value: '入庫費用',
  },
  {
    key: '出RAKUMART仓库费用',
    value: '出庫費用',
  },
  {
    key: '简易检品',
    value: '簡易検品',
  },
  {
    key: '精细检品（表面）',
    value: '詳細検品（表）',
  },
  {
    key: '详细检品（表+里）',
    value: '詳細検品（表+裏）',
  },
  {
    key: '梱包代',
    value: '梱包代',
  },
  {
    key: '商品组套操作费用',
    value: '商品セット化作業',
  },
  {
    key: '数量和种类',
    value: '数量と種類',
  },
  {
    key: '注文書/配送書/内容',
    value: '注文書/配送書/内容',
  },
  {
    key: '请输入新密码',
    value: '新しいパスワードを入力してください',
  },
  {
    key: '请再次输入新密码',
    value: 'もう一度新しいパスワードを入力してください',
  },
  {
    key: '付款',
    value: '支払い',
  },
  {
    key: '体积',
    value: '体積',
  },
  {
    key: '已产生费用',
    value: '課金費用',
  },
  {
    key: '计费天数',
    value: '課金日数',
  },
  {
    key: '天',
    value: '日',
  },
  {
    key: '和',
    value: '和',
  },
  {
    key: '的',
    value: '的',
  },
  {
    key: '个产品',
    value: '个产品',
  },
  {
    key: '商品库',
    value: '商品庫',
  },
  {
    key: '管理番号',
    value: '管理番号',
  },
  {
    key: '商品名称',
    value: '商品名',
  },
  {
    key: '请输入管理番号',
    value: '管理番号を入力してください',
  },
  {
    key: '接受',
    value: '受け入れ',
  },
  {
    key: '提出番数',
    value: '提出番数',
  },
  {
    key: '不良品换货',
    value: '交換',
  },
  {
    key: '提交',
    value: '提出',
  },
  {
    key: '废弃',
    value: '廃棄',
  },
  {
    key: '退货/取消购买',
    value: '返品/購入キャンセル',
  },
  {
    key: '请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。',
    value:
     '処理意見を入力してください。複数の番号を選択した場合、選択された各番号に同じ処理意見が表示されます。',
  },
  {
    key: '请输入您的邮箱',
    value: 'メールアドレスを入力してください',
  },
  {
    key: '标签搜索',
    value: 'タグ検索',
  },
  {
    key: '请输入物流单号',
    value: '追跡番号',
  },
  {
    key: '余额',
    value: '残高',
  },
  {
    key: '不可使用',
    value: '出金確認待ち',
  },
  {
    key: '待确认入金金额',
    value: '確認待ち',
  },
  {
    key: '请输入登录名',
    value: 'ユーザー名を入力してください',
  },
  {
    key: '临时保存待提出',
    value: '未提出',
  },
  {
    key: '氏名',
    value: '氏名',
  },
  {
    key: '子账户管理',
    value: '社員用アカウント情報',
  },
  {
    key: '新規社員',
    value: '新規社員',
  },
  {
    key: '上架完了',
    value: '入庫完了',
  },
  {
    key: '请输入氏名',
    value: '名前を入力してください',
  },
  {
    key: '长度不能超出24个字符',
    value: '长度不能超出24个字符',
  },
  {
    key: '长度不能超出99个字符',
    value: '长度不能超出99个字符',
  },
  {
    key: '实重',
    value: '実重量',
  },
  {
    key: '箱规',
    value: '箱の仕様',
  },
  {
    key: '容积重量',
    value: '容積重量',
  },
  {
    key: '国际物流单号',
    value: '国際輸送伝票番号',
  },
  {
    key: '番号',
    value: '番号',
  },
  {
    key: '操作失败',
    value: '操作が失敗しました',
  },
  {
    key: '邮箱、手机号或密码输入错误',
    value: 'メールアドレス、電話番号、またはパスワードが間違っています',
  },
  {
    key: '用户名或密码错误',
    value: 'パスワードまたはアカウント名が間違っています。',
  },
  {
    key: '已使用',
    value: '利用中',
  },
  {
    key: '个',
    value: '个',
  },
  {
    key: '暂无订单',
    value: '注文書なし',
  },
  {
    key: '暂无配送单',
    value: '配送リストがありません',
  },
  {
    key: '商品金额',
    value: '商品代金',
  },
  {
    key: '签收时间',
    value: '受領時間',
  },
  {
    key: '手数料（5%）',
    value: '手数料（5%）',
  },
  {
    key: 'ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください',
    value:
     'ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください',
  },
  {
    key: '海关',
    value: '海关',
  },
  {
    key: '可用',
    value: '利用可能',
  },
  {
    key: '订单大于',
    value: '注文金額が以上です',
  },
  {
    key: '有效期',
    value: '有効期限',
  },
  {
    key: '请输入新的手机号',
    value: '新しい携帯電話番号を入力してください',
  },
  {
    key: '请输入新的邮箱',
    value: '新しいメールアドレスを入力してください',
  },
  {
    key: '请输入 6 到 16 位的密码,不支持空格',
    value: 'パスワードは6桁以上16桁以内に収めてください',
  },
  {
    key: '部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解',
    value:
     '一部の商品はすでに梱包されており、手動で取り出すことはできません。担当者に問い合わせてください。',
  },
  {
    key: '总成本详细',
    value: '詳細',
  },
  {
    key: '总成本',
    value: '総コスト (総額)',
  },
  {
    key: '成本(元)',
    value: 'コスト(元)',
  },
  {
    key: '单个成本',
    value: '単一のコスト',
  },
  {
    key: '长度不超过25个字符',
    value: '25文字以内で入力してください',
  },
  {
    key: '请输入名字罗马字',
    value: '名前（ローマ字）を入力してください',
  },
  {
    key: '请输入公司名',
    value: '会社名を入力してください',
  },
  {
    key: '请输入邮编',
    value: '郵便番号を入力してください',
  },
  {
    key: '请输入携带電話',
    value: '请输入携带電話',
  },
  {
    key: '在库更新中',
    value: '在庫更新中',
  },
  {
    key: '请输入您正常使用的邮箱地址，将用于重要的验证和通知的接收',
    value:
     '正常に使用するメールアドレスを入力してください。重要な検証と通知の受信に使用されます',
  },
  {
    key: '请输入您正常使用的手机号码，将用于重要的验证和通知的接收',
    value:
     '正常に使用する携帯電話番号を入力してください。重要な検証と通知の受信に使用されます',
  },
  {
    key: '请输入電話',
    value: '请输入電話',
  },
  {
    key: '添加到收藏夹成功',
    value: 'お気に入りに追加しました',
  },
  {
    key: '已发货',
    value: '国際配送中',
  },
  {
    key: '账户余额不足',
    value: 'アカウント残高が不足しています',
  },
  {
    key: '是否取消该订单？',
    value: 'キャンセルしますか？',
  },
  {
    key: '是否取消该订单？',
    value: '是否取消该订单？',
  },
  {
    key: '是否取消该配送单？',
    value: 'キャンセルしますか？',
  },
  {
    key: '查看数据？',
    value: '閲覧？',
  },
  {
    key: '签收',
    value: '受領済み',
  },
  {
    key: '是否签收该配送单？',
    value: 'この配送書を受け取りますか？',
  },
  {
    key: '签收成功',
    value: '受領完了',
  },
  {
    key: '已取消签收',
    value: '受領キャンセル済み',
  },
  {
    key: '已评价',
    value: '評価済み',
  },
  {
    key: '交易生成时间',
    value: '日付',
  },
  {
    key: '余额',
    value: '残高',
  },
  {
    key: '收费仓库里货物需要发货，请直接与您的负责人联系！',
    value: '課金倉庫の商品を発送されたい場合は担当者までご連絡ください。',
  },
  {
    key: '国际物流',
    value: '国際送料',
  },
  {
    key: '手续费（元）',
    value: '手数料（元）',
  },
  {
    key: '全部通知',
    value: 'すべての通知',
  },
  {
    key: '已读',
    value: '既読',
  },
  {
    key: '未读',
    value: '未読',
  },
  {
    key: '通知',
    value: 'お知らせ',
  },
  {
    key: '删除(购物车)',
    value: '削除（ショッピングカート）',
  },
  {
    key: '删除提示',
    value: '削除の確認',
  },
  {
    key: '其他',
    value: 'その他',
  },
  {
    key: '成为Rakumart的会员',
    value: 'rakumart会員登録',
  },
  {
    key: '立即注册',
    value: '今すぐ新規登録',
  },
  {
    key: '寻找产品下单，我们确认报价方案，您可进行付款',
    value:
     'ご希望の商品をカートに入れ、まとめたらオファーをご提出いただきます。弊社より見積提出後、支払いへ',
  },
  {
    key: '下单',
    value: '発注',
  },
  {
    key: '在我们仓库进行质量把控与存储',
    value: '弊社の中国検品センターで日本の消費者基準で検品した後再梱包します。',
  },
  {
    key: '请更换其他支付方式',
    value: '他の支払い方法を選択してください',
  },
  {
    key: '提出发货指令，我们进行物流咨询，给您提供报价方案，由您选择国际运输并付款',
    value: '商品入庫後、発送分の商品をご指定頂ければ発送手配へ',
  },
  {
    key: '等待产品运输到您指定的地点',
    value: '輸出入通関を経てご指定の場所へお届けします',
  },
  {
    key: '工作时间',
    value: '受付時間',
  },
  {
    key: '09:30-18:30(日本時間)',
    value: '日本時間 10:00~18:30（土、日曜日及び中国の祝日を除く）',
  },
  {
    key: '为你推荐',
    value: 'お勧め',
  },
  {
    key: '全部问题',
    value: 'すべて',
  },
  {
    key: '请输入银行名',
    value: '銀行名を入力してください',
  },
  {
    key: '代购',
    value: '代行サービス',
  },
  {
    key: '请输入支店番号',
    value: '支店番号を入力してください',
  },
  {
    key: '请输入支店名',
    value: '支店名を入力してください',
  },
  {
    key: '请输入預金種目',
    value: '預金種目を入力してください',
  },
  {
    key: '请输入口座名義',
    value: '口座名義を入力してください',
  },
  {
    key: '请输入姓氏',
    value: '苗字を入力してください',
  },
  {
    key: '无在库',
    value: '無在庫サービス（D2C）',
  },
  {
    key: '待采购',
    value: '買付中',
  },
  {
    key: '待采购',
    value: '買付中',
  },
  {
    key: 'OEM',
    value: 'OEM、ODMサービス',
  },
  {
    key: '待回复',
    value: '処理中',
  },
  {
    key: '待后续处理',
    value: '処理待ち',
  },
  {
    key: '已处理',
    value: '完了',
  },
  {
    key: '产品',
    value: '商品',
  },
  {
    key: '地址',
    value: '住所',
  },
  {
    key: '全名',
    value: ' 全名',
  },
  {
    key: '请输入全名',
    value: '请输入全名',
  },
  {
    key: 'Rakumart，我们帮助您从中国进口',
    value: 'Rakumart，我们帮助您从中国进口',
  },
  {
    key: 'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。',
    value:
     'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。',
  },
  {
    key: '全面、便捷的中国进口管理。',
    value: '全面、便捷的中国进口管理。',
  },
  {
    key: '专注于您的业务',
    value: '专注于您的业务',
  },
  {
    key: '可发往世界各地，优化所有物流成本',
    value: '可发往世界各地，优化所有物流成本',
  },
  {
    key: '多样化的物流选择',
    value: '多样化的物流选择',
  },
  {
    key: '为所有产品需求提供额外服务',
    value: '为所有产品需求提供额外服务',
  },
  {
    key: '为您全流程的附加服务提供解决方案',
    value: '为您全流程的附加服务提供解决方案',
  },
  {
    key: '出货完成',
    value: '出荷完成',
  },
  {
    key: '出货',
    value: '出荷処理',
  },
  {
    key: '创建新帐户',
    value: '新しいアカウントを作成する',
  },
  {
    key: '请输入名称',
    value: '名前を入力してください',
  },
  {
    key: '商品属性',
    value: '商品情報',
  },
  {
    key: '商品标题',
    value: '商品タイトル',
  },
  {
    key: '规格',
    value: 'その他',
  },
  {
    key: '购入数据',
    value: '購入データ',
  },
  {
    key: '颜色',
    value: 'カラー',
  },
  {
    key: '选择该商品',
    value: 'この商品を選択する',
  },
  {
    key: '回到顶部',
    value: 'トップへ戻る',
  },
  {
    key: '你的最爱',
    value: 'お気に入り',
  },
  {
    key: '资料库',
    value: 'データベース',
  },
  {
    key: '数据获取错误',
    value: 'データの取得エラー',
  },
  {
    key: '*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为估价完成时被修正，请那样提交估价。',
    value:
     '*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、その ままお見積りをご提出下さいませ。',
  },
  {
    key: '国内配送',
    value: '国内配送',
  },
  {
    key: '运费',
    value: '中国送料',
  },
  {
    key: '缺少必填项',
    value: 'この質問は必須です',
  },
  {
    key: '配送单已签收',
    value: '過去の配送履歴',
  },
  {
    key: '到着',
    value: '到着',
  },
  {
    key: '姓氏',
    value: '苗字',
  },
  {
    key: '名称',
    value: '名前',
  },
  {
    key: '店铺信息',
    value: '店舗情報',
  },
  {
    key: '出荷',
    value: '出荷',
  },
  {
    key: '售后详情',
    value: 'アフターサービスの詳細',
  },
  {
    key: '售后列表',
    value: '售后列表',
  },
  {
    key: '发货数',
    value: '发货数',
  },
  {
    key: '赔款金额',
    value: '赔款金额',
  },
  {
    key: '售后完成时间',
    value: '售后完成时间',
  },
  {
    key: '售后单号',
    value: '售后单号',
  },
  {
    key: '售后完成时间',
    value: '售后完成时间',
  },
  {
    key: '查看详情',
    value: '查看详情',
  },
  {
    key: '售后信息',
    value: '售后信息',
  },
  {
    key: '售后编号',
    value: '售后编号',
  },
  {
    key: '出货数量',
    value: '出荷数',
  },
  {
    key: '售后调整原因',
    value: '售后调整原因',
  },
  {
    key: '问题图片',
    value: '问题图片',
  },
  {
    key: '实际付款',
    value: '支払い予',
  },
  {
    key: '具体赔款信息',
    value: '具体赔款信息',
  },
  {
    key: '费用调整名义',
    value: '费用调整名义',
  },
  {
    key: '具体说明',
    value: '具体说明',
  },
  {
    key: '费用变更',
    value: '费用变更',
  },
  {
    key: '出货商品信息',
    value: '出货商品信息',
  },
  {
    key: "密码",
    value: "パスワード"
  },
  {
    key: "忘记密码",
    value: "パスワードを忘れた場合"
  },
  {
    key: "添加地址",
    value: "住所を追加する"
  },
  {
    key: "还没有账号",
    value: "無料"
  },
  {
    key: "已从您的余额扣除待支付的金额。",
    value: "残高より支払いさせて頂きました。"
  },
  {
    key: "支付金额不能大于1000000日元",
    value: "金額は100000000円を超えてはいけない"
  },
  {
    key: "创建新账户",
    value: "新規会員登録"
  },
  {
    key: "标记*的请务必填写",
    value: "※は必ずご記入ください"
  },
  {
    key: "还没有账号",
    value: "新規会員登"
  },
  {
    key: "数据导出中",
    value: "データエクスポート中"
  },
  {
    key: "长度在 1 到 24 个字符",
    value: "半角1文字以上24文字以内に収めてください"
  },
  {
    key: "请输入住所",
    value: "住所を入力してください"
  },
  {
    key: "登录名不能全由数字组成",
    value: "数字のみのユーザー名は対応できません"
  },
  {
    key: "保持登录状态",
    value: "登録状態を維持"
  },
  {
    key: "从淘宝、阿里巴巴、天猫轻松转型进口业务",
    value: "タオバオ・アリババ・天猫から楽々と輸入事業へ"
  },
  {
    key: "请输入商品名搜索",
    value: "商品名検索"
  },
  {
    key: "加入商品库",
    value: "商品ライブラリに追加"
  },
  {
    key: "请输入邮箱/手机号",
    value: "メールアドレス/携帯電話番号を入力してください"
  },
  {
    key: "手机号已被使用",
    value: "携帯電話番号が既に利用されています"
  },
  {
    key: "邮箱已被使用",
    value: "メールアドレスが既に利用されています"
  },
  {
    key: "从商品库中导入更多产品",
    value: "商品庫から追加"
  },
  {
    key: "手机号",
    value: "携帯電話番号"
  },
  {
    key: "手机号为空",
    value: "携帯電話番号が入力されていません。入力してください。"
  },
  {
    key: "手机",
    value: "携帯電話番号"
  },
  {
    key: "倉庫保管費用",
    value: "倉庫保管費用"
  },
  {
    key: "请输入密码",
    value: "パスワードを記入"
  },
  {
    key: "カウント期間",
    value: "カウント期間"
  },
  {
    key: "正在发送验证码",
    value: "確認コードの送信中です"
  },
  {
    key: "一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。",
    value: "一つの注文書に於いて、全ての商品がマイページ『倉庫配送依頼』に入った日（出荷可能日）~ 配送依頼見積書を提出した日（出荷依頼日）の前日まで。"
  },
  {
    key: "無料保管期間",
    value: "無料保管期間"
  },
  {
    key: "通常会員様",
    value: "通常会員様"
  },
  {
    key: "無料保管",
    value: "無料保管"
  },
  {
    key: "月額有料会員様",
    value: "月額有料会員様"
  },
  {
    key: "無料保管期限を過ぎた場合の保管手数料について",
    value: "無料保管期限を過ぎた場合の保管手数料について"
  },
  {
    key: "注意事项",
    value: "注意事項"
  },
  {
    key: "法人番号",
    value: "法人番号"
  },
  {
    key: "標準コード",
    value: "標準コード"
  },
  {
    key: "リアルタイム口座(14桁)",
    value: "リアルタイム口座(14桁)"
  },
  {
    key: "アカウント残高",
    value: "アカウント残高"
  },
  {
    key: "授权管理",
    value: "権限管理"
  },
  {
    key: "您已进行装箱授权，如需要提货请与负责人沟通",
    value: "こちらのアカウントは配送依頼権限を担当者へ許可している状態です、操作される場合は担当者へご連絡ください"
  },
  {
    key: "現在ご利用可能の金額",
    value: "現在ご利用可能の金額"
  },
  {
    key: "入金確認待ち金額",
    value: "入金確認待ち金額"
  },
  {
    key: "不可用金额",
    value: "凍結金額"
  },
  {
    key: "物流失效，请重新选择物流",
    value: "配送方法が無効です。別の配送方法を選択してください"
  },
  {
    key: "失效",
    value: "URL無効"
  },
  {
    key: "在库确认",
    value: "在庫確認"
  },
  {
    key: "您对该商品添加过的数据可以快捷填充，目前标签只支持：FBA，乐天，雅虎",
    value: "現在Amazon、楽天、ヤフー ラベル情報記入可能です"
  },
  {
    key: "不可用",
    value: "不可用"
  },
  {
    key: "余额提现",
    value: "余额提现"
  },
  {
    key: "振込先",
    value: "振込先"
  },
  {
    key: "请输入手机号",
    value: "日本国内の携帯電話を入力してください"
  },
  {
    key: "填写个人信息",
    value: "個人情報入力"
  },
  {
    key: "个人信息",
    value: "個人情報"
  },
  {
    key: "注册完成",
    value: "新規登録完了"
  },
  {
    key: "名字",
    value: "名前"
  },
  {
    key: "名字需要修改，请与业务员进行联系",
    value: "名前を変更する必要があります。担当者に連絡してください"
  },
  {
    key: "变更",
    value: "変更"
  },
  {
    key: "会员到期",
    value: "残り"
  },
  {
    key: "权限管理",
    value: "権限管理"
  },
  {
    key: "验证码",
    value: "確認コード"
  },
  {
    key: "非常感谢您的注册",
    value: "この度新規登録いただきましてありがとうございました。今後ともよろしくお願いいたします。"
  },
  {
    key: "注册完了",
    value: "登録完了"
  },
  {
    key: "立即登录",
    value: "ログインへ"
  },
  {
    key: "个人中心",
    value: "マイページ"
  },
  {
    key: "退出登录",
    value: "ログアウト"
  },
  {
    key: "验证码错误",
    value: "確認コードが違います"
  },
  {
    key: "注文書No",
    value: "注文書No"
  },
  {
    key: "キーワードまたは1688、タオバオの商品URL",
    value: "キーワードまたは1688、タオバオの商品URL"
  },
  {
    key: "登录",
    value: "ログイン"
  },
  {
    key: "注册",
    value: "新規登録"
  },
  {
    key: "初次见面的人",
    value: "初めての方"
  },
  {
    key: "無在庫について",
    value: "無在庫について"
  },
  {
    key: "附加服务说明",
    value: "オプション費用"
  },
  {
    key: "中国会社",
    value: "中国会社"
  },
  {
    key: "密码找回成功",
    value: "新規パスワードの設定完了致しました"
  },
  {
    key: "問い合わせ",
    value: "問い合わせ"
  },
  {
    key: "国際送料詳細",
    value: "国際送料詳細"
  },
  {
    key: "FBA直送サービス",
    value: "FBA直送サービス"
  },
  {
    key: "プライバシーポリシー",
    value: "プライバシーポリシー"
  },
  {
    key: "会員利用規約",
    value: "会員利用規約"
  },
  {
    key: "写真撮影（中国）",
    value: "写真撮影（中国）"
  },
  {
    key: "物流追跡",
    value: "物流追跡"
  },
  {
    key: "收费体系",
    value: "料金体系"
  },
  {
    key: "购物车",
    value: "買い物かご"
  },
  {
    key: "円",
    value: "円"
  },
  {
    key: "カードから追加",
    value: "カードから追加"
  },
  {
    key: 'オプション',
    value: "オプション"
  },
  {
    key: 'オプション选择',
    value: "オプション选择"
  },
  {
    key: "追加",
    value: "追加"
  },
  {
    key: "特色类别",
    value: "特色種類"
  },
  {
    key: "加入购物车",
    value: "ショッピングカートに入れる"
  },
  {
    key: "账号",
    value: "ユーザー名"
  },
  {
    key: "请输入账号或手机号或邮箱",
    value: "ユーザー名／携帯番号／Email"
  },
  {
    key: "其他认证方式",
    value: "他の認証方法"
  },
  {
    key: "無料会員登録",
    value: "無料会員登録"
  },
  {
    key: "利用ガイド",
    value: "利用ガイド"
  },
  {
    key: "确认个人信息",
    value: "個人情報確認"
  },
  {
    key: "登录名",
    value: "ユーザー名"
  },
  {
    key: "密码确认",
    value: "パスワード再確認"
  },
  {
    key: "手机",
    value: "携帯番号"
  },
  {
    key: "获取验证码",
    value: "確認コード取得"
  },
  {
    key: "在Rakumart，您还可以享受到各个仓库的标签服务（比如FBA，乐天，雅虎），产品定制摄影，ODM定制，等额外服务",
    value: "Rakumartでは、さまざまな倉庫でのラベルサービス（FBA、楽天、Yahooなど）や、製品のカスタム撮影、ODM（Original Design Manufacturer）カスタマイズなど、さまざまな追加サービスを提供しています。"
  },
  {
    key: "如果您有自己的私人品牌，太好了！请联系我们。",
    value: "如独自のプライベートブランドをお持ちであれば、ぜひご連絡ください。"
  },
  {
    key: "包装，标签，精细检品",
    value: "梱包、ラベル、精密品検査"
  },
  {
    key: "有料会员",
    value: "定額会員"
  },
  {
    key: "为您的私人品牌生产定制包装，标签，按照您的要求进行",
    value: "お客様のプライベートブランド向けに、カスタムパッケージやラベルを作成、ご要望に沿って対応いたします。"
  },
  {
    key: "個",
    value: "個"
  },
  {
    key: "产品摄影",
    value: "商品写真撮影"
  },
  {
    key: "贴标服务",
    value: "ラベル貼りサービス"
  },
  {
    key: "更换条码，标签",
    value: "バーコード、ラベルの変更"
  },
  {
    key: "更换包装袋",
    value: "パッケージの交換"
  },
  {
    key: "双面打印",
    value: "両面印刷"
  },
  {
    key: "缝制标签",
    value: "縫い付けラベル"
  },
  {
    key: "缝制个性化标签到您的产品上，如纺织品，衣物等。",
    value: "製品にカスタムラベルを縫い付けることも可能です。例えば、紡織品や衣類などです。"
  },
  {
    key: "拆卸织物标签",
    value: "タグの取り外し"
  },
  {
    key: "放置吊牌",
    value: "紙タグの取り付け"
  },
  {
    key: "移除吊牌",
    value: "紙タグの取り外し"
  },
  {
    key: "联合包装",
    value: "共同パッケージ"
  },
  {
    key: "泡沫袋保护",
    value: "泡袋で保護"
  },
  {
    key: "我们可以去掉一些纺织品上，从工厂出来时一直带有的织物标签。",
    value: "工場出荷時に付属している布タグを取り除くことができます。"
  },
  {
    key: "在您的产品上按照您的需求放上您的品牌标签。",
    value: "製品にはお客様のブランドタグを付けることができます。"
  },
  {
    key: "RAKUMART手续费说明",
    value: "RAKUMART 会員手数料について"
  },
  {
    key: "把商品上带有的吊牌进行移除。",
    value: "商品に付属している吊り札を取り外すこともできます。"
  },
  {
    key: "根据客户在本网站采购商品的累计总金额设置手续费，",
    value: "お客様が当サイトで商品を購入する累計総額に基づいて手数料が設定されます。"
  },
  {
    key: "根据采购总额手续费也不同，详情请参照下表。",
    value: "購入総額に応じて手数料が異なりますので、詳細は以下の表をご参照ください。"
  },
  {
    key: "*交易手续费的下调是在交易完成超过等级之后才适用。",
    value: "※手数料の引き下げは、取引金額が一定のレベルを超えた後に適用されます。取引金額は配送依頼書の商品が出荷された後に変更します。"
  },
  {
    key: "例如:实际超过1000万日元以后的交易，适用4.2%的手续费。",
    value: "例えば、実際の取引が1000万円を超えた場合、手数料は4.2%が適用されます。"
  },
  {
    key: "大件物品需要单独报价，请与负责人联系",
    value: "大型商品については、個別に見積もりが必要ですので、担当者にご相談ください"
  },
  {
    key: "默认为A4纸，其他类型纸张需求请联系负责人确认。",
    value: "デフォルトではA4サイズの用紙を使用していますが、他の種類の用紙をご希望の場合は、担当者にご確認ください。"
  },
  {
    key: "在您的产品上按照您的需求放上您的品牌吊牌。",
    value: "製品にはお客様のブランドタグを付けることができます。"
  },
  {
    key: "专业摄影来完成，无论产品什么类型或者多复杂。",
    value: "専門の撮影サービスを提供しており、製品の種類や複雑さに関係なく対応いたします。"
  },
  {
    key: "与我们的负责人协商组装等要求",
    value: "組み立てなどの要件については、担当者と相談してください。"
  },
  {
    key: "请输入意见反馈",
    value: "ご意見を入力してください"
  },
  {
    key: "配送单评价",
    value: "お客様満足度アンケート"
  },
  {
    key: "（*单张图片不能超过10MB，最多上传4个图片）",
    value: "（*1枚あたり最大10MBまで、合計４枚）"
  },
  {
    key: "满意度为1到5星，星级越高越满意",
    value: "満足度は1から5つ星で、星の数が多いほど満足度が高いです"
  },
  {
    key: "服务流程是否满意",
    value: "サービスに満足しましたか"
  },
  {
    key: "商品质量是否符合您的预期",
    value: "商品の品質は予想通りでしたか"
  },
  {
    key: "商品是否完整（有无缺失、破损）",
    value: "商品は完全でしたか（欠損や破損はありましたか）"
  },
  {
    key: "整体服务满意度",
    value: "全体的なサービス満足度"
  },
  {
    key: "服务人员是否能精准到位满足您的需求",
    value: "担当者はあなたの要求に正確かつ満足できるように対応しましたか"
  },
  {
    key: "服务人员是否态度良好",
    value: "担当者の態度は良好でしたか"
  },
  {
    key: "服务人员是否处理问题及时",
    value: "担当者は問題を適切に処理しましたか"
  },
  {
    key: "物流满意度",
    value: "物流の満足度"
  },
  {
    key: "对此物流速度是否满意",
    value: "物流のスピードに満足しましたか"
  },
  {
    key: "物流费用是否满意",
    value: "物流費用に満足しましたか"
  },
  {
    key: "物流服务（通关、配送等）",
    value: "物流サービス（通関、配送など）"
  },
  {
    key: "ODM/OEM服务",
    value: "ODM/OEMサービス"
  },
  {
    key: "客户准备一份初稿，我们委托工厂按照设计生产或者对现有产品进行个性化定制，添加客户想要的功能或特性，",
    value: "お客様が初期案を準備し、我々が工場に依頼してデザインに基づいて製造するか、"
  },
  {
    key: "以便在自有品牌下销售。",
    value: "既存の製品をカスタマイズしてお客様の要望に合わせた機能や特性を追加します。"
  },
  {
    key: "这个过程从工厂购买材料开始，所以要满足一定的产量",
    value: "独自のブランドで販売注文するために必要な最低ロットが必要になります。"
  },
  {
    key: "我们将与生产厂家联系，了解生产数量，单价，交货期，并拿到样品与您确认",
    value: "生産工場と連携し、生産数量、単価、納期などの詳細を確認し、サンプルをお客様にご確認いただきます。"
  },
  {
    key: "服务评价",
    value: "サービス評価"
  },
  {
    key: "已被使用",
    value: "ユーザー名が既に利用されています"
  },
  {
    key: "关键词",
    value: "キーワード"
  },
  {
    key: "没有找到商品，请换一个关键词试试",
    value: "と関係する商品が見つかりませんでした。再度別のキーワードで検索または担当者までご連絡ください。"
  },
  {
    key: "图片上传中",
    value: "写真のアップロード中"
  },
  {
    key: "图片上传",
    value: "画像添付"
  },
  {
    key: "请输入您想查找的商品名称或者淘宝与1688的商品URL",
    value: "キーワードまたは1688、タオバオの商品URL"
  },
  {
    key: "搜索",
    value: "検索"
  },
  {
    key: "标签名称",
    value: "タグ"
  },
  {
    key: "提交评价",
    value: "評価を送信する"
  },
  {
    key: "店铺名称",
    value: "店舗名"
  },
  {
    key: "请输入店铺名称",
    value: "店舗名をご記入ください"
  },
  {
    key: "取消收藏",
    value: "お気に入り外す"
  },
  {
    key: "新增标签",
    value: "新規追加"
  },
  {
    key: "请输入标签名",
    value: "タグ名をご記入ください"
  },
  {
    key: "option说明界面",
    value: "オプションの説明画面"
  },
  {
    key: "上传的图片大小不能超过 10MB!",
    value: "アップロードされた画像のサイズは10MBを超えることはできません！"
  },
  {
    key: "图片格式不正确！",
    value: "画像の形式が正しくありません！"
  },
  {
    key: "请填写完评价内容再进行提交",
    value: "評価内容を入力してから送信してください"
  },
  {
    key: "确认开通",
    value: "開通を確認する"
  },
  {
    key: "天的有料会員？",
    value: "天的有料会員？"
  },
  {
    key: "件",
    value: "件"
  },
  {
    key: "合计金额",
    value: "合計金額"
  },
  {
    key: "不能全由空格组成",
    value: "不能全由空格组成"
  },
  {
    key: "附加服务",
    value: "オプションサービス"
  },
  {
    key: "国际物流说明",
    value: "国際送料"
  },
  {
    key: "手续费说明",
    value: "手数料について"
  },
  {
    key: "国际运费说明",
    value: "国際について"
  },
  {
    key: "全部商品",
    value: "全商品"
  },
  {
    key: "首页",
    value: "マイページ"
  },
  {
    key: "在库数",
    value: "在庫数"
  },
  {
    key: "国内送料",
    value: "中国内送料"
  },
  {
    key: "スタッフ",
    value: "スタッフ"
  },
  {
    key: "备选链接",
    value: "予備URL"
  },
  {
    key: "注文書修正",
    value: "注文書修正"
  },
  {
    key: "支払い",
    value: "支払い"
  },
  {
    key: "购物车添加成功",
    value: "カートに追加されました"
  },
  {
    key: "收藏商品",
    value: "お気に入り"
  },
  {
    key: "原url确认",
    value: "元url確認"
  },
  {
    key: "移出收藏夹",
    value: "お気に入りから削除する"
  },
  {
    key: "删除商品",
    value: "削除"
  },
  {
    key: "商品详情",
    value: "商品詳細"
  },
  {
    key: "属性",
    value: "詳細"
  },
  {
    key: "注文",
    value: "注文"
  },
  {
    key: "数量",
    value: "数量"
  },
  {
    key: "小計(元)",
    value: "小計(元)"
  },
  {
    key: "人民元対円換算",
    value: "人民元対円換算"
  },
  {
    key: "汇率从“三菱UFJ TTS レート”获取",
    value: "三菱UFJ銀行 TTS レート+0.7"
  },
  {
    key: "元",
    value: "元"
  },
  {
    key: "予想費用",
    value: "予想費用"
  },
  {
    key: "总价",
    value: "総額"
  },
  {
    key: "该商品将移出购物车",
    value: "この商品はカートから削除されます"
  },
  {
    key: "确认",
    value: "確認"
  },
  {
    key: "提醒",
    value: "注意"
  },
  {
    key: "是否将选中的商品移除购物车",
    value: "選択した商品をカートから削除しますか"
  },
  {
    key: "未选择操作的商品",
    value: "操作されていない商品が選択されていません"
  },
  {
    key: "去订货",
    value: "注文へ"
  },
  {
    key: "更改头像",
    value: "画像変更"
  },
  {
    key: "头像上传中",
    value: "プロフィール画像のアップロード中"
  },
  {
    key: "张",
    value: "枚"
  },
  {
    key: "手续费",
    value: "手数料"
  },
  {
    key: "无手续费",
    value: "手数料なし"
  },
  {
    key: "商品调查",
    value: "商品調査"
  },
  {
    key: "免费",
    value: "無料"
  },
  {
    key: "无",
    value: "无"
  },
  {
    key: "商品调查 免费",
    value: "商品調査 無料"
  },
  {
    key: "普通会員",
    value: "一般会員"
  },
  {
    key: "订单为您优先处理",
    value: "特殊な状況優先処理"
  },
  {
    key: "有料会員",
    value: "有料会員"
  },
  {
    key: "会員",
    value: "会員"
  },
  {
    key: "代行商品金額合計（万円）",
    value: "代行商品金額合計（万円）"
  },
  {
    key: "公平的佣金，巨大的价值",
    value: "透明な手数料で輸入サポート"
  },
  {
    key: "用很少的钱利用Rakumart提供的所有服务，更好的帮助您进口",
    value: "Rakumartが提供するサービスをご利用いただく上で、シンプルな手数料でお客様の輸入ビジネスをサポート致します。"
  },
  {
    key: "如果在使用本网站的过程中有不方便的地方或者想要改善的地方，请填写在下方文本框里，有图片也可进行上传，我们会尽可能的改善。",
    value: "当サイトの使い勝手がわるいところや改善したい所がございましたら、下記の枠にご記入頂ければ、改善可能の限り対応させていただきます。"
  },
  {
    key: "改善意见",
    value: "改善要望"
  },
  {
    key: "请多多关照。",
    value: "宜しくお願い致します。"
  },
  {
    key: "紹介へ",
    value: "紹介へ"
  },
  {
    key: "图片翻译",
    value: "画像翻訳"
  },
  {
    key: "商品详情介绍图片翻译",
    value: "商品詳細の画像翻訳"
  },
  {
    key: "特殊情况优先处理",
    value: "特殊な状況優先処理"
  },
  {
    key: "您的订单特殊情况下为您优先处理",
    value: "お客様の注文は特別な状況下で優先的に処理されます"
  },
  {
    key: "普通会员",
    value: "一般会員"
  },
  {
    key: "订单",
    value: "注文書"
  },
  {
    key: "临时保存的订单",
    value: "一時保存（未提出）"
  },
  {
    key: "立即申请",
    value: "今すぐ申し込む"
  },
  {
    key: "推荐给每月订购50万日元以上商品的顾客",
    value: "月に50万円以上の商品を注文されるお客様におすすめです"
  },
  {
    key: "关于规则事项",
    value: "ルールについて"
  },
  {
    key: "运输中",
    value: "配送中"
  },
  {
    key: "此店铺暂无商品",
    value: "お勧め商品ありません"
  },
  {
    key: "收藏店铺",
    value: "お気に入り店舗"
  },
  {
    key: "不是我想要查找的分类",
    value: "検索結果に問題あり？"
  },
  {
    key: "是否删除该订单？",
    value: "こちらの注文書を削除されますか？"
  },
  {
    key: "验证手机号",
    value: "携帯番号認証"
  },
  {
    key: "目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%",
    value: "現在、お客様のランクに関係なく、会費の支払いと確定後、以降注文の手数料はすべて0％です"
  },
  {
    key: "申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了",
    value: "申し込みが承認された日から日数をカウントします。定額会員の期間は月単位ではなく、日数単位で計算されます。たとえば、1月は31日ありますが、1月1日からの有効期間は30日であり、31日は適用されません"
  },
  {
    key: "确定为收费会员后，关于未支付的订单，手续费将自动变为0%",
    value: "定額会員に確定した場合、未払いの注文に対して手数料は自動的に0％になります"
  },
  {
    key: "期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)",
    value: "中国の法定休日などにより連続して5日以上休業する場合、該当する日数が自動的に追加されます。（例：国慶節、春節など）"
  },
  {
    key: "收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理",
    value: "定額会員の有効期限が5日前になると、システムから自動的に通知メールが送信されますが、何らかの理由で遅延する場合もあります。配信できない場合もあるため、有効期限については自己管理をお願いします"
  },
  {
    key: "请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案",
    value: "定額会員期間中の変更や返金は基本受け付けられません。事前に手数料（契約金額）を支払っているため、優待のサービスを提供しています。万が一、取引中に何か問題が発生した場合で弊社が原因の場合、定額会員料金の返還以外の補償策を提案させていただきます"
  },
  {
    key: "节省",
    value: "節約"
  },
  {
    key: "使用记录",
    value: "使用履歴"
  },
  {
    key: "请选择",
    value: "選択してください"
  },
  {
    key: "券编码",
    value: "クーポンコード"
  },
  {
    key: "中国国内物流状态",
    value: "中国国内物流状態"
  },
  {
    key: "采购完成",
    value: "購入完了"
  },
  {
    key: "售后",
    value: "アフターサービス"
  },
  {
    key: "资金管理",
    value: "入出金管理"
  },
  {
    key: "账单",
    value: "入出金履歴"
  },
  {
    key: "收藏",
    value: "お気に入り"
  },
  {
    key: "協力先",
    value: "協力先"
  },
  {
    key: "点",
    value: "円"
  },
  {
    key: "等待付款的订单",
    value: "入金待ち"
  },
  {
    key: "入金",
    value: "入金処理"
  },
  {
    key: "需要支付金额",
    value: "支払額"
  },
  {
    key: "立即付款",
    value: "支払いへ"
  },
  {
    key: "Excel导入",
    value: "Excelインポート"
  },
  {
    key: "Excel导出",
    value: "Excelエクスポート"
  },
  {
    key: "等待处理的问题产品",
    value: "処理待ち問題商品"
  },
  {
    key: "等待处理",
    value: "処理中"
  },
  {
    key: "请先下载Excel模板表格，再上传文件",
    value: "Excelのテンプレートをダウンロードして、ファイルをアップロードしてください"
  },
  {
    key: "前往处理",
    value: "処理へ"
  },
  {
    key: "请选择上传文件",
    value: "アップロードするファイルを選択してください"
  },
  {
    key: "文件解析成功后，商品将直接在商品库里展示，重复的商品会按照文件编辑好的内容进行覆盖",
    value: "ファイルが正常に解析されると、商品は直接商品ライブラリに表示されます。重複する商品はファイルの編集内容に従って上書きされます"
  },
  {
    key: "点击上传",
    value: "アップロードをクリック"
  },
  {
    key: "文件上传中",
    value: "ファイルのアップロード中"
  },
  {
    key: "文件大小超出限制",
    value: "ファイルのサイズが制限を超えています"
  },
  {
    key: "下载模板",
    value: "テンプレートをダウンロード"
  },
  {
    key: "查看",
    value: "詳細"
  },
  {
    key: "点击或将EXCEL 文件拖拽到这里上传",
    value: "ここをクリックするか、Excelファイルをここにドラッグしてアップロードしてください"
  },
  {
    key: "等待付款的国际运费",
    value: "国際送料支払い待ち"
  },
  {
    key: "文件格式仅限Xls和Xlsx",
    value: "ファイル形式はXlsおよびXlsxのみ対応しています"
  },
  {
    key: "日本语",
    value: "日本語"
  },
  {
    key: "授权许可",
    value: "権限許可"
  },
  {
    key: "普通流程",
    value: "通常の流れ"
  },
  {
    key: "商品到货检品",
    value: "商品の到着と検品"
  },
  {
    key: "上架仓库",
    value: "倉庫への入庫"
  },
  {
    key: "商品从仓库取出",
    value: "商品倉庫からの出庫"
  },
  {
    key: "打包装箱",
    value: "梱包"
  },
  {
    key: "确认商品",
    value: "商品確認"
  },
  {
    key: "授权方法",
    value: "許可方法"
  },
  {
    key: "负责人为您提配送单",
    value: "担当者が配送依頼書を提出"
  },
  {
    key: "配送单报价",
    value: "配送依頼書の見積もり"
  },
  {
    key: "当您授权给我们配送单提出权限后",
    value: "RAKUMARTに配送依頼書の提出権限を許可すると、以下の作業が行われます。"
  },
  {
    key: "当您授权给我们出金权限以及提出配送单权限后",
    value: "RAKUMARTに入金処理および配送依頼書の提出権限を許可すると、以下の作業が行われます。"
  },
  {
    key: "优点1：缩短发货流程，更快到达日本",
    value: "利点1：出荷手続きが短縮され、日本への到着がより早くなります"
  },
  {
    key: "优点2：每天不需要确认商品状态，只要向负责人传达固定的发货规则，就可以随时发货",
    value: "利点2：商品の状態を毎日確認する必要がなく、定められた出荷ルールを担当者に伝えるだけでいつでも出荷できます"
  },
  {
    key: "当您授权后：同意商品配送委托时，可缩短交货期1天以上",
    value: "許可をいただいた場合、商品の配送を承認いただくと、納期を1日以上短縮することが可能です"
  },
  {
    key: "对需要开通的授权内容进行确认",
    value: "許可内容を確認します。"
  },
  {
    key: "点击授权按钮，点击“确认”授权。",
    value: "許可ボタンをクリックし、\"確認\"をクリックして許可します。"
  },
  {
    key: "常用收件地址与进口商信息确认好",
    value: "よく使用する受取り住所と輸入者の情報を確認します。"
  },
  {
    key: "与负责人联系",
    value: "担当者に連絡します。"
  },
  {
    key: "授权完成",
    value: "許可完了"
  },
  {
    key: "授权RAKUMART公司提交配送委托",
    value: "RAKUMART社への配送委託の提出を許可します"
  },
  {
    key: "负责人根据订单及配送委托书的余额给予扣款收款权限，余额不足时请再次汇款",
    value: "注文と配送委託書に基づいて、担当者は請求と受け取りの権限を与えられます。残高が不足している場合は、再度入金してください"
  },
  {
    key: "当前状态",
    value: "現在の状態"
  },
  {
    key: "取消配送委托权限",
    value: "配送依頼書の権限を取り消します"
  },
  {
    key: "权限许可后，已经进行了包装作业，如果想被取消的话，请尽快联系负责人。",
    value: "権限を許可した後、商品の梱包作業が行われている場合、速やかに担当者に連絡してください。"
  },
  {
    key: "余额收款支付权限撤销",
    value: "入金および受け取りの権限を取り消します"
  },
  {
    key: "取消授权",
    value: "許可の取り消し"
  },
  {
    key: "授权取消",
    value: "許可の取消"
  },
  {
    key: "已开通授权",
    value: "許可済み"
  },
  {
    key: "授权",
    value: "許可"
  },
  {
    key: "可随时取消。",
    value: "いつでも取り消すことができます。"
  },
  {
    key: "用户协议",
    value: "利用規約"
  },
  {
    key: "未授权",
    value: "許可されません"
  },
  {
    key: "订单入金，配送单入金的授权设定已经完成，衷心感谢您的信赖。",
    value: "注文の注文及び配送依頼書の入金の権限設定が完了しました。ご信頼頂きありがとうございました。"
  },
  {
    key: "订单入金，配送单入金的授权设定已经取消，衷心感谢您的信赖。",
    value: "注文の入金及び配送依頼書の入金の権限設定が取り消されました。ご利用頂きありがとうございました。"
  },
  {
    key: "常用地址设定未完成，请同时设定常用进口商地址和常用收件址。",
    value: "よく使用する住所の設定が完了していません。よく使用する輸入者の住所と受け取り住所を同時に設定してください。"
  },
  {
    key: "配送单提出权限设定已经完成，授权许可后，已经在进行打包工作，如果想要被取消的话请务必尽早与负责人联系。",
    value: "配送依頼書の提出権限は設定が完了しており、許可を許可した後、商品の梱包作業が行われています。取り消したい場合は、できるだけ早く担当者に連絡してください。"
  },
  {
    key: "许可后，关于已经反映到仓库的商品，只有负责人可以操作，如果想要变动的话，请向负责人确认后进行调整",
    value: "許可された後、倉庫に反映されている商品に関しては、担当者のみが操作できます。変更が必要な場合は、担当者に確認して調整してください"
  },
  {
    key: "亚马逊交货的商品可以随时提交配送委托，也可以传达给负责人进行操作",
    value: "Amazon配送の場合は、いつでも配送委託を提出したり、担当者に伝えたりすることができます"
  },
  {
    key: "常用地址",
    value: "よく使用する住所"
  },
  {
    key: "常用进口商地址和常用收件地址相同也可以",
    value: "よく使用する輸入者の住所と受け取り住所は同じでも構いません"
  },
  {
    key: "只支持上传格式为png，jpg，jpeg，pneg文件，最大可上传三张",
    value: "対応できるファイル形式：png，jpg，jpeg，pneg最大３枚まで"
  },
  {
    key: "设定为常用收件地址",
    value: "よく使用する受け取り住所に設定する"
  },
  {
    key: "设定为常用进口商",
    value: "よく使用する輸入者に設定する"
  },
  {
    key: "已设为常用",
    value: "よく使用する住所に設定済み"
  },
  {
    key: "请输入关键词搜索",
    value: "キーワードをご入力してください"
  },
  {
    key: "通知公告",
    value: "お知らせ"
  },
  {
    key: "授权内容",
    value: "許可内容"
  },
  {
    key: "写真上传中",
    value: "写真上传中"
  },
  {
    key: "出入金详细",
    value: "入出金履歴詳細"
  },
  {
    key: "订单号/派送单号/内容",
    value: "注文番号/配送番号/内容"
  },
  {
    key: "订单管理",
    value: "注文管理"
  },
  {
    key: "请输入咨询内容",
    value: "お問い合わせ内容を入力してください"
  },
  {
    key: "全部优惠券",
    value: "すべてのクーポン"
  },
  {
    key: "券类别",
    value: "券種類"
  },
  {
    key: "类别",
    value: "検索サイト"
  },
  {
    key: "额度/可用次数",
    value: "割引額"
  },
  {
    key: "有效日期",
    value: "有効期限"
  },
  {
    key: "使用状态",
    value: "利用状態"
  },
  {
    key: "使用时间",
    value: "利用日"
  },
  {
    key: "已过期",
    value: "期限切れ"
  },
  {
    key: "子账号信息编辑",
    value: "社員アカウント"
  },
  {
    key: "订单号",
    value: "注文番号"
  },
  {
    key: "已选择",
    value: "選択中"
  },
  {
    key: "请输入订单号",
    value: "注文番号"
  },
  {
    key: "问题商品",
    value: "問題商品"
  },
  {
    key: "当列表里没有您需要的服务时，请直接与负责人进行联系",
    value: "内容に応じて個別で対応させていただきます"
  },
  {
    key: "仓库",
    value: "倉庫"
  },
  {
    key: "隐藏",
    value: "隠す"
  },
  {
    key: "展开",
    value: "展開"
  },
  {
    key: "标签设置",
    value: "ラベル編集"
  },
  {
    key: "剩余在库数量",
    value: "在庫残量"
  },
  {
    key: "为您的私人品牌生产定制包装，标签，定制摄影，ODM定制服务，按照您的要求进行处理，定制生产取决于尺寸，颜色和所需的材料类型，您必须提供您品牌的所有元素与信息，以便我们进行预算，报价",
    value: "各商品に対する個別での付属サービス依頼が可能になります、内容に応じて個別で対応させていただきます。"
  },
  {
    key: "配送单",
    value: "配送依頼書"
  },
  {
    key: "报价中配送单",
    value: "見積もり中の配送伝票"
  },
  {
    key: "最低的的手续费，为您降低进口成本",
    value: "最低手数料で、輸入コストを削減します"
  },
  {
    key: "个人信息管理",
    value: "個人情報管理"
  },
  {
    key: "注册信息修改",
    value: "登録情報変更"
  },
  {
    key: "我的收货地址",
    value: "配送先管理"
  },
  {
    key: "子账户",
    value: "社員用アカウント"
  },
  {
    key: "「RAKUMART有料会員制度」",
    value: "「RAKUMART定額会員制度」"
  },
  {
    key: "成为有料会員，享受更多优惠",
    value: "定額会員になって、さらなる特典をお楽しみください"
  },
  {
    key: "亚马逊仓库",
    value: "Amazon倉庫"
  },
  {
    key: "雅虎仓库",
    value: "Yahoo倉庫"
  },
  {
    key: "EMS中邮海外仓",
    value: "EMS国際倉庫"
  },
  {
    key: "乐天仓库",
    value: "楽天倉庫"
  },
  {
    key: "配送先管理",
    value: "配送先管理"
  },
  {
    key: "更改密码",
    value: "パスワードを変更する"
  },
  {
    key: "预购订单",
    value: "予約注文"
  },
  {
    key: "全部订单",
    value: "すべての注文書"
  },
  {
    key: "报价中",
    value: "見積中"
  },
  {
    key: "等待付款",
    value: "支払い待ち"
  },
  {
    key: "采购完了",
    value: "買付完了"
  },
  {
    key: "正在购买",
    value: "買付中"
  },
  {
    key: "密码变更",
    value: "パスワードの変更"
  },
  {
    key: "手机号变更",
    value: "携帯電話番号の変更"
  },
  {
    key: "邮箱变更",
    value: "メールアドレスの変更"
  },
  {
    key: "身份认证",
    value: "身分確認"
  },
  {
    key: "取消",
    value: "キャンセル"
  },
  {
    key: "欢迎来到",
    value: "ようこそ"
  },
  {
    key: "确认删除选中的商品吗？",
    value: "選択した商品を削除しますか？"
  },
  {
    key: "确认删除该商品吗？",
    value: "この商品を削除しますか？"
  },
  {
    key: "长度在 1 到 64 个字符",
    value: "半角1文字以上64文字以内に収めてください"
  },
  {
    key: "长度在 1 到 200 个字符",
    value: "半角1文字以上200文字以内に収めてください"
  },
  {
    key: "残金支払い",
    value: "残金支払い"
  },
  {
    key: "銀行入金",
    value: "銀行入金"
  },
  {
    key: "残高",
    value: "残高"
  },
  {
    key: "残り",
    value: "残り"
  },
  {
    key: "支払い額",
    value: "支払い額"
  },
  {
    key: "見積金額",
    value: "見積金額"
  },
  {
    key: "注文番号",
    value: "注文番号"
  },
  {
    key: "最低入金金額",
    value: "最低入金金額"
  },
  {
    key: "*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为在报价完成时被修正，请直接提交报价。",
    value: "*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、そのままお見積りをご提出下さいませ。"
  },
  {
    key: "开始日期",
    value: "開始日"
  },
  {
    key: "至",
    value: "～"
  },
  {
    key: "结束日期",
    value: "終了日"
  },
  {
    key: "交易方式",
    value: "取引方法"
  },
  {
    key: "出金额",
    value: "出金額"
  },
  {
    key: "确认入账时间",
    value: "入金確認日時"
  },
  {
    key: "入金额",
    value: "入金額"
  },
  {
    key: "残金",
    value: "残金"
  },
  {
    key: "提出时间",
    value: "提出時間"
  },
  {
    key: "注文書・配送書",
    value: "注文書・配送書"
  },
  {
    key: "注文書",
    value: "注文書"
  },
  {
    key: "配送書",
    value: "配送書"
  },
  {
    key: "内容",
    value: "内容"
  },
  {
    key: "账单类型",
    value: "账单类型"
  },
  {
    key: "搜 索",
    value: "索狩り"
  },
  {
    key: "请先选择要导出的时间范围",
    value: "出力される時間範囲を指定してください"
  },
  {
    key: "收费说明",
    value: "詳細"
  },
  {
    key: "RAKUMART在交易的过程中会向客户收取手续费。",
    value: "RAKUMARTは代行買付サービスに手数料をお客様にご請求しています。"
  },
  {
    key: "手续费的收取标准是根据订单金额百分比进行计算，百分比大小与客户的会员等级有关。",
    value: "手数料は注文金額の割合に基づいて計算されますが、割合の大きさはお客様の会員レベルによって異なります。"
  },
  {
    key: "请注意手续费只是基本工作的费用。",
    value: "手数料は基本的な業務費用であることにご注意ください。"
  },
  {
    key: "对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。",
    value: "下記の料金表に掲載されていない操作やサービスについても、柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。"
  },
  {
    key: "月采购额",
    value: "月買付額"
  },
  {
    key: "待打款",
    value: "振込待ち"
  },
  {
    key: "已拒绝",
    value: "拒否"
  },
  {
    key: "已打款",
    value: "送金済み"
  },
  {
    key: "检品中",
    value: "検品中"
  },
  {
    key: "订单状态",
    value: "注文書状態"
  },
  {
    key: "操作",
    value: "操作"
  },
  {
    key: "提出日期",
    value: "提出日"
  },
  {
    key: "发货日期",
    value: "出荷日"
  },
  {
    key: "包含订单",
    value: "関係注文書"
  },
  {
    key: "报价中订单",
    value: "見積中"
  },
  {
    key: "订单待付款",
    value: "支払い待ち"
  },
  {
    key: "购买完成日期",
    value: "買付完了日"
  },
  {
    key: "购买完成",
    value: "買付完了"
  },
  {
    key: "商品数",
    value: "商品数"
  },
  {
    key: "暂无数据",
    value: "データがありません"
  },
  {
    key: "处理意见",
    value: "処理"
  },
  {
    key: "单价（元）",
    value: "単価（元）"
  },
  {
    key: "全部",
    value: "すべて"
  },
  {
    key: "等待发货",
    value: "出荷待ち"
  },
  {
    key: "添加至收藏夹",
    value: "お気に入りに追加"
  },
  {
    key: "可用次数",
    value: "利用可能な回数"
  },
  {
    key: "支付完成",
    value: "支払いが完了しました"
  },
  {
    key: "我们为您管理一切国际物流",
    value: "国際送料詳細"
  },
  {
    key: "Rakumart为您管理和执行从中国进口的整个物流流程。",
    value: "私たちは、中国からの輸入物流の管理と実行を担当し"
  },
  {
    key: "利用Rakumart提供的所有资源，彻底改变您从中国购买和进口的方式，为您的业务节省时间和成本。",
    value: "Rakumartを利用して中国からの商品の購入、輸入の方法を根本的に変え、ビジネスに時間短縮とコストダンする様々な配送方法を提供させていただきます。"
  },
  {
    key: "成本",
    value: "コスト"
  },
  {
    key: "物流纳期一览",
    value: "一覧"
  },
  {
    key: "物流名称",
    value: "物流会社"
  },
  {
    key: "国际物流运费预估",
    value: "見積ツール"
  },
  {
    key: "国际物流简易比较",
    value: "国際送料　簡易比較"
  },
  {
    key: "物流收费说明",
    value: "詳細"
  },
  {
    key: "注意事項",
    value: "注意事項"
  },
  {
    key: "根据会员级别不同",
    value: "お客様の販売状況に応じ多種な対応サポート"
  },
  {
    key: "请输入处理意见",
    value: "処理意見を入力してください"
  },
  {
    key: "快捷处理",
    value: "クイック処理"
  },
  {
    key: "仅当前账号",
    value: "現在のアカウントのみ"
  },
  {
    key: "其他费用明细",
    value: "その他費用明細"
  },
  {
    key: "普通仓库",
    value: "倉庫"
  },
  {
    key: "收费仓库",
    value: "課金倉庫"
  },
  {
    key: "最终订单金额",
    value: "最終金額"
  },
  {
    key: "名义",
    value: "内容"
  },
  {
    key: "调整金额",
    value: "調整額"
  },
  {
    key: "返金额（円）",
    value: "返金額（円）"
  },
  {
    key: "追加额（円）",
    value: "追加費用（円）"
  },
  {
    key: "备选链接选择：",
    value: "备选链接选择："
  },
  {
    key: "点击展开显示明细",
    value: "クリックして明細展開"
  },
  {
    key: "点击收起明细",
    value: "クリックして閉じる"
  },
  {
    key: "费用（元）",
    value: "費用（元）"
  },
  {
    key: "注文書",
    value: "注文書"
  },
  {
    key: "订单汇率",
    value: "レート"
  },
  {
    key: "类目",
    value: "分類"
  },
  {
    key: "金额（元）",
    value: "金额（元）"
  },
  {
    key: "金额（円）",
    value: "金额（円）"
  },
  {
    key: "当前链接：",
    value: "当前链接："
  },
  {
    key: "小计",
    value: "小計"
  },
  {
    key: "国内运费",
    value: "国内運賃"
  },
  {
    key: "备注栏",
    value: "備考欄"
  },
  {
    key: "已选商品",
    value: "選択中商品"
  },
  {
    key: "状态",
    value: "状態"
  },
  {
    key: "店铺名",
    value: "店舗名"
  },
  {
    key: "子账户信息编辑",
    value: "子账户信息编辑"
  },
  {
    key: "备注",
    value: "備考欄"
  },
  {
    key: "使用中",
    value: "利用中"
  },
  {
    key: "禁用",
    value: "停止中"
  },
  {
    key: "配送单列表",
    value: "配送依頼書"
  },
  {
    key: "中止",
    value: "停止中"
  },
  {
    key: "* 请输入6到16位的密码，可由字母数字及@符号组成，不支持空格。",
    value: "* パスワードは6から16桁の英数字と@記号で構成される必要があります,\n" +
     "スペースは使用できません。"
  },
  {
    key: "回到首页",
    value: "先頭へ"
  },
  {
    key: "售后出金",
    value: "アフター出金"
  },
  {
    key: "售后入金",
    value: "アフター入金"
  },
  {
    key: "未匹配到配送单详情",
    value: "配送の詳細が一致しませんでした"
  },
  {
    key: "未匹配到相关订单",
    value: "データ取得エラー、ページを閉じて、再度操作をお願いします。\n" +
     "それでも操作できない場合は担当者までご連絡ください。"
  },
  {
    key: "配送单回退追收",
    value: "配送伝票の返金追加請求"
  },
  {
    key: "配送单回退入金",
    value: "配送伝票の返金入金"
  },
  {
    key: "开通付费会员出金",
    value: "有料会員の開通出金"
  },
  {
    key: "装箱中",
    value: "梱包中"
  },
  {
    key: "发货中",
    value: "配送手配中"
  },
  {
    key: "提出配送单",
    value: "提出"
  },
  {
    key: "装箱数",
    value: "梱包数"
  },
  {
    key: "临时保存",
    value: "一時保存"
  },
  {
    key: "箱规（cm）",
    value: "箱の仕様（cm）"
  },
  {
    key: "实际重量（kg）",
    value: "実際の重量（kg）"
  },
  {
    key: "配送单单番备注",
    value: "配送伝票の番号備考"
  },
  {
    key: "追加到配送单",
    value: "配送依頼書へ追加"
  },
  {
    key: "上架中",
    value: "入庫待ち"
  },
  {
    key: "上架完成",
    value: "倉庫"
  },
  {
    key: "已签收",
    value: "RAKUMART倉庫到着"
  },
  {
    key: "配送单号",
    value: "配送依頼書"
  },
  {
    key: "请输入配送单号搜索",
    value: "配送番号記入して検索"
  },
  {
    key: "商品照片",
    value: "画像"
  },
  {
    key: "所在配送单箱号",
    value: "所在箱No"
  },
  {
    key: "箱数",
    value: "箱数"
  },
  {
    key: "订单支付入金",
    value: "注文の支払い入金"
  },
  {
    key: "订单支付出金",
    value: "注文の支払い出金"
  },
  {
    key: "配送单支付入金",
    value: "配送伝票の支払い入金"
  },
  {
    key: "配送单支付出金",
    value: "配送伝票の支払い出金"
  },
  {
    key: "人工调整",
    value: "手動調整"
  },
  {
    key: "商品调整出金",
    value: "商品調整出金"
  },
  {
    key: "商品调整入金",
    value: "商品調整入金"
  },
  {
    key: "客户提现",
    value: "顧客の引き出し"
  },
  {
    key: "开通付费会员出金",
    value: "有料会員の開通出金"
  },
  {
    key: "配送单回退入金",
    value: "配送依頼書キャンセル入金"
  },
  {
    key: "充值",
    value: "チャージ"
  },
  {
    key: "公司名",
    value: "会社名"
  },
  {
    key: "住所",
    value: "住所"
  },
  {
    key: "公司地址",
    value: "公司地址"
  },
  {
    key: "公司营业执照",
    value: "公司营业执照"
  },
  {
    key: "银行账户",
    value: "口座番号"
  },
  {
    key: "銀行名",
    value: "銀行名"
  },
  {
    key: "出金金額",
    value: "出金金額"
  },
  {
    key: "汇款金额",
    value: "送金額"
  },
  {
    key: "汇款截图",
    value: "送金のスクリーンショット"
  },
  {
    key: "当前账户余额",
    value: "アカウント残高"
  },
  {
    key: "户名",
    value: "户名"
  },
  {
    key: "请输入户名",
    value: "口座名義をご記入ください"
  },
  {
    key: "提现申请原因",
    value: "出金申請原因"
  },
  {
    key: "口座名義",
    value: "口座名義"
  },
  {
    key: "原",
    value: "原"
  },
  {
    key: "确认提交",
    value: "提出"
  },
  {
    key: "余额提现明细",
    value: "残高出金詳細"
  },
  {
    key: "提现申请发起失败，余额不足，无法发起提现",
    value: "出金依頼が失敗しました。残高が不足しているため、出金依頼を行うことができません"
  },
  {
    key: "提现申请发起成功",
    value: "出金依頼が正常に発行されました"
  },
  {
    key: "最晚1-2个工作日资金自动打入账户",
    value: "最長1〜2営業日以内に資金が自動的にアカウントに振り込まれます"
  },
  {
    key: "请输入银行账户",
    value: "口座番号をご記入ください"
  },
  {
    key: "请输入提现金额",
    value: "金額をご記入ください"
  },
  {
    key: "请您核对以下信息是否正确!",
    value: "以下の情報が正しいかどうかご確認ください!"
  },
  {
    key: "以下单子在同一个包裹进行发货",
    value: "以下配送書の商品は同梱発送されます。"
  },
  {
    key: "支付方式",
    value: "支払い方法"
  },
  {
    key: "明细",
    value: "詳細"
  },
  {
    key: "オプション详情",
    value: "オプション详情"
  },
  {
    key: "账户余额",
    value: "账户余额"
  },
  {
    key: "提现记录",
    value: "提现记录"
  },
  {
    key: "提现",
    value: "残高出金"
  },
  {
    key: "发起时间",
    value: "提出時間"
  },
  {
    key: "流水号",
    value: "番号"
  },
  {
    key: "收款户名",
    value: "口座名義"
  },
  {
    key: "收款户号",
    value: "口座番号"
  },
  {
    key: "请输入口座番号",
    value: "请输入口座番号"
  },
  {
    key: "请输入你的问题或者关键字",
    value: "質問内容または関連キーワード"
  },
  {
    key: "帮助中心",
    value: "よくある質問"
  },
  {
    key: "option公司费用介绍",
    value: "option公司费用介绍"
  },
  {
    key: "仓库保管说明",
    value: "倉庫保管費用"
  },
  {
    key: "提现金额",
    value: "出金額"
  },
  {
    key: "定额会员",
    value: "定額会員"
  },
  {
    key: "普检精检详细说明",
    value: "検品について"
  },
  {
    key: "银行名",
    value: "銀行名"
  },
  {
    key: "配送单整体评价",
    value: "今回の仕入れについての総合評価"
  },
  {
    key: "商品包装是否满意",
    value: "商品個別の梱包"
  },
  {
    key: "預金種目",
    value: "預金種目"
  },
  {
    key: "商品整体满意度",
    value: "商品満足度"
  },
  {
    key: "支店番号",
    value: "支店番号"
  },
  {
    key: "支店名",
    value: "支店名"
  },
  {
    key: "处理状态",
    value: "処理状態"
  },
  {
    key: "查看凭证",
    value: "詳細凭证"
  },
  {
    key: "下载",
    value: "下载"
  },
  {
    key: "条",
    value: "件"
  },
  {
    key: "商品金額.元",
    value: "商品金額"
  },
  {
    key: "国内送料.元",
    value: "中国内送料.元"
  },
  {
    key: "オプション手数料.元",
    value: "オプション手数料"
  },
  {
    key: "商品码",
    value: "商品コード"
  },
  {
    key: "标签号",
    value: "ラベル番号"
  },
  {
    key: "请输入订单号搜索",
    value: "注文番号検索"
  },
  {
    key: "请输入关键词",
    value: "キーワードをご記入ください"
  },
  {
    key: "开始时间",
    value: "開始日"
  },
  {
    key: "选择",
    value: "選択"
  },
  {
    key: "照片",
    value: "画像"
  },
  {
    key: "商品链接",
    value: "商品リンク"
  },
  {
    key: "单价",
    value: "単価"
  },
  {
    key: "商品状态",
    value: "商品状態"
  },
  {
    key: "问题写真",
    value: "問題商品写真"
  },
  {
    key: "配送单附件",
    value: "配送依頼書添付ファイル"
  },
  {
    key: "标签信息",
    value: "ラベル情報"
  },
  {
    key: "所在箱子",
    value: "所在箱"
  },
  {
    key: "问题数",
    value: "数量"
  },
  {
    key: "问题详细",
    value: "問題点"
  },
  {
    key: "正常",
    value: "Normal"
  },
  {
    key: "座机",
    value: "電話番号"
  },
  {
    key: "添加",
    value: "添加"
  },
  {
    key: "是否提交座机与注意事项修改",
    value: "固定電話番号と注意事項の変更を送信しますか"
  },
  {
    key: "一時保存しました。",
    value: "一時保存しますか？"
  },
  {
    key: "一時保存",
    value: "一時保存"
  },
  {
    key: "注文を提出",
    value: "注文を提出"
  },
  {
    key: "写真",
    value: "写真"
  },
  {
    key: "商品详细",
    value: "商品詳細"
  },
  {
    key: "调整前数据",
    value: "調整前"
  },
  {
    key: "调整后数据",
    value: "調整後"
  },
  {
    key: "请输入想搜索的URL",
    value: "商品キーワードまたはアリババ、タオバオのURL"
  },
  {
    key: "订购数量",
    value: "発注数"
  },
  {
    key: "单价(元)",
    value: "単価（元）"
  },
  {
    key: "标签",
    value: "タグ"
  },
  {
    key: "备考栏",
    value: "備考欄"
  },
  {
    key: "同店铺热销",
    value: "店舗人気商品"
  },
  {
    key: "前往店铺",
    value: "店舗へ"
  },
  {
    key: "是否取消收藏此店铺？",
    value: "店舗のお気に入りを取り消しますか？"
  },
  {
    key: "添加一行",
    value: "追加"
  },
  {
    key: "历史标签",
    value: "履歴"
  },
  {
    key: "签收日期",
    value: "受領日"
  },
  {
    key: "请输入标签号",
    value: "コード"
  },
  {
    key: "请输入商品码",
    value: "ASIN"
  },
  {
    key: "关于RAKUMART",
    value: "rakumartについて"
  },
  {
    key: "请输入FBA码",
    value: "请输入FBA码"
  },
  {
    key: "商品庫から追加",
    value: "商品庫から追加"
  },
  {
    key: "编辑",
    value: "編集"
  },
  {
    key: "单价.元",
    value: "単価.元"
  },
  {
    key: "小计.元",
    value: "小計.元"
  },
  {
    key: "请输入1688链接或taobao链接或RAKUMART链接",
    value: "1688、taobaoまたはRAKUMARTのURLを入力してください"
  },
  {
    key: "*请务必填写附带*的项目",
    value: "*ついている項目は必ずご記入ください"
  },
  {
    key: "入金日",
    value: "入金日"
  },
  {
    key: "项目",
    value: "項目"
  },
  {
    key: "出入金记录",
    value: "入出金履歴"
  },
  {
    key: "中国国内物流",
    value: "中国国内物流状態"
  },
  {
    key: "待发货",
    value: "配送待ち"
  },
  {
    key: "配送地址管理",
    value: "配送先管理"
  },
  {
    key: "收藏夹",
    value: "お気に入り（商品）"
  },
  {
    key: "视频",
    value: "ビデオ"
  },
  {
    key: "*ついている項目は必ずご記入ください",
    value: "*ついている項目は必ずご記入ください"
  },
  {
    key: "入金額",
    value: "入金額"
  },
  {
    key: "入金名義カタカナ記載",
    value: "入金名義カタカナ記載"
  },
  {
    key: "カタカナ記載",
    value: "カタカナ記載"
  },
  {
    key: "入金名義",
    value: "入金名義"
  },
  {
    key: "着金予定日",
    value: "着金予定日"
  },
  {
    key: "请输入着金予定日",
    value: "请输入着金予定日"
  },
  {
    key: "请输入入金名義",
    value: "请输入入金名義"
  },
  {
    key: "*请填写整数",
    value: "*整数をご記入ください"
  },
  {
    key: "請求料金",
    value: "請求料金"
  },
  {
    key: "提出",
    value: "提出"
  },
  {
    key: "当前订单状态",
    value: "注文書状態"
  },
  {
    key: "金额变更明细",
    value: "金額変更詳細"
  },
  {
    key: "入金时数据",
    value: "見積時"
  },
  {
    key: "入金时金额",
    value: "入金時最終"
  },
  {
    key: "提交成功",
    value: "入金時最終"
  },
  {
    key: "最新数值",
    value: "確定後"
  },
  {
    key: "※ 请勿在汇款报告的备注栏中填写问题。\n因为担当不同所以不能传达。",
    value: "※入金報告の備考欄で質問を入れないでください。\n" +
     "担当が異なりますので伝わりません。"
  },
  {
    key: "国际运费待支付",
    value: "国际运费待支付"
  },
  {
    key: "优惠券",
    value: "クーポン券"
  },
  {
    key: "所有订单",
    value: "すべての注文書"
  },
  {
    key: "进口商",
    value: "輸入者"
  },
  {
    key: "邮编",
    value: "郵便番号"
  },
  {
    key: "新增地址",
    value: "新しい住所を追加する"
  },
  {
    key: "编辑地址",
    value: "住所を編集する"
  },
  {
    key: "选中",
    value: "選択中"
  },
  {
    key: "選択中の商品",
    value: "選択中の商品"
  },
  {
    key: "开始时间不能晚于结束时间",
    value: "開始時間は終了時間よりも遅くすることはできません"
  },
  {
    key: "结束时间不能早于开始时间",
    value: "終了時間は開始時間よりも早くすることはできません"
  },
  {
    key: "支付金额不能大于100000000日元",
    value: "金額は100000000円を超えてはいけない"
  },
  {
    key: "结束时间",
    value: "終了日"
  },
  {
    key: "找回密码",
    value: "パスワード変更"
  },
  {
    key: "操作成功",
    value: "操作が成功しました"
  },
  {
    key: "移除成功",
    value: "削除が成功しました"
  },
  {
    key: "日元",
    value: "日本円"
  },
  {
    key: "出货完了",
    value: "出荷完了"
  },
  {
    key: "检品option费",
    value: "検品オプション料金"
  },
  {
    key: "发送option费",
    value: "配送オプション費用"
  },
  {
    key: "输入备注",
    value: "備考を入力してください"
  },
  {
    key: "请输入商品名称",
    value: "商品名を入力してください"
  },
  {
    key: "新增",
    value: "追加"
  },
  {
    key: "从仓库选择地址",
    value: "倉庫から住所を選択する"
  },
  {
    key: "地址标题",
    value: "住所のタイトル"
  },
  {
    key: "请输入地址标题",
    value: "住所のタイトルを入力してください"
  },
  {
    key: "密码验证",
    value: "パスワードの確認"
  },
  {
    key: "手机号验证",
    value: "携帯電話番号の確認"
  },
  {
    key: "未验证",
    value: "未認証"
  },
  {
    key: "立即验证",
    value: "今すぐ認証へ"
  },
  {
    key: "邮箱验证",
    value: "メールアドレスの確認"
  },
  {
    key: "安全验证",
    value: "セキュリティの検証"
  },
  {
    key: "请输入新密码",
    value: "新しいパスワードを入力してください"
  },
  {
    key: "请再次输入新密码确认",
    value: "新しいパスワードを再入力して確認してください"
  },
  {
    key: "调整后option",
    value: "調整后option"
  },
  {
    key: "调整前option",
    value: "調整前option"
  },
  {
    key: "两次输入密码不一致",
    value: "入力した2つのパスワードが一致しません"
  },
  {
    key: "设置新密码",
    value: "新しいパスワードを設定してください"
  },
  {
    key: "找回成功",
    value: "パスワードの再設定が成功しました"
  },
  {
    key: "账号未注册",
    value: "アカウントが登録されていません"
  },
  {
    key: "请输入您注册时使用的邮箱",
    value: "登録時に使用したメールアドレスを入力してください"
  },
  {
    key: "请输入您注册时使用的手机号",
    value: "登録時の携帯番号を入力してください"
  },
  {
    key: "RAKUMART帮助中心",
    value: "RAKUMART　よくある質問"
  },
  {
    key: "下一步",
    value: "次へ"
  },
  {
    key: "请再次输入密码",
    value: "パスワードを再入力"
  },
  {
    key: "输入密码和前一次输入的不一致",
    value: "パスワードと上の入力が一致しません。再度入力してください。"
  },
  {
    key: "更改成功",
    value: "変更が完了しました"
  },
  {
    key: "临时保存的配送单",
    value: "一時保存（未提出）"
  },
  {
    key: "所有配送单",
    value: "すべての配送書"
  },
  {
    key: "货物装箱中",
    value: "梱包中"
  },
  {
    key: "国际运费待付款",
    value: "支払い待ち"
  },
  {
    key: "国际物流待发货",
    value: "物流業者へ輸送中"
  },
  {
    key: "前往日本途中",
    value: "国際輸送中"
  },
  {
    key: "已经收到货物",
    value: "受け取り"
  },
  {
    key: "已取消",
    value: "キャンセル"
  },
  {
    key: "格式不正确",
    value: "形式が正しくありません"
  },
  {
    key: "在库更新",
    value: "在庫数更新"
  },
  {
    key: "如果与原始商品页面的库存数量不匹配，请点击'在库更新'",
    value: "在庫数1688，taobaoページと違う場合はクリックして更新。"
  },
  {
    key: "原商品页面的库存数为'0'时，请向负责人咨询，并向店铺确认",
    value: "1688，タオバオの実際ページ在庫数0の場合は担当者まで問い合わせください。"
  },
  {
    key: "请输入登录密码",
    value: "ログインパスワードを入力してください"
  },
  {
    key: "邮箱",
    value: "メールアドレス"
  },
  {
    key: "时间",
    value: "日付"
  },
  {
    key: "出入金编号",
    value: "入金報告番号"
  },
  {
    key: "请多关照",
    value: "よろしくお願いします"
  },
  {
    key: "＊您操作入金的金额是等待确认状态。",
    value: "ご記載の金額は確認待ち状態になります"
  },
  {
    key: "取消日期",
    value: "キャンセル日"
  },
  {
    key: "错误报告",
    value: "エラー報告"
  },
  {
    key: "当前搜索结果不是您想查找的商品",
    value: "検索キーワードと違う商品が出てきた"
  },
  {
    key: "搜索不出结果",
    value: "検索結果がない"
  },
  {
    key: "出入金类别",
    value: "出入金種類"
  },
  {
    key: "调整时间",
    value: "調整日付"
  },
  {
    key: "调整具体明细",
    value: "変動明細"
  },
  {
    key: "调整金额（元）",
    value: "調整額（元）"
  },
  {
    key: "入金额（円）",
    value: "入金額（円）"
  },
  {
    key: "出金额（円）",
    value: "出金額（円）"
  },
  {
    key: "全选",
    value: "すべて選択"
  },
  {
    key: "小于最低入金金额",
    value: "最低入金額を超えるように記入及び振込をお願いします。"
  },
  {
    key: "购买中",
    value: "処理待ち"
  },
  {
    key: "说说你对本次服务的感受",
    value: "今回のサービスについての感想を教えてください"
  },
  {
    key: "已到达rakumart仓库",
    value: "到着作業待ち"
  },
  {
    key: "购买数量",
    value: "購入数量"
  },
  {
    key: "商品作业中",
    value: "到着作業中"
  },
  {
    key: "请至少选择一项通知",
    value: "少なくとも1つの通知を選択してください"
  },
  {
    key: "是否确认删除选中的内容。",
    value: "選択した内容を削除してもよろしいですか。"
  },
  {
    key: "是否确认删除选中的商品",
    value: "選択された商品を削除してもよろしいですか?"
  },
  {
    key: "当前选择的通知中，包含有未读的通知，是否确认删除选中的内容。",
    value: "選択した通知には未読の通知が含まれています。選択した内容を削除してもよろしいですか。"
  },
  {
    key: "标签选择",
    value: "タグリスト"
  },
  {
    key: "查找相似",
    value: "類似品を検索"
  },
  {
    key: "问题产品批量回复",
    value: "一括返答"
  },
  {
    key: "一个订单最多添加100个商品",
    value: "1つの注文に最大100個の商品を追加できます"
  },
  {
    key: "一个订单最多添加200个商品",
    value: "1つの注文に最大200個の商品を追加できます"
  },
  {
    key: "两次输入的密码不一致",
    value: "入力されたパスワードが一致しません"
  },
  {
    key: "请输入 6 到 16 位包含大小写字母和数字的密码",
    value: "6から16文字の大文字と小文字、数字を含むパスワードを入力してください"
  },
  {
    key: "已取消删除",
    value: "削除がキャンセルされました"
  },
  {
    key: "确认删除选中的地址吗？",
    value: "選択したアドレスを削除してもよろしいですか？"
  },
  {
    key: "商品配送信息",
    value: "商品の配送情報"
  },
  {
    key: "订单初始付款",
    value: "注文初期支払い"
  },
  {
    key: "有料会員天数有误",
    value: "有料会員天数有误"
  },
  {
    key: "订单中未找到该商品",
    value: "注文内で商品が見つかりませんでした"
  },
  {
    key: "正在获取图片信息",
    value: "画像情報を取得中です"
  },
  {
    key: "代行専用",
    value: "代行専用"
  },
  {
    key: "商品规格",
    value: "商品詳細"
  },
  {
    key: "图片",
    value: "画像"
  },
  {
    key: "聊天",
    value: "チャット"
  },
  {
    key: "详情",
    value: "详细"
  },
  {
    key: "详细",
    value: "詳細"
  },
  {
    key: "问题提出时间",
    value: "提出時間"
  },
  {
    key: "店铺",
    value: "店舗名"
  },
  {
    key: "标签编号",
    value: "ラベル番号"
  },
  {
    key: "附件上传",
    value: "ファイルのアップロード"
  },
  {
    key: "订单附件",
    value: "注文の添付ファイル"
  },
  {
    key: "返回付款",
    value: "支払いに戻る"
  },
  {
    key: "その他",
    value: "その他"
  },
  {
    key: "再提出",
    value: "再提出"
  },
  {
    key: "从购物车中导入更多产品",
    value: "さらに製品をショッピングカートからインポート"
  },
  {
    key: "删除",
    value: "削除"
  },
  {
    key: "历史记录",
    value: "履歴記録"
  },
  {
    key: "其他费用",
    value: "その他"
  },
  {
    key: "合计：",
    value: "合計："
  },
  {
    key: "合计",
    value: "合計"
  },
  {
    key: "抵扣金额",
    value: "控除金額"
  },
  {
    key: "应付",
    value: "支払い予定"
  },
  {
    key: "商品金額",
    value: "商品金額"
  },
  {
    key: "手数料",
    value: "手数料"
  },
  {
    key: "手数料について",
    value: "手数料について"
  },
  {
    key: "オプション手数料",
    value: "オプション手数料"
  },
  {
    key: "合計金額",
    value: "合計金額"
  },
  {
    key: "国际运输公司选择",
    value: "国際配送業者選択"
  },
  {
    key: "*一箱30KG以内、電池、水は含んだ商品は発送できません。",
    value: "*一箱30KG以内、電池、水は含んだ商品は発送できません。"
  },
  {
    key: "当前限制选择 3 个文件",
    value: "現在は 3 個のファイルの選択が制限されています"
  },
  {
    key: "确定移除",
    value: "削除を確定しますか"
  },
  {
    key: "円金券",
    value: "円金券"
  },
  {
    key: "导出装箱明细",
    value: "Excelのダウンロード"
  },
  {
    key: "手数料详情",
    value: "手数料詳細"
  },
  {
    key: "收费比例",
    value: "手数料％"
  },
  {
    key: "您输入的验证码不正确，请确认后重新输入",
    value: "コードが間違っています、再度確認してから入力しなおしてください"
  },
  {
    key: "*单个文件不能超过1GB，一个订单最多上传3个文件。",
    value: "*ファイルサイズは最大1GB，３つまで、それ以上の場合は担当者までご連絡ください。"
  },
  {
    key: "提出数量",
    value: "数量"
  },
  {
    key: "添加附件",
    value: "アップロード"
  },
  {
    key: "附件",
    value: "付属ファイル"
  },
  {
    key: "取 消",
    value: "キャンセル"
  },
  {
    key: "保 存",
    value: "保 存"
  },
  {
    key: "网站属性选择",
    value: "ウェブサイト属性の選択"
  },
  {
    key: "人工编辑属性",
    value: "手動で属性を編集する"
  },
  {
    key: "属性名称",
    value: "属性名"
  },
  {
    key: "具体属性值",
    value: "具体的な属性値"
  },
  {
    key: "添加属性",
    value: "添加属性"
  },
  {
    key: "属性编辑",
    value: "商品詳細"
  },
  {
    key: "注文書備考欄",
    value: "注文書備考欄"
  },
  {
    key: "业务员订单备注回复",
    value: "注文書備考欄に対する返答"
  },
  {
    key: "*对该订单有特殊要求的，可以在这边备注备注后会同步显示给业务员",
    value: "* 注文書に対する備考欄はこちらへご記入ください\n\n" +
     "* 記載後、担当者へ共有されます"
  },
  {
    key: "人民币",
    value: "人民元"
  },
  {
    key: "订单备注栏",
    value: "注文書備考欄"
  },
  {
    key: "确定",
    value: "確定"
  },
  {
    key: "购物车为空",
    value: "ショッピングカートは空です"
  },
  {
    key: "商品库为空",
    value: "商品ライブラリは空です"
  },
  {
    key: "使用优惠券",
    value: "クーポン券利用"
  },
  {
    key: "代金券",
    value: "金券"
  },
  {
    key: "免手续费",
    value: "手数料無料"
  },
  {
    key: "请上传图片",
    value: "画像をアップロードしてください"
  },
  {
    key: "插件使用方法",
    value: "グーグル拡張機能の利用方法"
  },
  {
    key: "优惠券使用方法",
    value: "クーポンの使用方法"
  },
  {
    key: "提出发货方法",
    value: "配送依頼書の提出手順"
  },
  {
    key: "下单方法",
    value: "注文方法"
  },
  {
    key: "商品查找方法",
    value: "商品検索方法"
  },
  {
    key: "注册方法",
    value: "登録方法"
  },
  {
    key: "进口流程",
    value: "輸入手続き"
  },
  {
    key: "隐私政策",
    value: "プライバシーポリシー"
  },
  {
    key: "用户指南",
    value: "初めての方"
  },
  {
    key: "折扣券",
    value: "割引クーポン"
  },
  {
    key: "国际运费",
    value: "国際運賃"
  },
  {
    key: "共",
    value: "共"
  },
  {
    key: "商品种类",
    value: "商品種類"
  },
  {
    key: "问题商品明细",
    value: "問題商品詳細"
  },
  {
    key: "邮编输入错误",
    value: "郵便番号が間違っています"
  },
  {
    key: "邮编格式不正确",
    value: "郵便番号の形式が正しくありません。ご確認ください。"
  },
  {
    key: "发送可能数",
    value: "発送可能数"
  },
  {
    key: "可提出数量",
    value: "出荷可能数"
  },
  {
    key: "装箱数量",
    value: "梱包数"
  },
  {
    key: "已提出数",
    value: "提出済み数量"
  },
  {
    key: "问题产品回复",
    value: "選択中の商品一括返信"
  },
  {
    key: "问题产品",
    value: "問題商品"
  },
  {
    key: "中国国内物流跟进",
    value: "中国国内状態"
  },
  {
    key: "等待购买",
    value: "買付待ち"
  },
  {
    key: "中国国内派送中",
    value: "中国国内配送中"
  },
  {
    key: "国内派送中",
    value: "国内配送中"
  },
  {
    key: "派送中",
    value: "配送中"
  },
  {
    key: "计费数据",
    value: "請求データ"
  },
  {
    key: "收货地址",
    value: "お届け先住所"
  },
  {
    key: "请输入邮编",
    value: "郵便番号を入力してください"
  },
  {
    key: "提货数量不能为零",
    value: "引き取り数量は0にすることはできません"
  },
  {
    key: "提货数量",
    value: "出荷数"
  },
  {
    key: "单番装箱备注",
    value: "箱詰め備考"
  },
  {
    key: "ローマ字",
    value: "ローマ字"
  },
  {
    key: "日期",
    value: "日期"
  },
  {
    key: "不能为空",
    value: "入力が空白です"
  },
  {
    key: "请填写验证码",
    value: "確認コードをご記入ください"
  },
  {
    key: "使用条款和隐私政策还没有被同意。",
    value: "Rakumart利用規約＆プライバシーポリシーを選択してください。"
  },
  {
    key: "返回重新编辑",
    value: "戻る"
  },
  {
    key: "发送方式",
    value: '送り方'
  },
  {
    key: "物流单号",
    value: '国際追跡'
  },
  {
    key: "配送单状态",
    value: '状態'
  },
  {
    key: "配送单待报价",
    value: '見積待ち'
  },
  {
    key: "配送单待付款",
    value: '支払い町'
  },
  {
    key: "待财务确认到账",
    value: '着金確認待ち'
  },
  {
    key: "待出货",
    value: '出荷待ち'
  },
  {
    key: "登录失效",
    value: '現在のログインは無効です、再度ログインしてください。'
  },
  {
    key: "待财务确认",
    value: '入金確認中'
  },
  {
    key: "国际配送中",
    value: '国際配送中'
  },
  {
    key: "箱号",
    value: '箱番号'
  },
  {
    key: "实际重量",
    value: '実際の重量'
  },
  {
    key: "权限范围",
    value: '権限範囲'
  },
  {
    key: "是否前往登录?",
    value: 'ログインページに移動しますか?'
  },
  {
    key: "展开",
    value: '展開'
  },
  {
    key: '该商品因库存不足或其他原因已经找不到，请查看其他商品！',
    value: 'URL無効、こちらの商品は既に在庫切れ、店舗から棚卸しされている状態です。\n' +
     '\n' +
     '1688またはタオバオで問題なく確認できる場合は担当者までご連絡ください。\n' +
     '個別で確認させていただきます。'
  },
  {
    key: '返回首页',
    value: 'トップページへ'
  },
  {
    key: '去登录',
    value: 'ログインへ'
  },
  {
    key: '手机号输入错误',
    value: '携帯電話に誤りがございます'
  },
  {
    key: "提出订单",
    value: "注文を提出する"
  },
  {
    key: "请选择要提出订单的商品",
    value: "注文する商品を選択してください"
  },
  {
    key: "加购商品",
    value: "カートに追加"
  },
  {
    key: "提出订单",
    value: "注文を提出する"
  },
  {
    key: "编辑订单",
    value: "注文を編集する"
  },
  {
    key: "取消订单",
    value: "注文をキャンセルする"
  },
  {
    key: "订单支付",
    value: "注文を支払う"
  },
  {
    key: "问题产品处理",
    value: "問題のある商品を処理する"
  },
  {
    key: "配送单提出",
    value: "配送依頼を提出する"
  },
  {
    key: "取消配送单",
    value: "配送依頼をキャンセルする"
  },
  {
    key: "配送单支付",
    value: "配送依頼を支払う"
  },
  {
    key: "签收配送单",
    value: "配送依頼を受け取る"
  },
  {
    key: "创建/编辑收货地址",
    value: "配送先住所を作成/編集する"
  },
  {
    key: "删除收货地址",
    value: "配送先住所を削除する"
  },
  {
    key: "长度在 2 到 24 个字符",
    value: "半角2文字以上24文字以内に収めてください"
  },
  {
    key: "价格",
    value: "価格"
  },
  {
    key: "起批量",
    value: "最低ロット"
  },
  {
    key: "特殊要求备注",
    value: "特別要求備考"
  },
  {
    key: "价格从高到低",
    value: "価格が高い順に"
  },
  {
    key: "价格从低到高",
    value: "価格が低い順に"
  },
  {
    key: "选项",
    value: "オプション"
  },
  {
    key: "小计(元)",
    value: "小計（元）"
  },
  {
    key: "小計",
    value: "小計"
  },
  {
    key: "合计（元）",
    value: "合計（元）"
  },
  {
    key: "リセット",
    value: "リセット"
  },
  {
    key: "筛选",
    value: "検索"
  },
  {
    key: "保存",
    value: "保存"
  },
  {
    key: "订单手续费",
    value: "買付手数料"
  },
  {
    key: "入RAKUMART仓库费用",
    value: "入庫費用"
  },
  {
    key: "出RAKUMART仓库费用",
    value: "出庫費用"
  },
  {
    key: "简易检品",
    value: "簡易検品"
  },
  {
    key: "精细检品（表面）",
    value: "詳細検品（表）"
  },
  {
    key: "详细检品（表+里）",
    value: "詳細検品（表+裏）"
  },
  {
    key: "梱包代",
    value: "梱包代"
  },
  {
    key: "商品组套操作费用",
    value: "商品セット化作業"
  },
  {
    key: "数量和种类",
    value: "数量と種類"
  },
  {
    key: "注文書/配送書/内容",
    value: "注文書/配送書/内容"
  },
  {
    key: "请输入新密码",
    value: "新しいパスワードを入力してください"
  },
  {
    key: "请再次输入新密码",
    value: "もう一度新しいパスワードを入力してください"
  }, {
    key: "付款",
    value: "支払い"
  },
  {
    key: "体积",
    value: "体積"
  },
  {
    key: "计费重量",
    value: "実際の重量"
  },
  {
    key: "计费体积",
    value: "体積"
  },
  {
    key: "长",
    value: "長"
  },
  {
    key: "宽",
    value: "幅"
  },
  {
    key: "高",
    value: "縦"
  },
  {
    key: "新增箱子",
    value: "箱を追加する"
  },
  {
    key: "已产生费用",
    value: "課金費用"
  },
  {
    key: "计费天数",
    value: "課金日数"
  },
  {
    key: "天",
    value: "日"
  },
  {
    key: "和",
    value: "和"
  },
  {
    key: "的",
    value: "的"
  },
  {
    key: "个产品",
    value: "个产品"
  },
  {
    key: "商品库",
    value: "商品庫"
  },
  {
    key: "管理番号",
    value: "管理番号"
  },
  {
    key: "请输入管理番号",
    value: "管理番号を入力してください"
  },
  {
    key: "接受",
    value: "受け入れ"
  },
  {
    key: "提出番数",
    value: "提出番数"
  },
  {
    key: "不良品换货",
    value: "交換"
  },
  {
    key: "提交",
    value: "提出"
  },
  {
    key: "废弃",
    value: "廃棄"
  },
  {
    key: "退货/取消购买",
    value: "返品/購入キャンセル"
  },
  {
    key: "请输入您的处理意见，如果您已选中多番，选中的各番将给出一样的处理意见。",
    value: "処理意見を入力してください。複数の番号を選択した場合、選択された各番号に同じ処理意見が表示されます。"
  },
  {
    key: "请输入您的邮箱",
    value: "メールアドレスを入力してください"
  },
  {
    key: "标签搜索",
    value: "タグ検索"
  },
  {
    key: "请输入物流单号",
    value: "追跡番号"
  },
  {
    key: "余额",
    value: "残高"
  },
  {
    key: "不可使用",
    value: "出金確認待ち"
  },
  {
    key: "待确认入金金额",
    value: "確認待ち"
  },
  {
    key: "请输入登录名",
    value: "ユーザー名を入力してください"
  },
  {
    key: "临时保存待提出",
    value: "未提出"
  },
  {
    key: "子账户管理",
    value: "サブアカウント管理"
  },
  {
    key: "新規社員",
    value: "新規社員"
  },
  {
    key: "长度不能超出24个字符",
    value: "长度不能超出24个字符"
  },
  {
    key: "长度不能超出99个字符",
    value: "长度不能超出99个字符"
  },
  {
    key: "实重",
    value: '実重量'
  },
  {
    key: "箱规",
    value: '箱の仕様'
  },
  {
    key: "容积重量",
    value: '容積重量'
  },
  {
    key: "物流方式",
    value: '物流方法'
  },
  {
    key: "国际物流单号",
    value: '国際輸送伝票番号'
  },
  {
    key: "番号",
    value: '番号'
  },
  {
    key: '操作失败',
    value: '操作が失敗しました'
  },
  {
    key: "邮箱、手机号或密码输入错误",
    value: 'メールアドレス、電話番号、またはパスワードが間違っています'
  },
  {
    key: "用户名或密码错误",
    value: 'パスワードまたはアカウント名が間違っています。'
  },
  {
    key: '已使用',
    value: '利用中'
  },
  {
    key: '个',
    value: '个'
  },
  {
    key: '暂无订单',
    value: '注文書なし'
  },
  {
    key: '暂无配送单',
    value: '配送リストがありません'
  },
  {
    key: '签收时间',
    value: '受領時間'
  },
  {
    key: '手数料（5%）',
    value: '手数料（5%）'
  },
  {
    key: 'ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください',
    value: 'ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください'
  },
  {
    key: '海关',
    value: '海关'
  },
  {
    key: '可用',
    value: '利用可能'
  },
  {
    key: '订单大于',
    value: '注文金額が以上です'
  },
  {
    key: '有效期',
    value: '有効期限'
  },
  {
    key: '请输入新的手机号',
    value: '新しい携帯電話番号を入力してください'
  },
  {
    key: '请输入新的邮箱',
    value: '新しいメールアドレスを入力してください'
  },
  {
    key: '请输入 6 到 16 位的密码,不支持空格',
    value: 'パスワードは6桁以上16桁以内に収めてください'
  },
  {
    key: '部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解',
    value: '一部の商品はすでに梱包されており、手動で取り出すことはできません。担当者に問い合わせてください。'
  },
  {
    key: '总成本详细',
    value: '詳細'
  },
  {
    key: '总成本',
    value: '総コスト (総額)'
  },
  {
    key: '成本(元)',
    value: 'コスト(元)'
  },
  {
    key: '单个成本',
    value: '単一のコスト'
  },
  {
    key: '长度不超过25个字符',
    value: '25文字以内で入力してください'
  },
  {
    key: '请输入名字罗马字',
    value: '名前（ローマ字）を入力してください'
  },
  {
    key: '请输入公司名',
    value: '会社名を入力してください'
  },
  {
    key: '请输入邮编',
    value: '郵便番号を入力してください'
  },
  {
    key: '请输入携带電話',
    value: '请输入携带電話'
  },
  {
    key: '在库更新中',
    value: '在庫更新中'
  },
  {
    key: '请输入您正常使用的邮箱地址，将用于重要的验证和通知的接收',
    value: '正常に使用するメールアドレスを入力してください。重要な検証と通知の受信に使用されます'
  },
  {
    key: '请输入您正常使用的手机号码，将用于重要的验证和通知的接收',
    value: '正常に使用する携帯電話番号を入力してください。重要な検証と通知の受信に使用されます'
  },
  {
    key: '请输入電話',
    value: '请输入電話'
  },
  {
    key: '添加到收藏夹成功',
    value: 'お気に入りに追加しました'
  },
  {
    key: '已发货',
    value: '国際配送中'
  },
  {
    key: '描述',
    value: '説明'
  },
  {
    key: '账户余额不足',
    value: 'アカウント残高が不足しています'
  },
  {
    key: '是否取消该订单？',
    value: 'キャンセルしますか？'
  },
  {
    key: '是否取消该订单？',
    value: '是否取消该订单？'
  },
  {
    key: '是否取消该配送单？',
    value: 'キャンセルしますか？'
  },
  {
    key: '签收',
    value: '受け取り'
  }, {
    key: '是否签收该配送单？',
    value: 'この配送書を受け取りますか？'
  }, {
    key: '签收成功',
    value: '受領完了'
  },
  {
    key: '已取消签收',
    value: '受領キャンセル済み'
  },
  {
    key: '已评价',
    value: '評価済み'
  },
  {
    key: '交易生成时间',
    value: '日付'
  },
  {
    key: '余额',
    value: '残高'
  },
  {
    key: '收费仓库里货物需要发货，请直接与您的负责人联系！',
    value: '課金倉庫の商品を発送されたい場合は担当者までご連絡ください。'
  },
  {
    key: '国际物流',
    value: '国際送料'
  },
  {
    key: '手续费（元）',
    value: '手数料（元）'
  },
  {
    key: '全部通知',
    value: 'すべての通知'
  },
  {
    key: '已读',
    value: '既読'
  },
  {
    key: '未读',
    value: '未読'
  },
  {
    key: '通知',
    value: 'お知らせ'
  },
  {
    key: '删除(购物车)',
    value: '削除（ショッピングカート）'
  },
  {
    key: '删除提示',
    value: '削除の確認'
  },
  {
    key: '其他',
    value: 'その他'
  },
  {
    key: '成为Rakumart的会员',
    value: 'rakumart会員登録'
  },
  {
    key: '立即注册',
    value: '今すぐ新規登録'
  },
  {
    key: '寻找产品下单，我们确认报价方案，您可进行付款',
    value: 'ご希望の商品をカートに入れ、まとめたらオファーをご提出いただきます。弊社より見積提出後、支払いへ'
  },
  {
    key: '下单',
    value: '発注'
  },
  {
    key: '在我们仓库进行质量把控与存储',
    value: '弊社の中国検品センターで日本の消費者基準で検品した後再梱包します。'
  },
  {
    key: '请更换其他支付方式',
    value: '他の支払い方法を選択してください'
  },
  {
    key: '提出发货指令，我们进行物流咨询，给您提供报价方案，由您选择国际运输并付款',
    value: '商品入庫後、発送分の商品をご指定頂ければ発送手配へ'
  },
  {
    key: '等待产品运输到您指定的地点',
    value: '輸出入通関を経てご指定の場所へお届けします'
  },
  {
    key: '工作时间',
    value: '受付時間'
  },
  {
    key: '09:30-18:30(日本時間)',
    value: '日本時間 10:00~18:30（土、日曜日及び中国の祝日はお休み）'
  },
  {
    key: '为你推荐',
    value: 'お勧め'
  },
  {
    key: '全部问题',
    value: 'すべて'
  },
  {
    key: '请输入银行名',
    value: '銀行名を入力してください'
  },
  {
    key: '代购',
    value: '代行サービス'
  },
  {
    key: '请输入支店番号',
    value: '支店番号を入力してください'
  },
  {
    key: '请输入支店名',
    value: '支店名を入力してください'
  },
  {
    key: '请输入預金種目',
    value: '預金種目を入力してください'
  },
  {
    key: '请输入口座名義',
    value: '口座名義を入力してください'
  },
  {
    key: '请输入姓氏',
    value: '苗字を入力してください'
  },
  {
    key: '无在库',
    value: '無在庫サービス（D2C）'
  },
  {
    key: '待采购',
    value: '買付中'
  },
  {
    key: '待采购',
    value: '買付中'
  },
  {
    key: 'OEM',
    value: 'OEM、ODMサービス'
  },
  {
    key: '待回复',
    value: '処理中'
  },
  {
    key: '待后续处理',
    value: '処理待ち'
  },
  {
    key: '已处理',
    value: '完了'
  },
  {
    key: '产品',
    value: '商品'
  },
  {
    key: '地址',
    value: '住所'
  },
  {
    key: '全名',
    value: ' 全名'
  },
  {
    key: '请输入全名',
    value: '请输入全名'
  },
  {
    key: 'Rakumart，我们帮助您从中国进口',
    value: 'Rakumart，我们帮助您从中国进口'
  }, {
    key: 'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。',
    value: 'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。'
  }, {
    key: '全面、便捷的中国进口管理。',
    value: '全面、便捷的中国进口管理。'
  }, {
    key: '专注于您的业务',
    value: '专注于您的业务'
  },
  {
    key: '可发往世界各地，优化所有物流成本',
    value: '可发往世界各地，优化所有物流成本'
  },
  {
    key: '多样化的物流选择',
    value: '多样化的物流选择'
  },
  {
    key: '为所有产品需求提供额外服务',
    value: '为所有产品需求提供额外服务'
  },
  {
    key: '为您全流程的附加服务提供解决方案',
    value: '为您全流程的附加服务提供解决方案'
  }, {
    key: '出货完成',
    value: '出荷完成'
  },
  {
    key: '出货',
    value: '出荷処理'
  },
  {
    key: '创建新帐户',
    value: '新しいアカウントを作成する'
  },
  {
    key: '请输入名称',
    value: '名前を入力してください'
  },
  {
    key: '商品属性',
    value: '商品情報'
  },
  {
    key: '商品标题',
    value: '商品タイトル'
  }, {
    key: '规格',
    value: 'その他'
  }, {
    key: '颜色',
    value: 'カラー'
  }, {
    key: '选择该商品',
    value: 'この商品を選択する'
  }, {
    key: '回到顶部',
    value: 'トップへ戻る'
  }, {
    key: '你的最爱',
    value: 'お気に入り'
  },
  {
    key: '资料库',
    value: 'データベース'
  },
  {
    key: '数据获取错误',
    value: 'データの取得エラー'
  },
  {
    key: '*根据期间限定减价和批量价格变化的情况下，有用Rakumart不正确表示的情况，不过，因为估价完成时被修正，请那样提交估价。',
    value: '*期間限定セール価格やロットによって値段が変わる場合、Rakumartで正しく表示されない場合がありますが、見積完了時に修正されますので、その ままお見積りをご提出下さいませ。'
  },
  {
    key: '国内配送',
    value: '国内配送'
  },
  {
    key: '运费',
    value: '中国送料'
  },
  {
    key: '缺少必填项',
    value: 'この質問は必須です'
  },
  {
    key: '配送单已签收',
    value: '過去の配送履歴'
  },
  {
    key: '到着',
    value: '到着'
  },
  {
    key: '姓氏',
    value: '苗字'
  },
  {
    key: '名称',
    value: '名前'
  },
  {
    key: '店铺信息',
    value: '店舗情報'
  },
  {
    key: '出荷',
    value: '出荷'
  },
  {
    key: '优惠券兑换',
    value: 'クーポン申請'
  },
  {
    key: '立即兑换',
    value: '今すぐ申請'
  },
  {
    key: '请填写到会登记的email地址',
    value: '参加登録したメールアドレスを記入してください'
  },
  {
    key: '请填写兑换码',
    value: 'シリアルナンバーを記入してください'
  },
  {
    key: '定金待支付',
    value: '頭金支払い待ち'
  },
  {
    key: '尾款待支付',
    value: '残債支払い待ち'
  },
  {
    key: '去支付定金',
    value: '頭金支払いへ'
  },
  {
    key: '去支付尾款',
    value: '残債支払いへ'
  },
  {
    key: '待付尾款',
    value: '残債支払い待ち'
  },
  {
    key: '待付首款',
    value: '頭金支払い待ち'
  },
  {
    key: '复制',
    value: 'コピー'
  },
  {
    key: '已上架数量',
    value: '到着済み'
  },
  {
    key: '订单跟进注意事项',
    value: '注文書管理用備考欄'
  },
  {
    key: '亚马逊店铺',
    value: '亚马逊店铺'
  },
  {
    key: '乐天店铺',
    value: '乐天店铺'
  },
  {
    key: '店铺链接',
    value: '店舗URL'
  },
  {
    key: '添加日期',
    value: '追加日'
  },
  {
    key: 'API授权状态',
    value: 'API許可状態'
  },
  {
    key: '店铺状态',
    value: '店舗状態'
  },
  {
    key: '新增店铺',
    value: '新規追加'
  },
  {
    key: '亚马逊',
    value: 'Amazon'
  },
  {
    key: '已授权',
    value: '連結済み'
  },
  {
    key: '编辑店铺',
    value: '编辑店铺'
  },
  {
    key: '请选择店铺类型',
    value: '请选择店铺类型'
  },
  {
    key: '请输入店铺名称',
    value: '请输入店铺名称'
  },
  {
    key: '请输入店铺链接',
    value: '请输入店铺链接'
  },
  {
    key: '授权状态',
    value: '許可状態'
  },
  {
    key: '亚马逊仓库管理',
    value: 'Amazon 在庫管理'
  },
  {
    key: '店铺授权',
    value: '店舗API連結'
  },
  {
    key: '库存报告',
    value: 'Amazon在庫'
  },
  {
    key: '以上SKU商品没设置亚马逊补货入仓商品，无法生成订单，请确认。',
    value: '仕入れ先が登録されていないため、注文できません'
  },
  {
    key: '超过补货链接最大数量',
    value: '仕入れ先最大３つまで'
  },
  {
    key: '商品名/SKU/ASIN',
    value: '商品名/SKU/ASIN'
  },
  {
    key: '同步库存',
    value: '在庫状態更新'
  },
  {
    key: '商品SKU',
    value: '商品SKU'
  },
  {
    key: '可售量',
    value: '在庫数'
  },
  {
    key: '不可售量',
    value: '不可售量'
  },
  {
    key: '出入库作业中',
    value: '出入库作业中'
  },
  {
    key: '补货链接',
    value: '仕入れ先'
  },
  {
    key: '新增链接',
    value: '新規追加'
  },
  {
    key: '链接',
    value: 'URL'
  },
  {
    key: '亚马逊入仓商品',
    value: '亚马逊入仓商品'
  },
  {
    key: '下单后自动标识为入仓商品',
    value: '下单后自动标识为入仓商品'
  },
  {
    key: 'ctrl+v粘贴图片，可快速传图到搜索',
    value: '検索窓にctrl+vで画像をペーストし、検索を行う'
  },
  {
    key: '是否删除该补货链接？',
    value: '削除しますか？'
  },
  {
    key: '以上SKU商品没设置亚马逊补货入仓商品，无法生成订单，请确认',
    value: '上記商品の仕入れ先が登録されていないため、注文できません。'
  },
  {
    key: '搜索图片',
    value: '画像検索'
  },
  {
    key: '热搜关键词',
    value: '急上昇ワード'
  },
  {
    key: '亚马逊补货商品信息',
    value: '亚马逊补货商品信息'
  },
  {
    key: '亚马逊仓库地址',
    value: '亚马逊仓库地址'
  },
  {
    key: '一键代发',
    value: '1点から仕入れ可能'
  },
  {
    key: '固定在左侧',
    value: '左側に固定する'
  },
  {
    key: '隐藏相同点',
    value: '共通点を隠す'
  },
  {
    key: '高亮不同点',
    value: '相違点を表示する'
  },
  {
    key: '过去30天复购率',
    value: '過去30日のリピード率'
  },
  {
    key: '过去30天销量',
    value: '過去30日の販売数'
  },
  {
    key: '图片链接',
    value: '画像url'
  },
  {
    key: '按照主分类筛选',
    value: 'メインカテゴリーをお選び下さい。'
  },
  {
    key: '按照子分类筛选',
    value: 'サブカテゴリーをお選び下さい。'
  },
  {
    key: '查看更多',
    value: 'もっと見る'
  },
  {
    key: '请选择分类查看热搜榜单',
    value: 'カテゴリーを選び、ヒット商品を見つける'
  },
  {
    key: '在结果中搜索',
    value: '表示結果から検索'
  },
  {
    key: '最终同步时间',
    value: '最終更新'
  },
  {
    key: '最终同步时间',
    value: '最終更新'
  },
  {
    key: '成本估算',
    value: '原価計算'
  },
  {
    key: '购入总成本',
    value: 'コスト合計'
  },
  {
    key: '单件成本',
    value: 'コスト/点'
  },
  {
    key: '网站更新情报',
    value: 'Web更新情報'
  },
  {
    key: '上一页',
    value: '前のページ'
  },
  {
    key: '下一页',
    value: '次のページ'
  },
  {
    key: '必须要有一个默认补货下单的商品链接',
    value: 'デフォルトの補充商品リンクをご選択ください'
  },
  {
    key: '下单默认使用',
    value: 'デフォルトの注文リンクとして設定'
  },
  {
    key: '请输入购买商品相关联的品名进行HSCODE匹配，在所有结果中您可以选择最合适的材质与品名',
    value: '注文する商品の商品名をご記入してからHSコードを調べます'
  },
  {
    key: '关税率',
    value: '関税率'
  },
  {
    key: '国际运费详情',
    value: '国際送料詳細'
  },
  {
    key: '购买数',
    value: '注文数'
  },
  {
    key: '常规箱子规格',
    value: '常规箱子规格'
  },
  {
    key: '箱子规格',
    value: '段ボール規格'
  },
  {
    key: '参考国际运费',
    value: '国際送料'
  },
  {
    key: '合计抛重',
    value: '合計容積重量'
  },
  {
    key: '合计实重',
    value: '合計重量'
  },
  {
    key: '合计体积',
    value: '合計容積'
  },
  {
    key: '预计地方消费税',
    value: '予想地方消費税'
  },
  {
    key: '预计消费税',
    value: '予想消費税'
  },
  {
    key: '预估关税',
    value: '予想関税'
  },
  {
    key: '预估国际运费',
    value: '预估国际运费'
  },
  {
    key: '附加服务费',
    value: 'オプション'
  },
  {
    key: '中国国内运费',
    value: '中国国内送料'
  },
  {
    key: '所有商品以一箱来计算成本',
    value: '全ての商品は1箱で原価を計算します'
  },
  {
    key: '国际物流选择',
    value: '国際運送会社選択'
  },
  {
    key: '该商品获取不到HS编码信息',
    value: '该商品获取不到HS编码信息'
  },
  {
    key: '请选择HS编码',
    value: 'HSコードをご選択ください'
  },
  {
    key: '参考重量',
    value: '参考重量'
  },
  {
    key: '成本预估使用',
    value: '原価計算用'
  },
  {
    key: '细节确认',
    value: '詳細確認'
  },
  {
    key: '采购成本计算器',
    value: '原価計算ツール'
  },
  {
    key: '请注意，计算的结果有与实际费用不同的情况。以及商品实际通关的关税税率由海关决定，可能与当前选择的不同。计算结果仅供参考',
    value: 'ご注意：計算結果は実際の費用と異なる場合があります。また、商品の通関時の関税率は税関により決定されるため、現在選択しているものとは異なる可能性があります。計算結果は参考としてご利用ください'
  },
  {
    key: '关税',
    value: '関税'
  },
  {
    key: '收费标准',
    value: 'オプション費用'
  },
  {
    key: '自组货源',
    value: '自己管理商品パレート'
  },
  {
    key: '意向售卖参考',
    value: '販売予定商品のまとめ'
  },
  {
    key: '榜单商品推荐',
    value: '売れ筋商品のお勧め'
  },
  {
    key: '设置',
    value: '編集'
  },
  {
    key: '添加意向售卖商品',
    value: '販売予定商品を追加する'
  },
  {
    key: '商品更新时间',
    value: '商品更新时间'
  },
  {
    key: '已匹配商品集合数量',
    value: '已匹配商品集合数量'
  },
  {
    key: '我喜欢的',
    value: '我喜欢的'
  },
  {
    key: '商品展示',
    value: '商品展示'
  },
  {
    key: '该货盘下没有商品，请换个货盘再试',
    value: 'この商品パレートに商品はありません、他の商品パレートをご確認下さい。'
  },
  {
    key: '当前仅支持RAKUMART链接，淘宝链接，1688链接',
    value: 'Rakumart、タオバオ及び1688の商品URLをご記入下さい'
  },
  {
    key: '具体信息',
    value: '詳細情報'
  },
  {
    key: '商品更新通知',
    value: '商品更新の知らせ方法'
  },
  {
    key: '站内信通知',
    value: 'システムのお知らせ'
  },
  {
    key: '邮件通知',
    value: 'メール'
  },
  {
    key: '高级筛选指标',
    value: '絞り込み条件'
  },
  {
    key: '没有您想要筛选的指标',
    value: '他の絞り込み条件を追加してほしいですか'
  },
  {
    key: '当列表没有您想要的指标进行设置时，您可以添加自定义指标，指标提交后，RAKUMART担当会与您联系进行确认，指标后台审核通过后，可在页面进行选择',
    value: 'お気に入りの絞り込み条件がない場合、自己定義の絞り込み条件の追加は可能です。追加申請をご提出した後、Rakumart担当者から詳細を確認します。確認完了後、ご追加の絞り込み条件が表示されます'
  },
  {
    key: '自定义指标',
    value: '自己定義の絞り込み条件'
  },
  {
    key: '留言',
    value: '説明'
  },
  {
    key: '自定义指标申请记录',
    value: '自己定義の絞り込み条件の申請記録'
  },
  {
    key: '添加时间',
    value: '追加日付'
  },
  {
    key: 'HSCODE编码查询',
    value: 'HSコード検索'
  },
  {
    key: '所有分类',
    value: 'カテゴリー'
  },
  {
    key: '历史购买数据参考',
    value: '历史购买数据参考'
  },
  {
    key: '只统计历史购买量TOP9的商品',
    value: '只统计历史购买量TOP9的商品'
  },
  {
    key: '确认删除该货盘吗？',
    value: '确认删除该货盘吗？'
  },
  {
    key: '历史下单数量',
    value: '历史下单数量'
  },
  {
    key: '历史购买价格',
    value: '历史购买价格'
  },
  {
    key: '商品原链接',
    value: '商品原链接'
  },
  {
    key: '商品原链接',
    value: '商品原链接'
  },
  {
    key: '搜索条件设置完成之后，每天24点自动帮您寻找符合您条件的商品，商品一天更新一次',
    value: '搜索条件设置完成之后，每天24点自动帮您寻找符合您条件的商品，商品一天更新一次'
  },
  {
    key: '添加到优化列表',
    value: '添加到优化列表'
  },
  {
    key: '最近购买数据参考',
    value: '最近购买数据参考'
  },
  {
    key: '只展示最近购买的10个订单的商品',
    value: '只展示最近购买的10个订单的商品'
  },
  {
    key: '商品图片',
    value: '商品图片'
  },
  {
    key: '下单价格',
    value: '下单价格'
  },
  {
    key: '员工账户选择',
    value: '社員用アカウントを選択してください'
  },
  {
    key: '点击箱子编号或者箱子规格，可以查看具体装箱明细',
    value: 'クリックして梱包明詳細を確認'
  },
  {
    key: '在售货源优化',
    value: '在售货源优化'
  },
  {
    key: '一次最多只能导出10个订单',
    value: '一度に最大10件の注文書しかエクスポートできません'
  },
  {
    key: '导出表格请至少选择一个订单',
    value: '注文書を選択してください'
  },
  {
    key: '请至少选择一个订单',
    value: '请至少选择一个订单'
  },
  {
    key: '货盘内商品',
    value: '货盘内商品'
  },
  {
    key: '商品每日自动补充',
    value: '商品每日自动补充'
  },
  {
    key: '关闭',
    value: '关闭'
  },
  {
    key: '开启',
    value: '开启'
  },
  {
    key: '通知时间',
    value: '通知时间'
  },
  {
    key: '商品确认',
    value: '商品确认'
  },
  {
    key: '分类',
    value: 'スタイル'
  },
  {
    key: '目前没有商品查找任务，可以点击“立即查找”为您补充商品',
    value: '目前没有商品查找任务，可以点击“立即查找”为您补充商品'
  },
  {
    key: '立即查找',
    value: '立即查找'
  },
  {
    key: '查找中',
    value: '查找中'
  },
  {
    key: '查找完成',
    value: '查找完成'
  },
  {
    key: '我们会按照您设置的通知方式与通知时间给您进行通知',
    value: '我们会按照您设置的通知方式与通知时间给您进行通知'
  },
  {
    key: '商品时间',
    value: '商品时间'
  },
  {
    key: '实时通知',
    value: '实时通知'
  },
  {
    key: '固定时间',
    value: '固定时间'
  },
  {
    key: '通知时间',
    value: '通知时间'
  },
  {
    key: '商品是否需要确认',
    value: '商品是否需要确认'
  },
  {
    key: '是否需要每天自动查找商品',
    value: '是否需要每天自动查找商品'
  },
  {
    key: '每次获取商品数量',
    value: '每次获取商品数量'
  },
  {
    key: '您可以设置商品查找完成后，是否需要给你进行通知，设置需要通知的情况，需要进行通知时间确认',
    value: '您可以设置商品查找完成后，是否需要给你进行通知，设置需要通知的情况，需要进行通知时间确认'
  },
  {
    key: '商品查找到之后，是否需要人工确认',
    value: '商品查找到之后，是否需要人工确认'
  },
  {
    key: '如果设置需要人工确认，商品获取后，先到待确认列表，经由人工操作后，再进入入货盘',
    value: '如果设置需要人工确认，商品获取后，先到待确认列表，经由人工操作后，再进入入货盘'
  },
  {
    key: '如果设置不需要人工确认，商品确认后，商品直接加入货盘',
    value: '如果设置不需要人工确认，商品确认后，商品直接加入货盘'
  },
  {
    key: '是否需要每天为您查找商品补充进入货盘',
    value: '是否需要每天为您查找商品补充进入货盘'
  },
  {
    key: '设置开通后，每天夜间(12:00)将会自动查找商品',
    value: '设置开通后，每天夜间(12:00)将会自动查找商品'
  },
  {
    key: '*数量最多500个，最小1个',
    value: '*数量最多500个，最小1个'
  },
  {
    key: '我们会按照您需求的货盘数量，进行查找商品，当数量达到您的要求时，查找会停止。',
    value: '我们会按照您需求的货盘数量，进行查找商品，当数量达到您的要求时，查找会停止。'
  },
  {
    key: '商品查找条件',
    value: '商品查找条件'
  },
  {
    key: '选择条件',
    value: '选择条件'
  },
  {
    key: '我们为您准备了很多已经组合好得，各种类型的商品查找条件模版，您可以直接使用',
    value: '我们为您准备了很多已经组合好得，各种类型的商品查找条件模版，您可以直接使用'
  },
  {
    key: '您可以自己组合商品查找条件，组合成固定使用的模版',
    value: '您可以自己组合商品查找条件，组合成固定使用的模版'
  },
  {
    key: '使用推荐模版',
    value: '使用推荐模版'
  },
  {
    key: '使用自定义模板',
    value: '使用自定义模板'
  },
  {
    key: '指标设置',
    value: '指标设置'
  },
  {
    key: '山东，江苏，安徽，浙江，福建，上海，湖北，湖南，河南，江西',
    value: '山东，江苏，安徽，浙江，福建，上海，湖北，湖南，河南，江西'
  },
  {
    key: '以上地区发货预计3天到达国内仓库',
    value: '以上地区发货预计3天到达国内仓库'
  },
  {
    key: '推荐模板',
    value: '推荐模板'
  },
  {
    key: '自定义模板',
    value: '自定义模板'
  },
  {
    key: '新增模板',
    value: '新增模板'
  },
  {
    key: '是否删除该自定义模板？',
    value: '是否删除该自定义模板？'
  },
  {
    key: '确认保存该指标模板吗？',
    value: '确认保存该指标模板吗？'
  },
  {
    key: '确认删除该指标模板吗？',
    value: '确认删除该指标模板吗？'
  },
  {
    key: '移除搜索结果',
    value: '移除搜索结果'
  },
  {
    key: '商品放入货盘',
    value: '商品放入货盘'
  },
  {
    key: '商品查找完成时间',
    value: '商品查找完成时间'
  },
  {
    key: '待确认商品',
    value: '待确认商品'
  },
  {
    key: '放入货盘',
    value: '放入货盘'
  },
  {
    key: '移除',
    value: '移除'
  },
  {
    key: '移出货盘',
    value: '移出货盘'
  },
  {
    key: '是否把选中商品移除？',
    value: '是否把选中商品移除？'
  },
  {
    key: '降价',
    value: '値下げ'
  },
  {
    key: '加入时价',
    value: '元の単価'
  },
  {
    key: '当前价',
    value: '現在の単価'
  },
  {
    key: '距加入降',
    value: '値下げ額'
  },
  {
    key: '商品查找完成后，会放入待确认列表，需要人工确认后，加入货盘',
    value: '商品查找完成后，会放入待确认列表，需要人工确认后，加入货盘'
  },
  {
    key: '请勿重复提交相同请求',
    value: '同じリクエストを繰り返し送信しないでください'
  },
  {
    key: '商品管理',
    value: '欲しい物リスト'
  },
  {
    key: '产品证书',
    value: '製品書'
  },
  {
    key: '证书名',
    value: '証明書'
  },
  {
    key: '证书编号',
    value: '証明書番号'
  },
  {
    key: '商家提供的证书图片',
    value: '提供された証明書画像'
  },
  {
    key: '该证书为卖家自行提供',
    value: '製品書は工場より提供されます'
  },
  {
    key: '数据最新更新日期',
    value: '最終更新時間'
  },
  {
    key: '查看趋势图',
    value: '価額推移グラフを見る'
  },
  {
    key: '价格趋势',
    value: '単価の推移'
  },
  {
    key: '下单数量',
    value: '注文数'
  },
  {
    key: '首付款待财务确认',
    value: '注文数'
  },
  {
    key: '查看趋势图',
    value: '価額推移グラフを見る'
  },
]
let fanyi = (value) => {
  for (let i = 0; i < langs.length; i++) {
    if (langs[i].key == value) {
      return langs[i].value
    }
  }
  return value
}
export default fanyi
