import {apiAxios} from '../axios'
import Europess from '../wangZhi'

let Europe = Europess + '/client/'
let comm_api = Europess + '/api'
export default {
  // 登录
  logins: data => apiAxios('post', Europe + "userLogin", data),
  // 注册
  register: data => apiAxios('post', Europe + "userRegister", data),
  //子账户列表
  childrenUserList: data => apiAxios('post', Europe + "children.childrenUserList", data),
  //注册子账户参数检验
  checkChildrenUserRegisterField: data => apiAxios('post', Europe + "children.checkChildrenUserRegisterField", data),
  //注册子账户提交
  childrenUserRegister: data => apiAxios('post', Europe + "children.childrenUserRegister", data),
  //编辑子账户信息提交
  childrenUserEdit: data => apiAxios('post', Europe + "children.childrenUserEdit", data),
  //切换子账户状态【开启or禁用】
  childrenUserEditStatus: data => apiAxios('post', Europe + "children.childrenUserEditStatus", data),
  //订单列表侧边栏数量统计
  leftSidebarNum: data => apiAxios('post', Europe + "leftSidebarNumV2", data),
  // 注册字段校验
  checkRegisterField: data => apiAxios('post', Europe + "checkRegisterField", data),
  // 获取用户资料
  japanGetUserInfo: data => apiAxios('post', Europe + "user.info", data),
  // 购物车列表
  goodsCartList: (data) => apiAxios('post', Europe + "cart.goodsCartList", data),
  // 删除购物车商品
  goodsCartDelete: data => apiAxios('post', Europe + "cart.goodsCartDelete", data),
  // 修改购物车商品数量
  goodsCartEdit: data => apiAxios('post', Europe + "cart.goodsCartEdit", data),
  // 商品添加购物车
  goodsToCart: data => apiAxios('post', Europe + "cart.goodsToCart", data),
  //获取店铺推荐商品
  getShopRecommendedGoods: data => apiAxios('post', Europe + 'shopHotGoods', data),
  //店铺全部商品
  getShopAllGoods: data => apiAxios('post', Europe + 'shopAllGoods', data),
  //跨境版-店铺全部商品
  getSellerOfferList: data => apiAxios('post', Europe + 'sellerOfferList', data),
  //跨境版-1688店铺分类
  shopCates: data => apiAxios('post', Europe + 'shopCates', data),
  //店铺收藏备注信息编辑
  shopNoteSave: data => apiAxios('post', Europe + 'favorite.shopNoteSave', data),
  //店铺收藏分配标签
  shopToTags: data => apiAxios('post', Europe + 'favorite.shopToTags', data),
  //获取TIM客户未读消息
  getTimMessageUnreadNum: data => apiAxios('post', Europe + 'user.getTimMessageUnreadNum', data),
  //店铺收藏新增
  shopAdd: data => apiAxios('post', Europe + 'favorite.shopAdd', data),
  //查询某店铺是否已收藏
  shopCheckIn: data => apiAxios('post', Europe + 'favorite.shopCheckIn', data),
  //店铺收藏移除
  shopDelete: data => apiAxios('post', Europe + 'favorite.shopDelete', data),
  //店铺收藏列表
  shopList: data => apiAxios('post', Europe + 'favorite.shopList', data),
  //商品搜索结果问题反馈
  searchGoodsFeedback: data => apiAxios('post', Europe + "searchGoodsFeedback", data),
  // 查询购物车总条目数
  goodsToCartNum: data => apiAxios('post', Europe + "cart.goodsCartNum", data),
  // 获取子账户权限
  childrenUserGetAuth: data => apiAxios('post', Europe + "children.childrenUserGetAuth", data),
  // 编辑子账户权限
  childrenUserEditAuth: data => apiAxios('post', Europe + "children.childrenUserEditAuth", data),
  // 获取各状态订单条目数
  ordersSum: data => apiAxios('post', Europe + "order.orderListTables", data),
  // 订单列表
  orderList: data => apiAxios('post', Europe + "order.orderList", data),
  // 地址列表
  userAddressList: data => apiAxios('post', Europe + "address.list", data),
  // 删除地址
  userAddressDelete: data => apiAxios('post', Europe + "address.delete", data),
  // 保存地址（新增or编辑）
  userAddressUpdate: data => apiAxios('post', Europe + "address.save", data),
  // 第三方仓库地址
  thirdPartyWarehouseList: data => apiAxios('post', Europe + "address.third_party", data),
  // 商品添加收藏
  favoriteGoodsAdd: data => apiAxios('post', Europe + "favorite.goodsAdd", data),
  // 实时汇率
  commonExchangeRate: data => apiAxios('post', comm_api + "/common/exchangeRate", data),
  //账户优惠券展示
  couponGet: data => apiAxios('post', Europe + "user.usableDiscount", data),
  //账户优惠券使用记录
  couponRecord: data => apiAxios('post', Europe + "user.pastDiscount", data),
  // 收藏夹标签列表
  favoriteTagList: data => apiAxios('post', Europe + "favorite.tagList", data),
  //商品收藏Table数据量合计
  goodsTableNum: data => apiAxios('post', Europe + "favorite.goodsTableNum", data),
  //弹出未作答的必答问卷
  mustParticipateQuestionnaire: data => apiAxios('post', Europe + "user.mustParticipateQuestionnaire", data),
  //问券详情
  questionnaireDetail: data => apiAxios('post', Europe + "user.questionnaireDetail", data),
  //提交调查问卷作答
  questionnaireSubmit: data => apiAxios('post', Europe + "user.questionnaireSubmit", data),
  //商品库Table数据量合计
  inventoryTableNum: data => apiAxios('post', Europe + "favorite.inventoryTableNum", data),
  // 收藏夹商品列表
  favoriteGoodsList: data => apiAxios('post', Europe + "favorite.goodsList", data),
  //修改子账户密码
  childrenUserUpdatePassword: data => apiAxios('post', Europe + "children.childrenUserUpdatePassword", data),
  // 商品收藏分配标签
  favoriteGoodsToTags: data => apiAxios('post', Europe + "favorite.goodsToTags", data),
  //商品库分配标签
  inventoryGoodsToTags: data => apiAxios('post', Europe + "favorite.inventoryToTags", data),
  //商品库-实时拖动排序
  inventoryDragSorting: data => apiAxios('post', Europe + "favorite.inventoryList.dragSorting", data),
  // 删除标签
  favoriteTagDelete: data => apiAxios('post', Europe + "favorite.tagDelete", data),
  //添加标签
  favoriteTagAdd: data => apiAxios('post', Europe + "favorite.tagAdd", data),
  //商品收藏移除
  favoriteGoodsDelete: data => apiAxios('post', Europe + "favorite.goodsDelete", data),
  // 商品库商品列表
  favoriteInventoryList: data => apiAxios('post', Europe + "favorite.inventoryList", data),
  // 商品库商品移除
  favoriteInventoryDelete: data => apiAxios('post', Europe + "favorite.inventoryDelete", data),
  // 商品库商品批量保存
  favoriteInventoryBatchSave: data => apiAxios('post', Europe + "favorite.inventoryBatchSave", data),
  // 余额提现明细
  capitalCashOutList: data => apiAxios('post', Europe + "user.balanceExtractDetail", data),
  // 出入金记录
  capitalBillList: data => apiAxios('post', Europe + "user.balanceChange", data),
  //出入金订单配送单号展开详情
  capitalBillListGetOrderPorderDetail: data => apiAxios('post', Europe + "user.getOrderPorderDetail", data),
  // 获取出入金类型&&交易方式
  getBalanceChangeTypes: data => apiAxios('post', Europe + "user.getBalanceChangeTypes", data),
  // paypal充值余额
  payPalPayBalance: data => apiAxios('post', Europe + "user.payPalPayBalance", data),
  //payPal支付结果查询
  payPalSuccess: data => apiAxios('post', Europe + "user.payPalSuccess", data),
  // 订单支持使用的优惠券
  orderUsableDiscount: data => apiAxios('post', Europe + "order.usableDiscount", data),
  // payPal支付订单
  orderPayPalPayOrder: data => apiAxios('post', Europe + "order.payPalPayOrder", data),
  // payPal支付配送单
  porderPayPalPayOrder: data => apiAxios('post', Europe + "porder.payPalPayOrder", data),
  // 余额提现申请
  capitalCashOutSend: data => apiAxios('post', Europe + "user.balanceExtract", data),
  // 验证密码
  userCheckPassword: data => apiAxios('post', Europe + "user.checkLoginUserPassword", data),
  // 问题产品列表
  problemGoodsOrderList: data => apiAxios('post', Europe + "question.list", data),
  //  问题产品Table旁边的数量
  problemGoodsOrderNum: data => apiAxios('post', Europe + "question.listTableNum", data),
  // 客户反馈处理意见
  problemGoodsClientDeal: data => apiAxios('post', Europe + "question.over", data),
  //  国内物流状态列表
  chinaLogisticsList: data => apiAxios('post', Europe + "logistics.chinaLogistics.list", data),
  //  中国国内物流Table数据量合计
  chinaLogisticsListTableNum: data => apiAxios('post', Europe + "logistics.chinaLogistics.listTableNum", data),
  //  清除商品缓存
  clearGoodsCache: data => apiAxios('post', Europe + "clearGoodsCache", data),
  //  获取修改手机号验证码
  getUpdateMobileVerificationCode: data => apiAxios('post', Europe + "user.getUpdateMobileVerificationCode", data),
  //  获取修改密码验证码
  getUpdatePasswordVerificationCode: data => apiAxios('post', Europe + "user.getUpdatePasswordVerificationCode", data),
  //  普通仓库列表
  stockAutoList: data => apiAxios('post', Europe + "wms.stockAutoList", data),
  //  收费仓库列表
  stockFeeList: data => apiAxios('post', Europe + "wms.stockFeeList", data),
  //  普通仓库-修改商品单番客户备注
  updateStockClientRemark: data => apiAxios('post', Europe + "wms.updateClientRemark", data),
  //  普通仓库-修改商品单番标签
  updateStockOrderDetailTag: data => apiAxios('post', Europe + "wms.updateOrderDetailTag", data),
  //  商品历史标签（订单详情通用）
  goodsTagLogs: data => apiAxios('post', Europe + "favorite.goodsTagLogs", data),
  //   修改头像
  userPortraitSave: data => apiAxios('post', Europe + "user.updatePortraitUrl", data),
  // 创建新配送单
  storageSendDeliver: data => apiAxios('post', Europe + "porder.porderCreate", data),
  // 配送单列表
  storageDeliverList: data => apiAxios('post', Europe + "porder.porderList", data),
  // 配送单详情
  storageDeliverDetail: data => apiAxios('post', Europe + "porder.porderDetail", data),
  // 配送单状态Table旁边的数量
  storageDeliverNum: data => apiAxios('post', Europe + "porder.porderListTables", data),
  // 临时配送单删除
  storageTampDeliverDelete: data => apiAxios('post', Europe + "porder.porderDeleteTemporary", data),
  // 配送单-评价
  porderEvaluate: data => apiAxios('post', Europe + "porder.evaluate", data),
  // 余额支付开通VIP
  balancePayVip: data => apiAxios('post', Europe + "user.balancePayVip", data),
  // 客户签收配送单
  internationalLogisticsClientReceive: data => apiAxios('post', Europe + "porder.porderReceive", data),
  // 用户账号等级规则
  getUserLevelRoot: data => apiAxios('post', Europe + "user.getUserLevelRoot", data),
  // 银行汇款充值余额
  capitalBankUpBalance: data => apiAxios('post', Europe + "user.bankPayBalance", data),
  // 获取未查看的通知
  noticeUnreadNotice: data => apiAxios('post', Europe + "notice.unreadNotice", data),
  // 标记已读
  noticeReadNotice: data => apiAxios('post', Europe + "notice.readNotice", data),
  // 验证码校验(修改手机号)
  checkUpdateMobileValidationCode: data => apiAxios('post', Europe + "user.checkUpdateMobileValidationCode", data),
  // 查看通知列表
  noticeClientNoticeList: data => apiAxios('post', Europe + "notice.clientNoticeList", data),
  // 批量删除通知
  noticeBatchDelete: data => apiAxios('post', Europe + "notice.batchDelete", data),
  // 支付授权
  payAuthorized: data => apiAxios('post', Europe + "allow.payAuthorized", data),
  // 装箱授权
  encasementAuthorized: data => apiAxios('post', Europe + "allow.encasementAuthorized", data),
  // 设置默认地址
  setDefaultAddress: data => apiAxios('post', Europe + "address.setDefault", data),
  // 客户提交反馈
  addFeedback: data => apiAxios('post', Europe + "feedback.addFeedback", data),
  // 座机和注意事项修改(无修改风险的)
  updateAutoInfo: data => apiAxios('post', Europe + "user.updateAutoInfo", data),
  //获取修改邮箱验证码
  getUpdateEmailVerificationCode: data => apiAxios('post', Europe + "user.getUpdateEmailVerificationCode", data),
  //验证码校验(修改邮箱地址)
  checkUpdateEmailValidationCode: data => apiAxios('post', Europe + "user.checkUpdateEmailValidationCode", data),
  //通过验证码修改邮箱地址
  updateEmailViaValidationCode: data => apiAxios('post', Europe + "user.updateEmailViaValidationCode", data),
  //通过旧密码设置新密码
  updatePasswordViaOldPassword: data => apiAxios('post', Europe + "user.updatePasswordViaOldPassword", data),
  //通过验证码修改新手机号
  updateMobileViaValidationCode: data => apiAxios('post', Europe + "user.updateMobileViaValidationCode", data),
  //通过密码设置新邮箱
  updateEmailViaOldPassword: data => apiAxios('post', Europe + "user.updateEmailViaOldPassword", data),
  //通过密码设置新手机号
  updateMobileViaOldPassword: data => apiAxios('post', Europe + "user.updateMobileViaOldPassword", data),
  //通过验证码修改新密码
  updatePasswordViaValidationCode: data => apiAxios('post', Europe + "user.updatePasswordViaValidationCode", data),
  //验证码校验(修改密码)
  checkUpdatePasswordValidationCode: data => apiAxios('post', Europe + "user.checkUpdatePasswordValidationCode", data),
  //获取重设密码验证码
  getForgetPasswordVerificationCode: data => apiAxios('post', Europe + "getForgetPasswordVerificationCode", data),
  //收藏夹Table数量集合
  getTableNumber: data => apiAxios('post', Europe + "favorite.getTableNumber", data),
  //校验重设密码验证码
  checkForgetPasswordCode: data => apiAxios('post', Europe + "checkForgetPasswordCode", data),
  //忘记密码重设
  resetForgetPassword: data => apiAxios('post', Europe + "resetForgetPassword", data),
  //订单导出-临时token
  getOrderDetailDownloadToken: data => apiAxios('post', Europe + "download.orderDetailDownloadToken", data),
  //账单导出-临时token
  getUserBillDownloadToken: data => apiAxios('post', Europe + "download.userBillDownloadToken", data),
  //配送单导出-临时token
  getPorderDownloadToken: data => apiAxios('post', Europe + "download.porderDownloadToken", data),
  //商品库导出-临时token
  getInventoryDownloadToken: data => apiAxios('post', Europe + "download.inventoryDownloadToken", data),
  //获取校验通讯工具的真实性验证码
  getToolCheckedCode: data => apiAxios('post', Europe + "user.getToolCheckedCode", data),
  //提交通讯工具的真实性校验
  toolCheckedValidationCode: data => apiAxios('post', Europe + "user.toolCheckedValidationCode", data),
  //VIP服务列表
  vipList: data => apiAxios('post', Europe + "user.vipList", data),
  //仓库列表Table数据
  stockTableNum: data => apiAxios('post', Europe + "wms.stockTableNum", data),
  //装箱中列表
  encasementList: data => apiAxios('post', Europe + "wms.encasementListV2", data),
  //编辑配送单
  porderEdit: data => apiAxios('post', Europe + "porder.porderEdit", data),
  //再来一单
  copyOrder: data => apiAxios('post', Europe + "order.copyOrder", data),
  //订单-计算并修正订单状态(待支付点击取消确定才调用)
  updateOrderStatus: data => apiAxios('post', Europe + "order.updateStatus", data),
  //配送单-评价详情
  getEvaluateDetail: data => apiAxios('post', Europe + "porder.evaluateDetail", data),
  //设置默认物流方式
  setDefaultLogistics: data => apiAxios('post', Europe + "logistics.setDefault", data),
  //中国国内物流状态跟踪
  chinaLogisticsStatus: data => apiAxios('post', Europe + "logistics.chinaLogistics.status", data),
  //收藏夹标签名字修改
  tagNameEdit: data => apiAxios('post', Europe + "favorite.tagNameEdit", data),
  //网易分类
  homeCategories: data => apiAxios('post', Europe + "homeCategories", data),
  //网易首页推荐商品
  neteaseRecommendedGoods: data => apiAxios('post', Europe + "recommendedGoods", data),
  //网易分类详情列表
  neteaseCategoryList: data => apiAxios('post', Europe + "categoryList", data),
  //网易分类下商品
  neteaseCategoryGoodsList: data => apiAxios('post', Europe + "categoryGoodsList", data),
  //1688会员注册
  alibabaUserRegister: data => apiAxios('post', Europe + "user.alibabaUserRegister", data),
  //1688会员注册查询
  alibabaUserRegisterList: data => apiAxios('post', Europe + "user.alibabaUserRegisterList", data),
  //充值可选支付方式列表
  rechargeMethodList: data => apiAxios('post', Europe + "balance.payMethodList", data),
  // 售后列表
  aftersalelist: data => apiAxios('post', Europe + "aftersale.list", data),
  // 售后详情
  aftersaledetail: data => apiAxios('post', Europe + "aftersale.detail", data),
  //文章-列表
  articleList: data => apiAxios('post', Europe + "config.qa.articleList", data),
  //文章-top10文章列表
  articleFirstTenList: data => apiAxios('post', Europe + "config.qa.top10Articles", data),
  //爆品推荐
  getForUserInterestGoods: data => apiAxios('post', Europe + "forUserInterestGoods", data),
  //首页爆品推荐分类
  getHotSaleCates: data => apiAxios('post', Europe + "hotSaleCates", data),
  //爆品推荐
  getHotSaleSearch: data => apiAxios('post', Europe + "hotSaleSearch", data),
  //礼物兑换
  giftCodeExchange: data => apiAxios('post', Europe + "gift.giftCodeExchange", data),
  //可选兴趣分类
  interestCategoryList: data => apiAxios('post', Europe + "interest.categoryList", data),
  //可选兴趣平台
  interestPlatformList: data => apiAxios('post', Europe + "interest.platformList", data),
  //设置兴趣标签
  interestSetInterest: data => apiAxios('post', Europe + "interest.setInterest", data),
  //订单-修改单番管理番号
  updateOrderDetailForSn: data => apiAxios('post', Europe + "order.updateOrderDetailForSn", data),
  //订单-修改跟进备注
  followRemarkEdit: data => apiAxios('post', Europe + "order.followRemarkEdit", data),
  //首页-阿里热搜分类
  topKeyword: data => apiAxios('post', Europe + "topKeyword", data),
  //授权店铺列表
  authorizedShopList: data => apiAxios('post', Europe + "authorizedShopList", data),
  //授权店铺添加/修改
  authorizedShopSave: data => apiAxios('post', Europe + "authorizedShopSave", data),
  //授权客户绑定
  authorizeUser: data => apiAxios('post', Europe + "authorizeUser", data),
  //获取授权链接
  getAuthorizedLink: data => apiAxios('post', Europe + "getAuthorizedLink", data),
  //修改店铺状态
  authorizedShopStatus: data => apiAxios('post', Europe + "authorizedShopStatus", data),
  //库存报告列表
  inventoryReportList: data => apiAxios('post', Europe + "inventoryReportList", data),
  //亚马逊补货商品信息列表
  bindInventoryList: data => apiAxios('post', Europe + "bindInventoryList", data),
  //补货链接添加/修改
  replenishmentLinkSave: data => apiAxios('post', Europe + "replenishmentLinkSave", data),
  //补货链接删除
  replenishmentLinkDelete: data => apiAxios('post', Europe + "replenishmentLinkDelete", data),
  //入仓商品绑定
  markersWarehouseGoods: data => apiAxios('post', Europe + "markersWarehouseGoods", data),
  //亚马逊补货提单预览
  amazonPreview: data => apiAxios('post', Europe + "amazonPreview", data),
  //获取客户商品(同步)
  storeGoods: data => apiAxios('post', Europe + "storeGoods", data),
  //获取最后同步时间
  getSyncDate: data => apiAxios('post', Europe + "getSyncDate", data),
  //修改补货默认状态
  replenishmentStatus: data => apiAxios('post', Europe + "replenishmentStatus", data),
  //分类ID获取阿里热搜词
  hotCate: data => apiAxios('post', Europe + "hotCate", data),
  //同品类商品
  similarGoodsSearch: data => apiAxios('post', Europe + "similarGoodsSearch", data),
  //多语言关键词导航
  keywordSNQuery: data => apiAxios('post', Europe + "keywordSNQuery", data),
  //榜单列表
  rankingList: data => apiAxios('post', Europe + "client.rankingList", data),
  //成本预估-基础数据预热
  goodsCostPredictBase: data => apiAxios('post', Europe + "cost.predict.costPredictBase", data),
  //成本预估-手动查询可选hscode
  searchHsCodeOption: data => apiAxios('post', Europe + "cost.predict.searchHscodeOption", data),
  //成本预估-1688商品预估总成本
  costPredict: data => apiAxios('post', Europe + "cost.predict.costPredict", data),
  //成本预估-获取商品的历史hscode列表
  getHsCodeList: data => apiAxios('post', Europe + "cost.predict.getHsCodeList", data),
  //公告
  announcement: data => apiAxios('post', Europe + "announcement.announcement", data),
  //商品收藏-修改备注
  favoriteGoodsUpdateRemark: data => apiAxios('post', Europe + "favorite.favoriteGoodsUpdateRemark", data),
  //目标货源-客户历史购买top9
  clientHistoryTop: data => apiAxios('post', Europe + "personalProduct/clientHistoryTop", data),
  //目标货源-客户近期购买列表
  clientRecentBuyTop: data => apiAxios('post', Europe + "personalProduct/clientRecentBuyTop", data),
  //目标货源-表单选项
  getFormOption: data => apiAxios('post', Europe + "personalProduct/getFormOption", data),
  //目标货源-添加货盘条件
  storePalletCondition: data => apiAxios('post', Europe + "personalProduct/storePalletCondition", data),
  //目标货源-修改货盘条件
  updatePalletCondition: data => apiAxios('post', Europe + "personalProduct/updatePalletCondition", data),
  //目标货源-修改货盘名称
  updatePalletName: data => apiAxios('post', Europe + "personalProduct/updatePalletName", data),
  //目标货源-删除货盘
  deletePalletCondition: data => apiAxios('post', Europe + "personalProduct/deletePalletCondition", data),
  //目标货源-货盘列表
  getPalletConditionList: data => apiAxios('post', Europe + "personalProduct/getPalletConditionList", data),
  //目标货源-货盘商品列表
  palletProductList: data => apiAxios('post', Europe + "personalProduct/palletProductList", data),
  //目标货源-货盘商品待确认列表
  palletProductWaitList: data => apiAxios('post', Europe + "personalProduct/palletProductWaitList", data),
  //目标货源-添加自定义指标
  palletSuggestStore: data => apiAxios('post', Europe + "personalProduct/palletSuggestStore", data),
  //目标货源-查看自己的自定义指标列表
  getPalletSuggestList: data => apiAxios('post', Europe + "personalProduct/getPalletSuggestList", data),
  //榜单列表-默认一级分类商品
  listWithDefaultGoods: data => apiAxios('post', Europe + "client.listWithDefaultGoods", data),
  //目标货源-开始
  startRun: data => apiAxios('post', Europe + "personalProduct/startRun", data),
  //目标货源-轮循进度
  getJobProcess: data => apiAxios('post', Europe + "personalProduct/getJobProcess", data),
  //目标货源-待确认列表-拉黑
  storeBlack: data => apiAxios('post', Europe + "personalProduct/storeBlack", data),
  //目标货源-待确认列表-加入货盘
  productConfirm: data => apiAxios('post', Europe + "personalProduct/productConfirm", data),
  //目标货源-模板-列表
  getPalletTplList: data => apiAxios('post', Europe + "personalProduct/getPalletTplList", data),
  //目标货源-模板-客户添加
  storeTpl: data => apiAxios('post', Europe + "personalProduct/storeTpl", data),
  //目标货源-模板-客户修改
  updateTpl: data => apiAxios('post', Europe + "personalProduct/updateTpl", data),
  //目标货源-模板-客户删除
  deleteTpl: data => apiAxios('post', Europe + "personalProduct/deleteTpl", data),
  //目标货源-已确认商品删除
  productRemove: data => apiAxios('post', Europe + "personalProduct/productRemove", data),
}
