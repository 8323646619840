import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./css/reset.css";
import "./css/theme.scss";
import "./css/common.css";
import './css/keywordRecommendation.scss'
import './css/feedback.scss'
import './utlis/directive' //自定义指令
import './css/openSans.css'
import "./css/oldTagDialog.scss"
// 事件挂到fun下
import fun from './utlis/CommonlyUsedFunctions'
import language from './utlis/language'
// API挂到vue原型下
import api from './api/apis'
import md5 from 'js-md5';
import locale from 'element-ui/lib/locale/lang/ja'

Vue.prototype.$fun = fun
Vue.prototype.uploadUrl = '';
location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? Vue.prototype.uploadUrl = 'https://apiwww.rakumart.com/api/common/uploadFile' : Vue.prototype.uploadUrl = 'https://jpapi.rakumart.cn/api/common/uploadFile'
Vue.prototype.$fanyi = language
Vue.prototype.$dfgdfdee = md5;
Vue.prototype.$api = api
Vue.use(ElementUI, {
    locale
})
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
